import { Routes } from '@angular/router';
import { CreateTournamentComponent } from './components/create-tournament/create-tournament.component';
import { PlayerDataComponent } from '../player/components/player-data/player-data.component';
import { TournamentComponent } from './components/tournament/tournament.component';
import { TournamentsListComponent } from './components/tournaments-list/tournaments-list.component';
import { TournamentGameScreenComponent } from './components/tournament-game-screen/tournament-game-screen.component';
import { TournamentDescriptionComponent } from './components/tournament-description/tournament-description.component';
import {HasLoginGuard, HasPtcAdminGuard} from '../common/guards';
import {EditTournamentComponent} from '../common/modules/tournament-admin/components';

export const PtcTournamentRoutes: Routes = [
    {
        path: 'ptc/tournaments',
        component: TournamentsListComponent
    },
    {
        path: 'ptc/tournament/create',
        component: CreateTournamentComponent,
        canActivate: [HasLoginGuard],
    },
    {
        path: 'ptc/tournament/:id',
        component: TournamentComponent
    },
    {
        path: 'ptc/tournament/edit/:id',
        component: EditTournamentComponent,
        canActivate: [HasPtcAdminGuard],
    },
    {
        path: 'ptc/tournament/game/:id',
        component: TournamentGameScreenComponent
    },
    {
        path: 'ptc/info',
        component: PlayerDataComponent
    },
    {
        path: 'ptc/tournament-description/:type',
        component: TournamentDescriptionComponent
    }
];
