import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentDetailedModel, TournamentService } from '../../services';
import { PostEditorComponent } from '../post-editor/post-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'ptc-tournament-news-editor',
    templateUrl: './tournament-news-editor.component.html',
    styleUrls: ['./tournament-news-editor.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, TextFieldModule, FormsModule, MatButtonModule, MatExpansionModule, PostEditorComponent]
})
export class TournamentNewsEditorComponent implements OnInit {

  fileIdMarkUp = {
    fileId: 0,
    markUp: ""
  };

  @Input()
  tournament: TournamentDetailedModel;

  @Output()
  updated = new EventEmitter<boolean>();

  constructor(private _tournamentService: TournamentService,
    private _notifier: NotifierService,) { }

  updateNews(){
    this._tournamentService.editNewsHtml({
      id:this.tournament.id,
      newsHtml: this.tournament.newsHtml
    }).subscribe(data => this.handleResponse(data));
  }

  handleResponse(data: BaseApiResponse){
    NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    this.updated.emit(true);
  }

  generateMarkUp(){
    var str = '<div>\n' +
    `\t<img class="mx-auto d-block img-fluid" src="/FileCopies/Images/Medium/${this.fileIdMarkUp.fileId}.png">\n` +
    '</div>';

    this.fileIdMarkUp.markUp = str;
  }

  ngOnInit(): void {
  }
}
