import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserInterfaceBlock } from 'src/models/genericInterface';
import { Subscription } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-generic-interface-date-picker',
    templateUrl: './generic-interface-date-picker.component.html',
    styleUrls: ['./generic-interface-date-picker.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericInterfaceDatePickerComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, MatDatepickerModule, ReactiveFormsModule]
})
export class GenericInterfaceDatePickerComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  valueControl: UntypedFormControl = new UntypedFormControl();
  @Input() interfaceBlock: UserInterfaceBlock;
  onChange: Function;
  onTouched: Function;
  subscribe: Subscription;

  constructor() {
    this.subscribe = this.valueControl.valueChanges.subscribe((v: Date) => {
      if (this.onChange) {
        this.onChange(v ? v.toISOString() : v);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscribe) this.subscribe.unsubscribe();
  }

  ngOnInit() {}

  writeValue(obj: Date): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set value(value) {
    this.valueControl.setValue(value);
    if (this.onChange) {
      this.onChange(value);
    }
  }

  get value(): Date {
    return this.valueControl.value;
  }
}
