import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiResponse } from 'src/models/BaseApiResponse';

export interface CreateTournament {
    name: string;
    description: string;
    type: string;
    genderRestriction: boolean | null;
    participationCostRub: number;
    cityId: string;
    isExternal: boolean;
}

export interface EditTournament {
    id: string;
    name: string;
    description: string;
    genderRestriction: boolean | null;
}

export interface EditTournamentParticipationRequest {
    id: string;
    open: boolean;
}

export interface SavePlayersOrderRequest {
    tournamentId: string;
    playerIds: Array<string>;
}

export interface TournamentDeletePlayerRequest {
    tournamentId: string;
    playerId: string;
}

export interface EditTournamentParticipationCost {
    id: string;
    participationCostRub: number;
}

export interface EditTournamentNewsHtmlRequest {
    id: string;
    newsHtml: string;
}

export interface TournamentCreationResult {
    succeeded: boolean;
    errorMessage: string;
    tournamentId: string;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
            this._baseControllerUrl = baseUrl + 'api/ptc/tournament-admin/';
    }

    editParticipation(model: EditTournamentParticipationRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'EditParticipation', model);
    }

    editNewsHtml(model: EditTournamentNewsHtmlRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'edit/news', model);
    }


    clearGames(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `ClearGames/${id}`, {});
    }

    computeGames(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `ComputeGames/${id}`, {});
    }
    
    create(model: CreateTournament): Observable<TournamentCreationResult> {
        return this._httpClient.post<TournamentCreationResult>(this._baseControllerUrl + 'create', model);
    }

    finish(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `finish/${id}`, {});
    }

    delete(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `delete/${id}`, {});
    }

    edit(model: EditTournament): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'edit', model);
    }

    editCost(model: EditTournamentParticipationCost): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'edit/cost', model);
    }

    start(id: string): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `start/${id}`, {});
    }
}
