import { Component, Input, OnInit } from '@angular/core';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';

@Component({
    selector: 'ptc-scores-single-as-text',
    templateUrl: './scores-single-as-text.component.html',
    styleUrls: ['./scores-single-as-text.component.css'],
    standalone: true
})
export class ScoresSingleAsTextComponent implements OnInit {

  @Input()
  player1: PlayerModel;

  @Input()
  player2: PlayerModel;

  @Input()
  isWinning: boolean = false;

  @Input()
  scoreText: string;

  winningText = '';

  constructor() { }

  ngOnInit(): void {
    if(this.isWinning){
      this.winningText = this.player1.sex? "победил": "победила";
    }
    else{
      this.winningText = this.player1.sex? "проиграл": "проиграла";
    }
    
  }

}
