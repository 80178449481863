<mat-tab-group>
  <mat-tab label="Данные">
    <app-client-data [client]="client"></app-client-data>
  </mat-tab>
  <mat-tab label="Редактировать">
    <div *ngIf="client">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Персональные данные
            </mat-panel-title>
            <mat-panel-description>
              &nbsp;
              <mat-icon>account_circle</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <mat-form-field class="w-100">
              <input matInput placeholder="Фамилия" new-autocomplete="off" autocomplete="off"
                [(ngModel)]="client.surname">
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Имя" new-autocomplete="off" autocomplete="off"
                [(ngModel)]="client.name">
            </mat-form-field>

            <mat-form-field class="w-100">
              <input matInput placeholder="Отчество" new-autocomplete="off" autocomplete="off"
                [(ngModel)]="client.patronymic">
            </mat-form-field>
            <mat-form-field class="w-100">
              <input matInput placeholder="Номер телефона" new-autocomplete="off" autocomplete="off"
                [(ngModel)]="client.phoneNumber">
            </mat-form-field>
          </div>

          <mat-action-row>
            <button mat-button color="primary" (click)="editClient()">Обновить данные</button>
          </mat-action-row>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Аватар
            </mat-panel-title>
            <mat-panel-description>
              &nbsp;
              <mat-icon>photo_camera</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div>
            <h2>Ваш текущий аватар</h2>
            <app-client-avatar [size]="150" [class]="mat-card-avatar" [clientAvatarData]="client"></app-client-avatar>
          </div>

          <div>
            <app-upload-files-btn #uploadAvatarBtn (onFilesUploaded)="onAvatarFileUploaded($event)" [hidden]="true"
              [extAccepts]="'image/*'" [isMultiple]="false"></app-upload-files-btn>
          </div>

          <mat-action-row>
            <button *ngIf="hasAvatar" mat-button color="danger" (click)="removeAvatar()">Удалить аватар</button>
            <button mat-button color="primary" (click)="uploadAvatar()">{{uploadAvatarBtnText}}</button>
          </mat-action-row>
        </mat-expansion-panel>

      </mat-accordion>
    </div>

  </mat-tab>
</mat-tab-group>