<mat-card *ngIf="player && !player.emailConfirmed">
    <mat-card-content>
        <div>
            <b>Ваш адрес электронной почты не подтверждён. </b>
            Для записи на турниры необходимо иметь подтвержденный адрес электронной почты.
        </div>
        <div>
            Код потверждения был направлен на вашу электронную почту <b>{{player.email}}</b>.
            Возможно письмо с кодом попало в папку спам.
        </div>
        <div>
            Если у вас возникли вопросы, пожалуйста,
            напишите <b><a target="_blank" href="https://t.me/And_Rew_77">Андрею Гришаеву - +7 903 976 6709</a></b>.
        </div>
    
        <div style="height: 20px;"></div>
    
        <form [formGroup]="confirmForm">
            <mat-form-field class="w-100">
                <input matInput placeholder="Код потверждения" new-autocomplete="off" autocomplete="off"
                    formControlName="code" [(ngModel)]="codeData.code">
            </mat-form-field>
        </form>
    
        <button mat-raised-button color="primary" (click)="confirmCode()">Подтвердить электронную почту</button>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="player && player.emailConfirmed && !player.dataFilled">
    <mat-card-content>
        <div>
            <b>Ваша учётная запись не заполнена до конца. </b>
            Для записи на турниры необходимо иметь подтвержденную учётную запись с полностью заполненными данными.
        </div>
        <div>
            Пожалуйста перейдите <a [routerLink]="['/ptc/info']" href="...">сюда</a> 
        </div>
        <div>
            Далее перейдите в раздел Редактировать и раскройте вкладку персональные данные.
        </div>
        <div>
            Вашу учетная запись будет активирована и вы сможете участвовать в турнирах.
        </div>
        <div>
            Если у вас возникли вопросы, пожалуйста,
            напишите <b><a target="_blank" href="https://t.me/And_Rew_77">Андрею Гришаеву - +7 903 976 6709</a></b>.
        </div>    
    </mat-card-content>
</mat-card>

<mat-card *ngIf="player && player.emailConfirmed && player.dataFilled && !player.accountConfirmed">
    <mat-card-content>
        <div>
            <b>Ваша учётная запись не подтверждена. </b>
            Для записи на турниры необходимо иметь подтвержденную учётную запись.
        </div>
        <div>
            В ближайшее время с вами свяжутся администраторы сайта, используя указанный вами номер телефона <b>{{player.phoneNumber}}</b>.
        </div>
        <div>
            Ваша учетная запись будет активирована и вы сможете участвовать в турнирах.
        </div>
        <div>
            Если у вас возникли вопросы, пожалуйста,
            напишите <b><a target="_blank" href="https://t.me/And_Rew_77">Андрею Гришаеву - +7 903 976 6709</a></b>.
        </div>
    </mat-card-content>
</mat-card>