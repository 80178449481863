import { takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DestroyableComponent } from '../../../common/components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { UserForgotPasswordService } from '../../services/UserForgotPasswordService';
import { RouterLink } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, RouterLink]
})
export class ForgotPasswordComponent
  extends DestroyableComponent
  implements OnInit {
  public forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl(''),
  });
  public loading = false;
  public submited = false;
  constructor(
    private userForgotPasswordService: UserForgotPasswordService,
    private _snackBar: MatSnackBar,
    private _title: Title
  ) {
    super();
    this._title.setTitle('Забыли пароль?');
  }

  ngOnInit() {}

  public sendRequest() {
    if (!this.forgotPasswordForm.valid) {
      return;
    }
    this.loading = true;
    this.userForgotPasswordService
      .UserForgotPassword(this.forgotPasswordForm.value)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.loading = false;
        if (result.isSucceeded) {
          this.submited = true;
        } else {
          this._snackBar.open(result.message, 'Закрыть', { duration: 1500 });
        }
      });
  }
}
