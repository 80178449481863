import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { PlayerModel, PlayerQueryService } from 'src/app/player/services/PlayerQueryService';
import { GameService } from '../../services/GameService';
import { SetDataExtensions } from '../scores-main/SetDataExtensions';
import { SetGameModelData, SetFullGameDataComponent } from '../set-full-game-data/set-full-game-data.component';
import { MatButtonModule } from '@angular/material/button';
import { PlayerCardComponent } from '../../../player/components/player-card/player-card.component';
import { PlayerSearchListComponent } from '../../../player/components/player-search-list/player-search-list.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-create-singles-game',
    templateUrl: './create-singles-game.component.html',
    styleUrls: ['./create-singles-game.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, PlayerSearchListComponent, PlayerCardComponent, MatButtonModule, SetFullGameDataComponent]
})
export class CreateSinglesGameComponent implements OnInit, AfterViewInit {

  isDebug = true;
  gameCreated = false;

  step = 0;

  @Output()
  created = new EventEmitter<boolean>() 

  me: PlayerModel;
  opponent: PlayerModel;

  cityId: string = null;

  inProccess = false;

  playerSelectedHandler(player:PlayerModel){
    if(player.id === this.me.id){
      NotificaterExtensions.ShowError(this._notifier, "Вы не можете внести игру против самого себя")
      return;
    }

    this.opponent = player;
    this.step = 1;
  }

  model = {
    court: "",
    imageFileId: null
  };

  constructor(
    private _playerQueryService: PlayerQueryService,
    private _loginService: LoginService,
    private _gameService: GameService,
    private _notifier: NotifierService,
    private _cdr: ChangeDetectorRef) {
  }

  gameCreatedHandler(data: SetGameModelData){
    if(this.inProccess){
      return;
    }

    this.inProccess = true;


    this._gameService.create({
      cityId: data.courtData.city.id,
      courtType: data.courtData.courtType,
      imageFileId: data.imageFileId,
      opponentPlayerId: this.opponent.id,
      court: data.courtData.court,
      gameData: SetDataExtensions.toTennisMatchData(data.scoreData, this.me.id, this.opponent.id),
      playedOnUtc: data.courtData.dateIsoString
    }).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
      this.inProccess = false;
      this.step = 5;
      this.gameCreated = true;
      this.opponent = null;
    })
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  changeOponent(){
    this.opponent = null;
    this.step = 0;
  }

  startAgain(){
    this.opponent = null;
    this.step = 0;
    this.gameCreated = false;
  }

  ngOnInit(): void {
    this._loginService.getLoginDataCached().subscribe(data => {
      
      this._playerQueryService.getById(data.userId).subscribe(meAsPlayer => {
        this.me = meAsPlayer;
      });
    })
  }
}
