<mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Добавить игрока
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
        <ptc-search-players #searchComponent [searchPlayers]="searchPlayersToAdd">
        </ptc-search-players>
        
        <div style="height: 20px;"></div>

        <div *ngIf="!searchComponent.playerId" style="height: 70px;"></div>
        <button *ngIf="searchComponent.playerId" (click)="add()" mat-raised-button color="primary">
            Добавить
        </button>
        <div style="height: 50px;"></div>
    </div>

</mat-expansion-panel>

<div style="height: 20px;"></div>

<mat-card>
    <mat-card-content>
        <div>
            Участников: {{tournament.players.length}} из {{tournament.maxNumberOfParticipants}}
        </div>
    </mat-card-content>
</mat-card>

<div style="height: 20px;"></div>

<div cdkDropList (cdkDropListDropped)="drop($event)">
    <div style="margin: 2px;" class="example-box" *ngFor="let player of tournament.players" cdkDrag>
        <div class="example-handle" cdkDragHandle>
            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                </path>
                <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
        </div>

        <div>
            <a [routerLink]="['/ptc/player', player.id]">{{player.surname}} {{player.name}}</a>
        </div>

        <div class="d-flex justify-content-end">
            <div style="width: 10px;">

            </div>
            <div>
                <button (click)="delete(player.id)" mat-icon-button color="warn" aria-label="Delete">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

    </div>
</div>

<div style="height: 20px;"></div>
<button class="w-100" (click)="saveOrder()" mat-raised-button color="primary">
    Сохранить порядок
</button>