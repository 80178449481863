import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';

export interface GetListResult<T> {
    count: number | null;
    totalCount: number;
    offset: number;
    list: T[];
}

export interface TournamentDetailedModel {
    id: string;
    openForParticipantsJoining: boolean;
    activityStatus: TournamentActivityStatus;
    durationType: TournamentDurationType;
    name: string;
    description: string;
    newsHtml: string;
    maxNumberOfParticipants: number;
    isInTournament: boolean;
    genderRestriction: boolean | null;
    hidden: boolean;
    type: string;
    cityId: string;
    participationCostRub: number;
    isExternal: boolean;
    external: ExternalTournamentDataModel;
    events: Array<TournamentEventModel>;
    players: Array<PlayerSimpleModel>;
}

export enum TournamentDurationType {
	MultiDay = <any> 'MultiDay',
	OneDay = <any> 'OneDay'
}



export interface ExternalTournamentDataModel {
	 exists: boolean;
	 link: string;
	 description: string;
}

export enum TournamentActivityStatus {
   Planned = <any> 'Planned',
   Active = <any> 'Active',
   Finished = <any> 'Finished'
}

export interface TournamentEventModel {
    id: string;
    eventNumberIdentifier: number;
    isParticipantsDataReady: boolean;
    resultConfirmed: boolean;
    hasScoreData: boolean;
    description: string;
    scoreDataUpdatedByUserId: string;
    players: Array<TournamentEventPlayerModel>;
    scoreData: TennisMatchData;
    dateRestrictionUtc: string | null;
    canSetScore: boolean;
    imageFileId: number;
}


export interface TournamentEventPlayerModel {
    userId: string;
    isWinner: boolean;
}


export interface TennisMatchData {
    sets: Array<TennisSetData>;
    winnerPlayerId: string;
}


export interface TennisSetData {
    score1: string;
    score2: string;
    tieBreak: TennisTieBreakScoreData;
}


export interface TennisTieBreakScoreData {
    score1: string;
    score2: string;
}

export interface TournamentSimpleModel {
    id: string;
    openForParticipantsJoining: boolean;
    participationCostRub: number;
    activityStatus: TournamentActivityStatus;
    name: string;
    description: string;
    maxNumberOfParticipants: number;
    currentNumberOfParticipants: number;
    isInTournament: boolean;
    type: string;
}

export interface GetTournamentsRequest {
    openForParticipantsJoining: boolean | null;
    activityStatus: TournamentActivityStatus | null;
    showMine: boolean | null;
    cityId: string;
    count: number | null;
    offSet: number;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentQueryService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/tournament/query/';
    }

    get(model: GetTournamentsRequest): Observable<GetListResult<TournamentSimpleModel>> {
        return this._httpClient.post<GetListResult<TournamentSimpleModel>>(this._baseControllerUrl + 'get/list', model);
    }

    getById(id: string): Observable<TournamentDetailedModel> {
        return this._httpClient.get<TournamentDetailedModel>(this._baseControllerUrl + `get/byId/${id}`);
    }
}
