<div>
    <ptc-tournament-simple-match-card [game]="game.game" [tournament]="tournament"></ptc-tournament-simple-match-card>
    
    <div *ngIf="game.matchPoints">
        <div style="height: 20px;"></div>

        <div>
            Начисленные очки:
        </div>

        <div>
            {{players[0].player.surname}} {{players[0].player.name}}: {{game.matchPoints.playerPoints[0].points}}
        </div>

        <div>
            {{players[1].player.surname}} {{players[1].player.name}}: {{game.matchPoints.playerPoints[1].points}}
        </div>
    </div>
</div>