<div [hidden]="!shouldConfirm">
    <ptc-player-data-confirm-email (shouldConfirmEmitter)="shouldConfirm = $event" (codeConfirmed)="getPlayerData()">
    </ptc-player-data-confirm-email>
    <div style="height: 10px;"></div>
</div>

<mat-tab-group>
    <mat-tab label="Данные">
        <ptc-player-client-data *ngIf="playerLoaded" [client]="player"></ptc-player-client-data>

        <div style="height: 10px;"></div>

        <mat-card *ngIf="balance">
            <mat-card-content>
                <div>
                    Ваш баланс: <span class="h6">{{balance.balanceRub}}₽</span>
                </div>
            </mat-card-content>
        </mat-card>

        <div *ngIf="playerLoaded">
            <div style="height: 10px;"></div>

            <mat-card>
                <mat-card-content>
                    <ptc-telegram-player-data [player]="player"></ptc-telegram-player-data>
                </mat-card-content>
            </mat-card>
        </div>


        <div style="height: 10px;"></div>

        <mat-card>
            <mat-card-content>
                
            </mat-card-content>
            <div>
                Пополнить баланс на сайте вы можете банковским переводом:
            </div>
            <div>
                Тинькоф: <b>+79166044960</b> Сердюков Дмитрий Александрович
            </div>
            <div>
                Сбербанк: <b>+79166044960</b> Сердюков Дмитрий Александрович
            </div>
            <p></p>

            <div>
                После того как переведете деньги, необходимо прислать скриншот Диме Сердюкову в личку или в
                группу в телеграме.
            </div>
            <div>
                После того как вы пришлёте скриншот, ваш баланс будет пополнен на сайте.
            </div>
            <div style="height:10px"></div>
            <div>
                История изменения баланса: когда были добавлены деньги, когда были списаны деньги отображается во
                вкладке справа <b>история баланса</b>.
            </div>
        </mat-card>
    </mat-tab>
    <mat-tab label="Редактировать">
        <div [hidden]="!player">
            <mat-accordion>
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Персональные данные
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <form [formGroup]="editForm">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Фамилия" new-autocomplete="off" autocomplete="off"
                                formControlName="surname" [(ngModel)]="player.surname">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Имя" formControlName="name" [(ngModel)]="player.name">
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <input matInput placeholder="Отчество" new-autocomplete="off" autocomplete="off"
                                formControlName="patronymic" [(ngModel)]="player.patronymic">
                        </mat-form-field>

                        <my-date-time-picker #birthDatePicker [showLocalTime]="false" [showTime]="false"
                            [dateLabel]="'Дата рождения'" (onDateChanged)="newBirthDate = $event">
                        </my-date-time-picker>

                        <mat-form-field class="w-100">
                            <textarea cdkTextareaAutosize matInput placeholder="Обо мне" new-autocomplete="off"
                                autocomplete="off" formControlName="aboutMe" [(ngModel)]="player.aboutMe"></textarea>
                        </mat-form-field>

                        <ptc-banner-info
                            [message]="'В разделе обо мне укажите как долго и как хорошо вы играете в теннис, также напишите здесь об удобных способах связи с вами и упомяните место и время в которое вам удобно играть во время турнира. Не указывайте здесь контактных данных, они будут доступны вашему сопернику, когда будет назначена игра.'">
                        </ptc-banner-info>

                        <div>
                            Пол:
                        </div>
                        <mat-button-toggle-group name="sex" aria-label="Font Style">
                            <mat-button-toggle value="bold" [checked]="player.sex" (change)="player.sex = true">Мужской
                            </mat-button-toggle>
                            <mat-button-toggle value="italic" [checked]="!player.sex" (change)="player.sex = false">
                                Женский
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </form>

                    <mat-action-row>
                        <button mat-button color="primary" (click)="editPlayer()">Сохранить</button>
                    </mat-action-row>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Местоположение
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <common-country-and-city-select #countryCitySelect
                            (valueChanged)="cityChangedHandler()">
                        </common-country-and-city-select>

                        <div style="height: 10px;">
                        </div>


                    </div>
                    <mat-action-row>
                        <button mat-button color="primary" (click)="editCountryAndCity()">Сохранить</button>
                    </mat-action-row>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Рейтинг
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <ptc-ntrp-select #ntrpSelect></ptc-ntrp-select>

                        <div>
                            <a href="/info/ntrp" target="_blank" class="white-color">Не знаете как указать рейтинг?</a>
                        </div>

                        <div>

                        </div>
                    </div>
                    <mat-action-row>
                        <button mat-button color="primary" (click)="editNtrpRating()">Сохранить</button>
                    </mat-action-row>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Контактные данные
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>

                        <ptc-banner-info [message]="contactDataInfoText" [hasActions]="false">
                        </ptc-banner-info>

                        <div style="height: 20px;">
                        </div>

                        <form [formGroup]="contactForm">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Номер телефона" new-autocomplete="off" autocomplete="off"
                                    formControlName="phoneNumber" [(ngModel)]="player.phoneNumber">
                            </mat-form-field>

                            <mat-form-field class="w-100">
                                <input matInput placeholder="Адрес электронной почты" formControlName="email"
                                    [(ngModel)]="player.email">
                            </mat-form-field>
                        </form>
                    </div>
                    <mat-action-row>
                        <button mat-button color="primary" (click)="editCommunications()">Сохранить</button>
                    </mat-action-row>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Аватар
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>

                    </div>

                    <div>
                        <ptc-player-avatar-cropper></ptc-player-avatar-cropper>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <div style="height: 50px;"></div>
        </div>
    </mat-tab>
    <mat-tab label="История баланса">
        <div *ngFor="let transaction of balanaceTransactions">
            <mat-card>
                <mat-card-content>
                    <div>
                        <h5>{{transaction.sum > 0? '+' : ''}}{{transaction.sum}}₽</h5>
                    </div>
                    <div>
                        {{transaction.occuredOnUtc}}
                    </div>
                    <div>
                        {{transaction.description}}
                    </div>
                </mat-card-content>
            </mat-card>
            <div style="height: 5px;">
            </div>
        </div>

        <div style="height: 50px;"></div>
    </mat-tab>
</mat-tab-group>
