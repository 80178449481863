import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TournamentTypeLinkProvider } from '../tournament-type-link/tournament-type-link.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-tournament-description',
    templateUrl: './tournament-description.component.html',
    styleUrls: ['./tournament-description.component.css'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, NgIf, MatCardModule, MatExpansionModule]
})
export class TournamentDescriptionComponent implements OnInit {

  type: string;
  displayName: string = "";

  constructor(private _route: ActivatedRoute,
    private _typeLinkProvider: TournamentTypeLinkProvider) {

  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.type = params['type'];
      this.extractDisplayName();
    });
  }

  extractDisplayName(){
    this.displayName = this._typeLinkProvider.getDisplayName(this.type);
  }

}
