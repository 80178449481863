import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseApiResponse } from 'src/models/BaseApiResponse';

@Injectable({
    providedIn: 'root',
})
export class TournamentMessagingService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/messaging/tournament/';
    }

    sendPlanningMessage(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `${id}/send/planning`, {});
    }

    sendLongGamesMessages(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `${id}/send/long-games`, {});
    }

    sendCityMessages(cityId: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `city/${cityId}`, {});
    }
}
