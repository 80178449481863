import { OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NavigationStart, Router, GuardsCheckEnd, RouterModule } from '@angular/router';
import { Component } from '@angular/core';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { NotificationService } from './ecc/services/NotificationService';
import { NotifierNotification } from 'angular-notifier/lib/models/notifier-notification.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { NotifierModule } from 'angular-notifier';
import { SideNavMenuComponent } from './common/modules/app-nav/components/side-nav-menu/side-nav-menu.component';
import { NavMenuComponent } from './common/modules/app-nav/components/nav-menu/nav-menu.component';
import { LoginDataComponent } from './common/modules/app-nav/components/login-data/login-data.component';
import { SideNavMenuItemComponent } from './common/modules/app-nav/components/side-nav-menu-item/side-nav-menu-item.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //standalone: true,
  /*imports: [
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    CommonModule,
    NotifierModule,
    MatSidenavModule,
    LoginDataComponent, 
    NavMenuComponent, 
    SideNavMenuComponent, 
    SideNavMenuItemComponent
  ]*/
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('notifierWithHtmlTemplate')
  public notifierWithHtmlTemplate: TemplateRef<any>;

  @ViewChild('drawer', { static: false })
  private drawer: MatDrawer;

  private ngUnsubscribe$ = new Subject<void>();
  
  public withoutMenuContainer$ = this.router.events.pipe(
    filter((event) => event instanceof GuardsCheckEnd),
    map(
      ({
        state: {
          root: {
            firstChild: { data },
          },
        },
      }: GuardsCheckEnd) => data
    ),
    map(({ withoutMenuContainer }) => withoutMenuContainer)
  );

  constructor(
    private router: Router,
    //private notificationFromSocketService: NotificationFromSocketService,
    private notificationService: NotificationService,
    //private notificationCustomStackService: NotificationCustomStackService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        if (this.drawer) {
          this.drawer.close();
        }
      });

    this.initNotifications();
  }

  initNotifications(): void{
    return;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  public dismiss(
    notificationData: NotifierNotification,
    button: HTMLButtonElement
  ) {
    button.disabled = true;
    this.notificationService
      .MarkNotificationAsRead(notificationData.id)
      .pipe(take(1))
      .subscribe(() => notificationData?.component?.onClickDismiss());
  }
}
