import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {PlayerAccountService} from '../../../common/services';
import {CountryAndCitySelectComponent} from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import {
  TournamentActivityStatus,
  TournamentMessagingService,
  TournamentQueryService,
  TournamentSimpleModel
} from '../../../common/modules/tournament-admin/services';
import { PtcRoles } from 'src/app/common/roles';
import { TournamentsListGroupComponent } from '../tournaments-list-group/tournaments-list-group.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { PlayerDataConfirmEmailComponent } from '../../../player/components/player-data-confirm-email/player-data-confirm-email.component';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-tournaments-list',
    templateUrl: './tournaments-list.component.html',
    styleUrls: ['./tournaments-list.component.css'],
    standalone: true,
    imports: [NgIf, MatButtonModule, RouterLink, PlayerDataConfirmEmailComponent, MatCardModule, CountryAndCitySelectComponent, MatCheckboxModule, FormsModule, TournamentsListGroupComponent]
})
export class TournamentsListComponent implements AfterViewInit {

  @ViewChild("countryCitySelect")
  countryCitySelect: CountryAndCitySelectComponent;

  shouldConfirm = false;
  isAdmin = false;
  isAuthenticated = false;
  cityId:string = null;

  filter = {
    showMine: false
  };

  plannedTournaments:TournamentSimpleModel[] = null;
  openedForParicipantsTournaments:TournamentSimpleModel[] = null;
  currentTournaments:TournamentSimpleModel[] = null;
  finishedTournaments:TournamentSimpleModel[] = null;

  constructor(title: Title,
    loginService: LoginService,
    private _tournamentService: TournamentQueryService,
    private _playerService: PlayerAccountService,
    private _messagingService: TournamentMessagingService,
    private _notifier: NotifierService) {
    title.setTitle("Турниры");

    loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.admin) || data.roles.includes(PtcRoles.tournamentAdmin);
      
      this.isAuthenticated = data.isAuthenticated;

      if(this.isAuthenticated){
        this.filter.showMine = true;
      }
    })
  }

  sendMessagesForCity(){
    this._messagingService.sendCityMessages(this.cityId).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    });
  }

  cityChangedHandler(){
    this.cityId = this.countryCitySelect.city?.id;
    this.getTournaments();
  }

  ngAfterViewInit(): void {
    this.setPlayerData();
  }

  showMineChanged(data:boolean){
    this.getTournaments();
  }

  setPlayerData(){
    this._playerService.getLocationData().subscribe(data => {
      if(data){
        this.countryCitySelect.setValueViaPlayerData(data);
      }
      this.getTournaments();
    });
  }

  getTournaments(){

    let showMineFlag = null;

    if(this.filter.showMine){
      showMineFlag = true;
    }

    this.plannedTournaments = null;

    this._tournamentService.get({
      count:5,
      offSet: 0,
      activityStatus: TournamentActivityStatus.Planned,
      openForParticipantsJoining: false,
      showMine: null,
      cityId:this.cityId,
    }).subscribe(data => {
      this.plannedTournaments = data.list;
    });

    this.openedForParicipantsTournaments = null;

    this._tournamentService.get({
      count:5,
      offSet:0,
      activityStatus: TournamentActivityStatus.Planned,
      openForParticipantsJoining: true,
      showMine: showMineFlag,
      cityId:this.cityId,
    }).subscribe(data => {
      this.openedForParicipantsTournaments = data.list;
    });

    this.currentTournaments = null;

    this._tournamentService.get({
      count:5,
      offSet:0,
      activityStatus: TournamentActivityStatus.Active,
      openForParticipantsJoining: null,
      showMine: showMineFlag,
      cityId:this.cityId,
    }).subscribe(data => {
      this.currentTournaments = data.list;
    });

    this.finishedTournaments = null;

    this._tournamentService.get({
      count:5,
      offSet:0,
      activityStatus: TournamentActivityStatus.Finished,
      openForParticipantsJoining:null,
      showMine:showMineFlag,
      cityId: this.cityId
    }).subscribe(data => {
      this.finishedTournaments = data.list
    });
  }
}
