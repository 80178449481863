import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { GetListResult } from "croco-html/lib/services/files-query.service";
import { GetListSearchModel } from "src/app/files/services/FilesQueryService";
import { BaseApiResponse } from "src/models/BaseApiResponse";
import {TennisMatchData} from '../../common/modules/tournament-admin/services';

export interface CreateSinglesGame {
    opponentPlayerId: string;
    cityId: string;
    court: string;
    imageFileId: number;
    courtType: string;
    playedOnUtc: Date | string;
    gameData: TennisMatchData;
}

export interface SinglesGameSimpleModel {
    id: string;
    imageFileId: number | null;
    court:string;
    courtType: string;
    players: Array<GamePlayerModelWithWinner>;
    scoreData: TennisMatchData;
    playedOnUtc: Date | null;
}


export interface GamePlayerModelWithWinner {
    teamName: string;
    isWinner: boolean;
    player: PlayerSimpleModel;
}

export interface SearchGamesRequest {
    playerId: string;
    opponentPlayerId: string;
    q: string;
    count: number | null;
    offSet: number;
}


export interface PlayerSimpleModel {
    id: string;
    sex: boolean;
    name: string;
    surname: string;
    patronymic: string;
    avatarFileId: number | null;
    ntrpRating: string;
    rating: number;
}



@Injectable({
    providedIn: 'root',
})
export class GameService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + "api/ptc/game/";
    }

    searchMine(model: GetListSearchModel){
        return this._httpClient.post<GetListResult<SinglesGameSimpleModel>>(this._baseControllerUrl + 'search/mine', model);
    }

    search(model: SearchGamesRequest){
        return this._httpClient.post<GetListResult<SinglesGameSimpleModel>>(this._baseControllerUrl + 'search', model);
    }

    create(model: CreateSinglesGame){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'create', model);
    }

    getDefaultTennisMathData(){
        return this._httpClient.get<TennisMatchData>(this._baseControllerUrl + 'default/match-data');
    }

    delete(id: string){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `delete/${id}`, {});
    }
}
