<div class="d-flex justify-content-start">
    <div>
        <a style="cursor: pointer;" (click)="playerClickHandler(player)">
            <app-client-avatar [size]="100" [class]="'mat-card-avatar'" [clientAvatarData]="player">
            </app-client-avatar>
        </a>
    </div>
      
    <div style="width: 10px;">
    </div>

    <div class="d-flex align-items-center">
        <div>
            <a class="link" style="cursor: pointer;" (click)="playerClickHandler(player)">
                {{player.surname}} {{player.name}}
            </a>
            <div>
                NTRP: {{player.ntrpRating}}
            </div>
            <div>
                Рейтинг: {{player.rating.toFixed(2)}}
            </div>
        </div>
    </div>
</div>
