import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApiResponse } from "src/models/BaseApiResponse";
import {TennisMatchData} from '../../common/modules/tournament-admin/services';

export interface UpdateGameScoreData {
    gameId: string;
    imageFileId: number;
    gameData: TennisMatchData;
}


@Injectable({
    providedIn: 'root',
})
export class TournamentGameService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
            this._baseControllerUrl = baseUrl + "api/ptc/tournament/game/"
    }

    updateScore(data: UpdateGameScoreData){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + 'score/update', data);
    }

    confirmScore(id: string){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `score/Confirm/${id}`, {});
    }

    removeScoreConfirmation(id: string){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `score/RemoveConfirm/${id}`, {});
    }
}
