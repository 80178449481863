import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { ClientService } from 'src/app/clt/services/ClientService';
import { LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { NtrpSelectComponent } from '../ntrp-select/ntrp-select.component';
import {
  AccountTransactionModel,
  BaseApiResponseWithFilesIds,
  PlayerAccountService,
  PlayerBalanceData,
  PlayerData
} from '../../../common/services';
import { CountryAndCitySelectComponent } from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import { PlayerAvatarCropperComponent } from '../player-avatar-cropper/player-avatar-cropper.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BannerInfoComponent } from '../../../tournament/components/banner-info/banner-info.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MyDateTimePickerComponent } from '../../../common/modules/shared/components/my-date-time-picker/my-date-time-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { TelegramPlayerDataComponent } from '../../../common/modules/shared/components/telegram-player-data/telegram-player-data.component';
import { MatCardModule } from '@angular/material/card';
import { PlayerClientDataComponent } from '../player-client-data/player-client-data.component';
import { NgIf, NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { PlayerDataConfirmEmailComponent } from '../player-data-confirm-email/player-data-confirm-email.component';

@Component({
  selector: 'app-player-data',
  templateUrl: './player-data.component.html',
  styleUrls: ['./player-data.component.css'],
  standalone: true,
  imports: [
    MyDateTimePickerComponent,
    PlayerDataConfirmEmailComponent,
    MatTabsModule,
    NgIf,
    PlayerClientDataComponent,
    MatCardModule,
    TelegramPlayerDataComponent,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    BannerInfoComponent,
    MatButtonToggleModule,
    MatButtonModule,
    CountryAndCitySelectComponent,
    NtrpSelectComponent,
    PlayerAvatarCropperComponent,
    NgFor
  ]
})
export class PlayerDataComponent implements OnInit {

  newBirthDate: string = "";

  @ViewChild("birthDatePicker")
  birthDatePicker: MyDateTimePickerComponent;


  @ViewChild("ntrpSelect")
  ntrpSelect: NtrpSelectComponent;

  @ViewChild("countryCitySelect")
  countryCitySelect: CountryAndCitySelectComponent;

  shouldConfirm = false;
  fillDataText: string = "У вас не полностью указаны персональные данные. Необходимо указать ваш пол и заполнить раздел 'Обо мне'";

  contactDataInfoText = `Ваши контактные данные будут показаны сопернику только перед матчем.
  Cайт использует ваш номер телефона и адрес электронной почты только для информирования о предстоящих играх и турнирах.`;

  playerLoaded = false;

  balanaceTransactions: AccountTransactionModel[] = [];
  balance: PlayerBalanceData = null;

  userId: string = null;

  player: PlayerData = {
    name: "",
    surname: "",
    dataFilled: true,
    phoneNumber: "",
    phoneNumberConfirmed: true,
    patronymic: "",
    aboutMe: "",
    accountConfirmed: true,
    birthDate: null,
    createdOn: null,
    email: "",
    noEmail: false,
    sex: false,
    emailConfirmed: true,
    cityId: "",
    countryId: "",
    telegramUserId: null,
    telegramUserName: null,
    ntrpRating: null,
    rating: 0
  };


  name = "";
  step = -1;

  setStep(index: number) {
    this.step = index;
  }

  editForm = new UntypedFormGroup({
    "surname": new UntypedFormControl(),
    "name": new UntypedFormControl(),
    "patronymic": new UntypedFormControl(),
    "aboutMe": new UntypedFormControl(),
    "sex": new UntypedFormControl(),
  });

  contactForm = new UntypedFormGroup({
    "phoneNumber": new UntypedFormControl(),
    "email": new UntypedFormControl()
  });

  constructor(
    private _notifierService: NotifierService,
    private _loginService: LoginService,
    private _titleService: Title,
    private _accountService: PlayerAccountService,
    private _cltService: ClientService) {
    this._titleService.setTitle("Редактирование клиентских данных");
    this._loginService.getLoginDataCached().subscribe(data => {
      this.userId = data.userId;
      this.getAccountTransactions();
    })
  }

  cityChangedHandler() {
    this.player.cityId = this.countryCitySelect.city?.id;
  }

  editCountryAndCity() {

    this._accountService.updateCityData({
      countryId: this.countryCitySelect.country?.id,
      cityId: this.countryCitySelect.city?.id
    }).subscribe(data => {
      this.handleResponse(data);
    })

  }



  getPlayerData() {
    this._accountService.getData().subscribe(data => {
      this.player = data;

      this.ntrpSelect.setValue(data.ntrpRating);

      this.playerLoaded = true;
      this.birthDatePicker.setDateFromIsoString(this.player.birthDate as string);
    });

    this._accountService.getBalanceData().subscribe(data => {
      this.balance = data;
    })
  }

  editNtrpRating() {
    this._accountService.updateNtrpRating({
      ntrpRating: this.ntrpSelect.getValue()
    }).subscribe(data => {
      this.handleResponse(data);
    })
  }

  getAccountTransactions() {

    this._accountService.getTransactions({
      playerId: this.userId,
      count: 10,
      offSet: 0
    }).subscribe(data => {
      this.balanaceTransactions = data.list;
    })
  }

  editPlayer() {

    let playerData = JSON.parse(JSON.stringify(this.player)) as PlayerData;

    playerData.birthDate = this.newBirthDate;

    this._accountService.updateData(playerData).subscribe(data => {
      this.handleResponse(data);
    });
  }

  handleResponse(data: BaseApiResponse) {
    NotificaterExtensions.ShowBaseApiResponse(this._notifierService, data);

    if (data.isSucceeded) {
      this.getPlayerData();
    }
  }

  onAvatarUploaded(data: BaseApiResponseWithFilesIds) {

    if (!data.isSucceeded) {
      NotificaterExtensions.ShowError(this._notifierService, data.message);
      return;
    }

    this._cltService.UpdatePhoto(data.responseObject[0]).subscribe(photoResp => {

      if (photoResp.isSucceeded) {
        NotificaterExtensions.ShowSuccess(this._notifierService, "Загружена новая фотография");
        this._loginService.clearLoginDataCache();

        return;
      }

      NotificaterExtensions.ShowError(this._notifierService, photoResp.message);
    })
  }

  editCommunications() {

    NotificaterExtensions.ShowError(this._notifierService, "На данный момент изменение контактных данных ограничено, свяжитесь с Админитсратором.");
    return;
  }

  ngOnInit(): void {
    this.getPlayerData();
  }
}
