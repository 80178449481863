import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

export interface CrocoAdminAppOptions {
    baseUrl: string; 
}

@Injectable({
    providedIn: 'root',
})
export class AdminAppOptionsService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/settings/';
    }

    get() {
        return this._httpClient.get<CrocoAdminAppOptions>(this._baseControllerUrl + `admin-app/get`);
    }
}
