import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TournamentGroupModel, TournamentGroupService } from '../../services/TournamentGroupService';
import {TournamentDetailedModel, TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { TournamentSimpleMatchCardComponent } from '../tournament-simple-match-card/tournament-simple-match-card.component';
import { TournamentGroupComponent } from '../tournament-group/tournament-group.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ptc-tournament-groups-view',
    templateUrl: './tournament-groups-view.component.html',
    styleUrls: ['./tournament-groups-view.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, NgFor, TournamentGroupComponent, TournamentSimpleMatchCardComponent]
})
export class TournamentGroupsViewComponent implements OnInit {

  @Input()
  tournament: TournamentDetailedModel;

  groups:TournamentGroupModel[] = [];

  noGroups = false;

  finalGames:TournamentEventModel[] = [];

  @Input()
  tournamentId:string;

  constructor(private tournamentGroupService: TournamentGroupService) {
  }

  ngOnInit(): void {

    this.getTournament();
  }

  getTournament(){
    this.tournamentGroupService.get(this.tournamentId).subscribe(data => {

      if(!data.isSucceeded){
        this.noGroups = true;
        return;
      }

      this.groups = data.responseObject;
      this.extractFinalGames();
    });
  }

  extractFinalGames(){

    var groupGameIds = this.getGroupGameIds();
    var maxId = Math.max(...groupGameIds);

    this.finalGames = this.tournament.events.filter(x => x.eventNumberIdentifier > maxId);
  }

  getGroupGameIds(){
    var result: number[] = [];

    for(let i = 0; i < this.groups.length; i++){
      let group = this.groups[i];

      for(let j = 0; j < group.games.length; j++){
        let groupGame = group.games[j];

        result.push(groupGame.game.eventNumberIdentifier);
      }
    }

    return result;
  }

}
