import { Component, Injectable, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TournamentTypeLinkProvider {
  
  mappings:Map<string, string> = new Map();

  constructor(){
    this.mappings.set('For8AllPlaces', "Для 8 участников. Розыгрыш всех мест");
    this.mappings.set("For8WithTwoGroups", "Для 8 участников. С двумя группами")
  }

  getDisplayName(type: string): string{


    if(!this.mappings.has(type)){
      return type;
    }

    return this.mappings.get(type);
  }
}

@Component({
    selector: 'ptc-tournament-type-link',
    templateUrl: './tournament-type-link.component.html',
    styleUrls: ['./tournament-type-link.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class TournamentTypeLinkComponent implements OnInit {

  @Input()
  type: string;

  displayName: string = "";

  constructor(private _typeLinkProvider: TournamentTypeLinkProvider) {

  }

  ngOnInit(): void {
    this.displayName = this._typeLinkProvider.getDisplayName(this.type);
  }
}
