import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CitySelectComponent } from '../city-select/city-select.component';
import { CountrySelectComponent } from '../country-select/country-select.component';
import { CityModel } from '../../models';
import { CountryNameModel, PlayerAccountService, PlayerLocationData } from '../../../../services';

@Component({
  selector: 'common-country-and-city-select',
  templateUrl: './country-and-city-select.component.html',
  standalone: true,
  imports: [
    CountrySelectComponent,
    CitySelectComponent
  ]
})
export class CountryAndCitySelectComponent implements OnInit, AfterContentChecked {

  isDebug = false;

  country: CountryNameModel;
  city: CityModel;

  @ViewChild('citySelect')
  citySelect: CitySelectComponent;

  @ViewChild('countrySelect')
  countrySelect: CountrySelectComponent;

  @Output()
  valueChanged = new EventEmitter<boolean>();

  constructor(private readonly cdr: ChangeDetectorRef,
    private readonly _playerService: PlayerAccountService) { }


  setValueViaPlayerData(data: PlayerLocationData) {
    this.setValue(data.country, data.city);
  }

  setValue(country: CountryNameModel, city: CityModel) {
    this.country = country;
    this.countrySelect.country = country;

    this.citySelect.setCity(city);
  }

  countryChangedHandler(country: CountryNameModel) {
    this.country = country;

    if (!this.country) {
      this.citySelect.setCity(null);
    }

    this.valueChanged.emit(true);
  }

  cityChangedHandler(city: CityModel) {
    this.city = city;
    this.valueChanged.emit(true);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  setPlayerData() {
    this._playerService.getLocationData().subscribe(data => {
      if (data) {
        this.setValueViaPlayerData(data);
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setPlayerData();
  }
}
