<mat-card *ngIf="!creationResult">
    <mat-card-content>
        <h4>Создание нового турнира</h4>

        <ptc-tournament-form #form></ptc-tournament-form>

        <div style="height: 10px;"></div>

        <button (click)="create()" mat-raised-button color="primary">
            Создать
        </button>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="creationResult && creationResult.succeeded">
    <mat-card-content>
        <button class="w-100" (click)="goToTournament()" mat-raised-button color="primary">
            Перейти в турнир
        </button>
    </mat-card-content>
</mat-card>

<div style="height: 50px;"></div>