import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { UserInterfaceBlock } from 'src/models/genericInterface';
import { UntypedFormControl, NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GenericInterfaceAutocompleteMultipleComponent } from '../generic-interface-autocomplete-multiple/generic-interface-autocomplete-multiple.component';
import { GenericInterfaceAutocompleteComponent } from '../generic-interface-autocomplete/generic-interface-autocomplete.component';
import { GenericInterfaceForClassComponent } from '../generic-interface-for-class/generic-interface-for-class.component';
import { GenericInterfaceForArrayComponent } from '../generic-interface-for-array/generic-interface-for-array.component';
import { GenericInterfaceMultipleDropdownListComponent } from '../generic-interface-multiple-dropdown-list/generic-interface-multiple-dropdown-list.component';
import { GenericInterfaceDatePickerComponent } from '../generic-interface-date-picker/generic-interface-date-picker.component';
import { GenericInterfaceDropdownListComponent } from '../generic-interface-dropdown-list/generic-interface-dropdown-list.component';
import { GenericInterfaceTextareaComponent } from '../generic-interface-textarea/generic-interface-textarea.component';
import { GenericInterfaceTextboxBlockComponent } from '../generic-interface-textbox-block/generic-interface-textbox-block.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'app-generic-interface-block-switcher',
    templateUrl: './generic-interface-block-switcher.component.html',
    styleUrls: ['./generic-interface-block-switcher.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericInterfaceBlockSwitcherComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, GenericInterfaceTextboxBlockComponent, FormsModule, ReactiveFormsModule, GenericInterfaceTextareaComponent, GenericInterfaceDropdownListComponent, GenericInterfaceDatePickerComponent, GenericInterfaceMultipleDropdownListComponent, GenericInterfaceForArrayComponent, GenericInterfaceForClassComponent, GenericInterfaceAutocompleteComponent, GenericInterfaceAutocompleteMultipleComponent, NgSwitchDefault]
})
export class GenericInterfaceBlockSwitcherComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input() interfaceBlock: UserInterfaceBlock;
  valueControl: UntypedFormControl = new UntypedFormControl();
  onChange: Function;
  onTouched: Function;
  subscribe: Subscription;

  constructor() {
      this.subscribe = this.valueControl.valueChanges.subscribe((v)=>{
        if(this.onChange){
          this.onChange(v)
        }
      })
  }

  ngOnDestroy(){
    if(this.subscribe) this.subscribe.unsubscribe();
  }

  ngOnInit() {
  }

  writeValue(obj: any): void {
      this.value = obj;
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  set value(value) {
      this.valueControl.setValue(value);
      if (this.onChange) {
          this.onChange(value);
      }
  }

  get value(): any {
      return this.valueControl.value;
  }
}