import { Routes } from '@angular/router';
import { HasLoginGuard } from '../common/guards';
import { AuthWrapperComponent } from './components/auth-wrapper/auth-wrapper.component';
import { ClientComponent } from './components/client/client.component';
import { CreateNewPasswordComponent } from './components/create-new-password/create-new-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { PlayerRegistrationComponent } from './components/player-registration/player-registration.component';

export const CltRoutes: Routes = [
    {
      path: '',
      component: AuthWrapperComponent,
      data: { withoutMenuContainer: true },
      children: [{
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'registration',
        component: PlayerRegistrationComponent,
      },
      {
        path: 'registration/:linkId',
        component: PlayerRegistrationComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'create-new-password/:userId/:token',
        component: CreateNewPasswordComponent,
      }]
    },
    {
      path: 'client',
      component: ClientComponent,
      canActivate: [HasLoginGuard]
    },
  ];
