import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { SetScoreDataController } from '../set-score-data-common/set-score-data-common.component';
import { SetDataExtensions } from './SetDataExtensions';
import {TennisSetData} from '../../../common/modules/tournament-admin/services';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';

export interface SetRawData{
  score: string;
  tieBreak: string;
}

export interface ScoreData{
  score1: number;
  score2:number;
}

export interface SetData extends ScoreData {
  tieBreak: ScoreData;
}

@Component({
    selector: 'game-scores-main',
    templateUrl: './scores-main.component.html',
    styleUrls: ['./scores-main.component.css'],
    standalone: true,
    imports: [NgFor, MatFormFieldModule, MatInputModule, NgxMaskModule, FormsModule, NgIf, MatButtonModule]
})
export class ScoresMainComponent implements OnInit {

  setsCount = 1;

  sets: TennisSetData[] = [];
  setRawDatas: SetRawData[] = [];
  tieBreakStrings: string[] = [];

  @Input()
  controller = new SetScoreDataController();

  constructor(private _notifier: NotifierService) {
    this.init();
  }

  init(){
    for(let i=0; i < 5; i++){
      this.sets.push({
        score1: "",
        score2: "",
        tieBreak: {
          score1: "",
          score2: "",
        }
      })

      this.setRawDatas.push({
        score: "",
        tieBreak: ""
      });
    }
  }

  getScore():SetData[]{
    let result: SetData[] = [];

    for(let i = 0; i < this.setsCount; i++){
      let parsed = SetDataExtensions.parseFromRaw(this.setRawDatas[i], i, e => this.errorHandler(e));

      if(!parsed){
        return null;
      }

      result.push(parsed);
    }

    return result;
  }

  setScore(value: SetData[]){
    if(!value){
      return;
    }

    for(let i = 0; i < value.length; i++){
      this.sets[i].score1 = value[i].score1.toString();
      this.sets[i].score2 = value[i].score2.toString();
      this.setRawDatas[i].score = ScoresMainComponent.toRawData(value[i])

      if(value[i].tieBreak){
        this.sets[i].tieBreak.score1 = value[i].tieBreak.score1.toString();
        this.sets[i].tieBreak.score2 = value[i].tieBreak.score2.toString();

        this.setRawDatas[i].tieBreak = ScoresMainComponent.toRawData(value[i].tieBreak);
      }
    }
  }

  static toRawData(score: ScoreData){
    if(score.score1 === 0 && score.score2 === 0){
      return "";
    }

    return score.score1.toString() + ":" + score.score2.toString();
  }

  errorHandler(error: string){
    NotificaterExtensions.ShowError(this._notifier, error);
  }


  addSet(){
    if(this.setsCount === 5){
      NotificaterExtensions.ShowError(this._notifier, "Попытка добавить шестой сет");
      return;
    }

    this.setsCount++;
  }

  removeSet(){
    if(this.setsCount === 1){
      NotificaterExtensions.ShowError(this._notifier, "Попытка удалить единственный сет");
      return;
    }

    this.setsCount--;
  }

  ngOnInit(): void {
    this.setScore(this.controller.value);
  }

}
