<div *ngIf="!isEdit">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            
        </div>
        <div class="d-flex justify-content-end">
            <div></div>
            <div class="d-flex" *ngIf="isAdmin || post.post.author.id === userId">
                <button (click)="edit()" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>
    
                <button (click)="remove()" mat-icon-button color="warn">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div>
        <div>{{post.date}}</div>
        <div><a [routerLink]="['/ptc/player', post.post.author.id]" href="...">{{post.post.author.surname}} {{post.post.author.name}}</a>  опубликовал запись</div>
    </div>
    
    <div style="height: 20px;">
    </div>
    
    <croco-html-xml-tag-view [data]="post.post.bodyHtml | topreview">
    </croco-html-xml-tag-view>
</div>

<div *ngIf="isEdit">
    <croco-visual-editor #htmlEditor [html]="post.post.bodyHtml"></croco-visual-editor>

    <div>
        <button (click)="save()" class="w-100" mat-raised-button color="primary">
            Сохранить
        </button>
    </div>
</div>