<div>

    <div *ngFor="let item of [].constructor(setsCount); let i = index">
        <mat-form-field class="w-100">
            <input matInput [placeholder]="i + 1 + ' Сет'" 
                new-autocomplete="off" 
                autocomplete="off"
                mask="0:0"
                [dropSpecialCharacters]="false"
                style="font-size: 28px;text-align:center" 
                [(ngModel)]="setRawDatas[i].score">
        </mat-form-field>

        <div style="height: 10px;"></div>
    </div>
    

    <div class="d-flex justify-content-between">
        <div>
            <button *ngIf="setsCount !== 1" class="w-100" (click)="removeSet()" mat-raised-button color="warn">
                Удалить сет
            </button>
        </div>
          
        <div style="width: 10px;">
        </div>
    
        <div>
            <button style="background-color: #198754;" *ngIf="setsCount !== 5" class="w-100" (click)="addSet()" mat-raised-button color="primary">
                Добавить сет
            </button>
        </div>
    </div>
    
</div>
