<mat-tab-group>
    <mat-tab label="Профиль">
        <div style="margin: 2px;">
            <mat-card *ngIf="player">
                <mat-card-content>
                    <div>
                        <app-client-avatar [size]="100" [class]="'mat-card-avatar'" [clientAvatarData]="player">
                        </app-client-avatar>
                    </div>
                    <div style="height: 10px;"></div>
                    <div>
                        {{player.surname}} {{player.name}}
                    </div>
                    <div>NTRP Рейтинг: {{player.ntrpRating}}</div>
                    <div>Игровой рейтинг: {{player.rating.toFixed(2)}}</div>
                    <div *ngIf="player.dataFilled">
                        <div>
                            Пол: {{player.sex? 'Мужской' : 'Женский'}}
                        </div>
                        <div>
                            {{player.aboutMe}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div *ngIf="isAdmin">
                <div style="height: 10px;"></div>
                <mat-card *ngIf="player">
                    <mat-card-content>
                        <div>
                            Номер телефона: {{player.phoneNumber}}
                        </div>
                        <div *ngIf="!player.noEmail">
                            Адрес электронной почты: {{player.email}}
                        </div>
                        <div>
                            Аккаунт подтвержден: {{player.accountConfirmed? 'Да': 'Нет'}}
                        </div>
                        <div>
                            Основные данные указаны: {{player.dataFilled? 'Да': 'Нет'}}
                        </div>
                        <div>
                            Email подтвержден: {{player.emailConfirmed? 'Да': 'Нет'}}
                        </div>
                    </mat-card-content>
                    
                </mat-card>
            </div>

            <div *ngIf="player && isAdmin && !player.accountConfirmed">
                <div style="height: 10px;"></div>
                <mat-card>
                    <mat-card-content>
                        <button (click)="confirmPlayer()" mat-raised-button color="primary">
                            Подтвердить игрока
                        </button>
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="player && isAdmin">
                <div style="height: 10px;"></div>

                <mat-card>
                    <button (click)="loginAsPlayer()" mat-raised-button color="primary">
                        Авторизоваться
                    </button>
                </mat-card>
            </div>

            <div *ngIf="isAdmin">
                <div *ngIf="balance">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Изменить баланс
                        </mat-expansion-panel-header>

                        <div>
                            Баланс: {{balance.balanceRub}}₽
                        </div>

                        <div style="height: 20px;"></div>

                        <mat-form-field class="w-100">
                            <input matInput placeholder="Сумма" type="number" [(ngModel)]="transaction.sum">
                        </mat-form-field>

                        <mat-form-field class="w-100">
                            <textarea matInput [(ngModel)]="transaction.description" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                placeholder="Описание транзакции"></textarea>
                        </mat-form-field>

                        <button (click)="addTransaction()" mat-raised-button color="primary">
                            Добавить транзакцию
                        </button>
                    </mat-expansion-panel>
                </div>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Изменить рейтинг
                    </mat-expansion-panel-header>

                    <div>
                        Рейтинг: {{player.rating}}
                    </div>

                    <div style="height: 20px;"></div>

                    <mat-form-field class="w-100">
                        <input matInput placeholder="Новый рейтинг" type="number" [(ngModel)]="rating.newRating">
                    </mat-form-field>

                    <button (click)="changeRating()" mat-raised-button color="primary">
                        Изменить рейтинг
                    </button>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Изменить пароль
                    </mat-expansion-panel-header>

                    <div style="height: 20px;"></div>

                    <mat-form-field class="w-100">
                        <input matInput placeholder="Новый пароль" [(ngModel)]="changePassModel.password">
                    </mat-form-field>

                    <button (click)="changePassword()" mat-raised-button color="primary">
                        Изменить пароль
                    </button>
                </mat-expansion-panel>
            </div>
        </div>


    </mat-tab>
    <mat-tab label="История игр">
        <ptc-player-games [playerId]="playerId" [isAdmin]="isAdmin">
        </ptc-player-games>
    </mat-tab>
    <mat-tab *ngIf="isAdmin" label="Админ панель">
        <mat-card *ngIf="player.registrationSource.includes('Test')">
            Удаление
        </mat-card>
    </mat-tab>
</mat-tab-group>