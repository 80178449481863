import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {BaseApiResponseWithFilesIds, FileUploadService} from '../../../../services';
import { MatButtonModule } from '@angular/material/button';

export interface FilePostingStarted {
  filesCount: number;
  createLocalCopiesNow: boolean;
}

@Component({
    selector: 'app-upload-files-btn',
    templateUrl: './upload-files-btn.component.html',
    standalone: true,
    imports: [MatButtonModule]
})
export class UploadFilesBtnComponent {

  constructor(private _fileUploadService: FileUploadService) { }
  @ViewChild('filesInput') private fileInput: ElementRef<HTMLInputElement>;

  @Input()
  extAccepts = '*/*';

  @Input()
  isMultiple = true;

  @Input()
  btnText = 'Загрузить файлы';

  @Input()
  createLocalCopiesNow = false;

  @Input()
  hidden = false;

  @Output()
  postFilesStarted = new EventEmitter<FilePostingStarted>();

  @Output()
  onFilesUploaded = new EventEmitter<BaseApiResponseWithFilesIds>();

  handleFileInput(files: FileList): void {
    this.postFilesStarted.emit({
      filesCount: files.length,
      createLocalCopiesNow: this.createLocalCopiesNow
    });

    this._fileUploadService.postFiles(files, this.createLocalCopiesNow).subscribe(data => {
      this.onFilesUploaded.emit(data);
    });
  }

  clickFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
