<div class="container padding-top-bottom padding-on-mobile">
  <div class="row justify-content-center">
    <div class="col-md-6 text-center mb-5">
      <h2 class="heading-section">Смена пароля</h2>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-4">
      <div class="login-wrap p-0">
            <form class="example-form" (submit)="sendRequest()">
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    placeholder="Новый пароль"
                    [attr.type]="showPassword ? 'text' : 'password'"
                    autocomplete="off"
                    new-autocomplete="off"
                    [formControl]="newPassword"
                  />
                </mat-form-field>
                <div class="show-password-icon-wraper">
                  <fa-icon
                    (click)="showPasswordToggle()"
                    [icon]="showPassword ? faEyeSlash : faEye"
                  ></fa-icon>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-primary submit px-3 btn-submit"
                [disabled]="loading"
              >
                Сменить пароль
              </button>
            </form>
      </div>
      <div style="height: 300px;">
      </div>
    </div>
  </div>
</div>
