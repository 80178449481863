import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { PostsListComponent } from 'src/app/blog/components/posts-list/posts-list.component';
import { LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentJoiningErrorType, TournamentPlayerService } from '../../services/TournamentPlayerService';
import {TournamentDetailedModel, TournamentQueryService} from '../../../common/modules/tournament-admin/services';
import { PtcRoles } from 'src/app/common/roles';
import { TournamentBracketComponent } from '../tournament-bracket/tournament-bracket.component';
import { MatTableModule } from '@angular/material/table';
import { TournamentGroupsViewComponent } from '../tournament-groups-view/tournament-groups-view.component';
import { TGridComponent } from '../t-grid/t-grid.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PostEditorComponent } from '../../../common/modules/tournament-admin/components/post-editor/post-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TournamentTypeLinkComponent } from '../tournament-type-link/tournament-type-link.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-ptc-tournament',
    templateUrl: './tournament.component.html',
    styleUrls: ['./tournament.component.css'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, NgIf, MatCardModule, TournamentTypeLinkComponent, MatExpansionModule, PostEditorComponent, PostsListComponent, MatTabsModule, TGridComponent, TournamentGroupsViewComponent, MatTableModule, TournamentBracketComponent]
})
export class TournamentComponent implements OnInit {

  joinTitle = "Записаться";
  tournamentId: string;
  tournament: TournamentDetailedModel;
  isAdmin = false;
  displayedColumns = ["id", "name"];
  showAddMoneyButton = false;

  @ViewChild("postList")
  postList: PostsListComponent;

  constructor(private _route: ActivatedRoute,
    private _notifierService: NotifierService,
    private _loginService: LoginService,
    private _tournamentService: TournamentQueryService,
    private _tournamentPlayerService: TournamentPlayerService,
    private _title: Title,
    ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.tournamentId = params['id'];
      this.loadTournament();
    });

    this._loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.tournamentAdmin);
    })
  }

  postAddedHandler(){
    this.postList.loadPosts();
  }

  loadTournament(): void{
    this._tournamentService.getById(this.tournamentId).subscribe(data => {
      this.tournament = data;

      this._title.setTitle(this.tournament.name);

      if(this.tournament.participationCostRub > 0){
        this.joinTitle = `Записаться ${this.tournament.participationCostRub}₽`
      }
    });
  }

  leave() {
    this._tournamentPlayerService.leave(this.tournament.id).subscribe(data => {
      this.handleResponse(data);
    })
  }

  join(){
    this._tournamentPlayerService.join(this.tournamentId).subscribe(data => {
      this.handleResponse(data);

      if(!data.isSucceeded && data.responseObject.errorType === TournamentJoiningErrorType.NotEnoughMoney){
        this.showAddMoneyButton = true;
      }
    })
  }

  handleResponse(data: BaseApiResponse){
    NotificaterExtensions.ShowBaseApiResponse(this._notifierService, data);

    if(data.isSucceeded){
      this.loadTournament();
    }
  }
}
