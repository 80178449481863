<div *ngIf="!isReady">
  <div class="d-flex justify-content-center align-items-center flex-column" style="min-height: 300px">
    <mat-spinner [diameter]="50" style="display: block;"></mat-spinner>
    
    <div>
        <span class="h4">Загрузка</span>
    </div>
  </div>
</div>

<div *ngIf="hasError && isReady">
  <div class="d-flex justify-content-center align-items-center flex-column" style="min-height: 300px">
    
    <div class="text-center">
        <span class="h4">{{errorMessage}}</span>
    </div>

    <div style="height: 10px;"></div>

    <div class="row justify-content-center">
      <button (click)="backToSite()" 
              class="btn btn-primary submit px-3 btn-next"
              [disabled]="loading">
              На главную
      </button>
    </div>
  </div>
</div>

<div *ngIf="!hasError && isReady && isAuthenticated" style="height: fit-content;">
  <div class="container padding-top-bottom padding-on-mobile">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center mb-5">
        <h2 class="heading-section">Вы не можете регистрироваться так как вы авторизованы как {{loginData.surname}} {{loginData.name}}</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <button (click)="backToSite()" 
              class="btn btn-primary submit px-3 btn-next"
              [disabled]="loading">
              На главную
      </button>
    </div>
  </div>
</div>


<div *ngIf="!hasError && isReady && !isAuthenticated"  style="height: fit-content;">
  <div class="container padding-top-bottom padding-on-mobile">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center mb-5">
        <h2 class="heading-section">Регистрация игрока</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 text-center mb-5">
        <div [hidden]="step !== 1">
          <h6 class="heading-section">Необходимо указать свой реальный Email и номер телефона.
            После регистрации на ваш адрес электронной почты будет отправлен код подтверждения.
          </h6>
          <h6 class="heading-section">
            Вовремя турнира мы будем отправлять на вашу почту уведомления о предстоящей игре и контакты соперника.
            Указывайте свой настоящий номер телефона, чтобы соперник смог с вами связаться.
          </h6>
        </div>

        <div [hidden]="step !== 0">
          <h6 class="heading-section">
            В разделе обо мне укажите как долго и как хорошо вы играете в теннис, также напишите здесь об удобных
            способах связи с вами и упомяните место и время в которое вам удобно играть во время турнира.
            Не указывайте здесь контактных данных, они будут доступны вашему сопернику, когда будет назначена игра.
          </h6>
        </div>
      </div>

    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        
        <div class="login-wrap p-0">

          <form [formGroup]="registrationForm">
            <div [hidden]="step !== 2">

              <div class="position-relative mb-3" *ngIf="phoneNumberOptions">
                <mat-form-field class="w-100">
                  <input matInput appDisableAutofill [(ngModel)]="registrationData.phoneNumber" 
                    placeholder="Номер телефона" 
                    [prefix]="phoneNumberOptions.maskedInputPrefix" 
                    [mask]="phoneNumberOptions.maskedInputMask"
                    autocomplete="new-password" new-autocomplete="off" formControlName="phoneNumber" />
                </mat-form-field>
              </div>

              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input matInput appDisableAutofill [(ngModel)]="registrationData.email" placeholder="Почта" autocomplete="off" new-autocomplete="off"
                    formControlName="email" />
                </mat-form-field>
              </div>


              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input matInput appDisableAutofill [(ngModel)]="registrationData.password" placeholder="Пароль"
                    [attr.type]="showPassword ? 'text' : 'password'" autocomplete="new-password"
                    formControlName="password" />
                </mat-form-field>
                <div class="show-password-icon-wraper">
                  <fa-icon (click)="showPasswordToggle()" [icon]="showPassword ? faEyeSlash : faEye"></fa-icon>
                </div>
              </div>
            </div>

            <div [hidden]="step !== 1">
              
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input matInput [(ngModel)]="registrationData.surname" appDisableAutofill placeholder="Фамилия" autocomplete="off" new-autocomplete="off"
                    formControlName="surname" />
                </mat-form-field>
              </div>
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input matInput [(ngModel)]="registrationData.name" placeholder="Имя" autocomplete="off" new-autocomplete="off" formControlName="name" />
                </mat-form-field>
              </div>

              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input matInput [(ngModel)]="registrationData.patronymic" placeholder="Отчество" autocomplete="off" new-autocomplete="off"
                    formControlName="patronymic" />
                </mat-form-field>
              </div>

              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <mat-label>Пол</mat-label>
                  <mat-select [(ngModel)]="registrationData.sex" formControlName="sex">
                    <mat-option *ngFor="let sex of sexes" [value]="sex.value">
                      {{sex.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="position-relative mb-3">
                <my-date-time-picker 
                #birthDatePicker
                [showLocalTime]="false"
                (onDateChanged)="registrationData.birthDate = $event"
                [showTime]="false"
                [dateLabel]="'Дата рождения'" >
                </my-date-time-picker>
              </div>

              <div class="position-relative mb-3">
                <mat-form-field class="textarea-field-wrapper w-100">
                  <textarea [(ngModel)]="registrationData.aboutMe" appDisableAutofill cdkTextareaAutosize matInput placeholder="Обо мне" new-autocomplete="off"
                    autocomplete="off" formControlName="aboutMe"></textarea>
                </mat-form-field>
              </div>

              <div class="position-relative mb-3">
                <ptc-ntrp-select #ntrpSelect></ptc-ntrp-select>
              </div>

              <div class="position-relative mb-3">
                <div class="text-center">
                  <a href="/info/ntrp" target="_blank" class="white-color">Не знаете как указать рейтинг?</a>
                </div>
              </div>
            </div>

            <div [hidden]="step !== 0">

              <div [hidden]="registrationData.noCityOrCountryFilled">
                <common-country-and-city-select #citySelect>
                </common-country-and-city-select>
  
                <div style="height: 10px;"></div>
              </div>

              <div [hidden]="!registrationData.noCityOrCountryFilled">
                <div class="position-relative mb-3">
                  <mat-form-field class="w-100">
                    <input matInput [(ngModel)]="registrationData.cityOrCountry"
                      placeholder="Ваш город"
                      autocomplete="off" new-autocomplete="off"
                      formControlName="cityOrCountry" />
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="!registrationData.noCityOrCountryFilled" class="position-relative mb-3">
                <div class="text-center">
                  <button (click)="noCityFoundClickHandler(true)" style="background-color: transparent;border: 0px;" class="white-color">Вашего города нет в списке?</button>
                </div>
              </div>

              <div *ngIf="registrationData.noCityOrCountryFilled" class="position-relative mb-3">
                <div class="text-center">
                  <button (click)="noCityFoundClickHandler(false)" style="background-color: transparent;border: 0px;" class="white-color">Мой город есть в списке</button>
                </div>
              </div>

              <div style="height: 20px;"></div>
            </div>

            <div *ngIf="step > 0">
              <button (click)="step = step - 1;" class="btn btn-primary submit px-3 btn-next" [disabled]="loading">
                Назад
              </button>
              <div style="height: 10px;"></div>
            </div>

            <button *ngIf="step === 0" (click)="submitZeroStep()" class="btn btn-primary submit px-3 btn-next"
              [disabled]="loading">
              Далее
            </button>

            <button *ngIf="step === 1" (click)="submitFirstStep()" class="btn btn-primary submit px-3 btn-next"
              [disabled]="loading">
              Далее
            </button>

            <button *ngIf="step === 2" (click)="registration()" class="btn btn-primary submit px-3 btn-submit"
              [disabled]="loading">
              Зарегистрироваться
            </button>


            <div class="form-group mt-3 d-md-flex">
              <div class="w-50">
                <a routerLink="/login" class="white-color">Уже есть аккаунт?</a>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div style="height: 70px;"></div>
    <div class="row justify-content-center">
      <div class="col-md-6 text-center mb-5">
        <h6 class="heading-section">
          Если у вас возникли вопросы или сложности, пожалуйста, напишите <b><a href="https://t.me/dimaserd" target="_blank">Дмитрию
              Сердюкову - 89166044960</a></b>.
        </h6>
      </div>
    </div>
  </div>
</div>