<div *ngFor="let game of tournament.events">
    <mat-card>
        <mat-card-content>
            <ptc-tournament-simple-match-card [game]="game" [tournament]="tournament">
            </ptc-tournament-simple-match-card>
        </mat-card-content>
    </mat-card>
    
    <div style="height: 10px;"></div>
</div>

