import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TournamentGroupGameModel } from '../../services/TournamentGroupService';
import { MappingExtensions } from '../t-grid/extensions/MappingExtensions';
import { PlayerInfo } from '../t-grid/t-grid.component';
import {TournamentDetailedModel} from '../../../common/modules/tournament-admin/services';
import { NgIf } from '@angular/common';
import { TournamentSimpleMatchCardComponent } from '../tournament-simple-match-card/tournament-simple-match-card.component';

@Component({
    selector: 'ptc-tournament-group-match-card',
    templateUrl: './tournament-group-match-card.component.html',
    styleUrls: ['./tournament-group-match-card.component.css'],
    standalone: true,
    imports: [TournamentSimpleMatchCardComponent, NgIf]
})
export class TournamentGroupMatchCardComponent implements OnInit {

  players: PlayerInfo[] =[];

  @Input()
  game:TournamentGroupGameModel = null;

  @Input()
  tournament:TournamentDetailedModel = null;

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.players = MappingExtensions.getPlayersByEvent(this.game.game, this.tournament.players);
  }

  matchClicked(){
    this._router.navigate(['/ptc/tournament/game', this.game.game.id]);
  }
}
