import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { FilePathProvider } from 'src/app/common/services';

@Component({
    selector: 'ptc-show-image',
    templateUrl: './show-image.component.html',
    styleUrls: ['./show-image.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class ShowImageComponent implements OnInit {

  @Input()
  imageFileId:number = null;

  src: string = "";

  constructor(private readonly _filePathProvider: FilePathProvider){
  }

  getSrc(fileId: number) {
    return this._filePathProvider.getMediumImageFilePath(fileId);
  }
  
  ngOnInit(): void {
    this.src = this.getSrc(this.imageFileId);
  }
}
