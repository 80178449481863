import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MappingExtensions } from '../t-grid/extensions/MappingExtensions';
import { PlayerInfo } from '../t-grid/t-grid.component';
import {TournamentDetailedModel, TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { TGridMatchComponent } from '../t-grid/t-grid-match/t-grid-match.component';

@Component({
    selector: 'ptc-tournament-simple-match-card',
    templateUrl: './tournament-simple-match-card.component.html',
    styleUrls: ['./tournament-simple-match-card.component.css'],
    standalone: true,
    imports: [TGridMatchComponent]
})
export class TournamentSimpleMatchCardComponent implements OnInit {

  players: PlayerInfo[] =[];

  @Input()
  game:TournamentEventModel = null;

  @Input()
  tournament:TournamentDetailedModel = null;

  constructor(private _router: Router) {
  }

  ngOnInit(): void {
    this.players = MappingExtensions.getPlayersByEvent(this.game, this.tournament.players);
  }

  matchClicked(){
    this._router.navigate(['/ptc/tournament/game', this.game.id]);
  }

}
