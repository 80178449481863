import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentJoiningErrorType, TournamentPlayerService } from '../../services/TournamentPlayerService';
import {TournamentSimpleModel} from '../../../common/modules/tournament-admin/services';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { TournamentTypeLinkComponent } from '../tournament-type-link/tournament-type-link.component';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
@Component({
    selector: 'ptc-tournament-card',
    templateUrl: './tournament-card.component.html',
    styleUrls: ['./tournament-card.component.css'],
    standalone: true,
    imports: [MatCardModule, RouterLink, TournamentTypeLinkComponent, NgIf, MatButtonModule]
})
export class TournamentCardComponent implements OnInit {

  @Input()
  tournament: TournamentSimpleModel;

  @Output()
  onChange = new EventEmitter<boolean>();

  @Input()
  public isAuthenticated = false;

  joinTitle = "Записаться";
  showAddMoneyButton = false;

  constructor(private _tournamentPlayerService: TournamentPlayerService,
    private _notifier: NotifierService) { }

  join() {
    this._tournamentPlayerService.join(this.tournament.id).subscribe(data => {
      this.handleResponse(data);

      if(!data.isSucceeded && data.responseObject.errorType === TournamentJoiningErrorType.NotEnoughMoney){
        this.showAddMoneyButton = true;
      }
    })
  }

  leave() {
    this._tournamentPlayerService.leave(this.tournament.id).subscribe(data => {
      this.handleResponse(data);
    })
  }

  handleResponse(data: BaseApiResponse) {
    NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

    if (data.isSucceeded) {
      this.onChange.emit(true);
    }
  }

  ngOnInit(): void {
    if(this.tournament.participationCostRub > 0){
      this.joinTitle = `Записаться ${this.tournament.participationCostRub}₽`
    }
  }

}
