import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetListResult } from 'src/app/common/models';
import { BaseApiResponse } from 'src/models/BaseApiResponse';

export interface AddPostToTournamentRequest {
    tournamentId: string;
    bodyHtml: string;
}

export interface SearchTournamentPosts {
    tournamentId: string;
    count: number | null;
    offSet: number;
}

export interface TournamentPostModel {
    id: string;
    bodyHtml: string;
    author: PostAuthorModel;
    createdOnUtc: string;
}

export interface PostAuthorModel {
    id: string;
    name: string;
    surname: string;
    avatarFileId: number | null;
}

export interface EditPostRequest {
    postId: string;
    bodyHtml: string;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentPostService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/tournament/post/';
    }

    add(model: AddPostToTournamentRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `Add`, model);
    }

    edit(model: EditPostRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `edit`, model);
    }

    delete(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `delete/${id}`, {});
    }

    get(model: SearchTournamentPosts): Observable<GetListResult<TournamentPostModel>> {
        return this._httpClient.post<GetListResult<TournamentPostModel>>(this._baseControllerUrl + 'search', model);
    }
}


