import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ClientModel } from '../../services/ClientService';
import { CurrentLoginData, LoginService } from '../../services/LoginService';
import {FilePathProvider} from '../../../common/services';
import { ClientAvatarComponent } from '../../../common/modules/shared/components/client-avatar/client-avatar.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-client-data',
    templateUrl: './client-data.component.html',
    styleUrls: ['./client-data.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, ClientAvatarComponent]
})
export class ClientDataComponent implements OnChanges {

  @Input() client: ClientModel;
  sex = "Не указано";
  avatarFilePath: string =null;


  loginData:CurrentLoginData;



  constructor(private _filePathProvider: FilePathProvider,
    private _loginService: LoginService) {
      this._loginService.getLoginDataCached().subscribe(data => {
        this.loginData = data;
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClientData(changes.client?.currentValue);
  }

  updateClientData(client: ClientModel){

    if(!client){
      return;
    }

    let sex = client.sex;

    if(sex !== null){
      this.sex = sex? "Мужской" : "Женский";
    }

    this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.client.avatarFileId);
  }

  ngOnInit(): void {
  }
}
