import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericBaseApiResponse } from 'src/models/BaseApiResponse';

export interface TelegramLinkResponse {
    command: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlayerAccountTelegramService {

    baseControlerUrl: string;

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + 'Api/ptc/Player/telegram/';
    }

    public createLink(): Observable<GenericBaseApiResponse<TelegramLinkResponse>> {
        return this._httpClient.post<GenericBaseApiResponse<TelegramLinkResponse>>(this.baseControlerUrl + `CreateLink`, {});
    }
}
