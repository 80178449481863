import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { NotifierService } from 'angular-notifier';
import { EMPTY } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '../../../common/components';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { NtrpSelectComponent } from 'src/app/player/components/ntrp-select/ntrp-select.component';
import { PlayerRegistrationRequest, PlayerRegistrationService } from 'src/app/player/services/PlayerRegistrationService';
import { CurrentLoginData, LoginService } from '../../services/LoginService';
import { PhoneNumberOptions, PhoneNumberOptionService } from '../../services/PhoneNumberOptionService';
import { RegistrationLinkService } from '../../services/RegistrationLinkService';
import {CountryAndCitySelectComponent} from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MyDateTimePickerComponent } from '../../../common/modules/shared/components/my-date-time-picker/my-date-time-picker.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DiableAutofillDirective } from '../../../common/modules/shared/directives/DiableAutofillDirective';
import { NgxMaskModule } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

interface Option {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-player-registration',
    templateUrl: './player-registration.component.html',
    styleUrls: ['./player-registration.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgxMaskModule, DiableAutofillDirective, FontAwesomeModule, MatSelectModule, NgFor, MatOptionModule, MyDateTimePickerComponent, TextFieldModule, NtrpSelectComponent, CountryAndCitySelectComponent, RouterLink]
})
export class PlayerRegistrationComponent
  extends DestroyableComponent
  implements OnInit {

  @ViewChild('birthDatePicker')
  birthDatePicker: MyDateTimePickerComponent;

  @ViewChild('citySelect')
  citySelect: CountryAndCitySelectComponent;

  @ViewChild('ntrpSelect')
  ntrpSelect: NtrpSelectComponent;

  isReady = false;
  hasError = false;
  errorMessage = "";
  isAuthenticated = false;
  linkExtracted = false;
  loginData: CurrentLoginData;

  phoneNumberOptions: PhoneNumberOptions = null;

  step = 0;
  showLoginData = false;
  showClientData = true;

  sexes: Option[] = [
    { value: 'true', viewValue: 'Мужской' },
    { value: 'false', viewValue: 'Женский' }
  ];

  public showPassword = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public loading = false;

  startDate = new Date(1990, 1, 1).toISOString();

  registrationData: PlayerRegistrationRequest ={
    email: "",
    name: "",
    surname: "",
    patronymic: "",
    phoneNumber: "",
    password: "",
    aboutMe: "",
    ntrpRating: null,
    sex: null,
    birthDate: this.startDate,
    cityId: null,
    countryId: null,
    noCityOrCountryFilled: false,
    cityOrCountry: "",
    registrationSource: "PlayTennis.Site",
    noEmail: false,
    useRegistrationLink: false,
    registrationLinkId: null
  };

  public registrationForm = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    surname: new UntypedFormControl(''),
    patronymic: new UntypedFormControl(''),
    phoneNumber: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    ntrpRating: new UntypedFormControl(''),
    sex: new UntypedFormControl('true'),
    aboutMe: new UntypedFormControl(''),
    cityOrCountry: new UntypedFormControl('')
  });

  cyrillicPattern = /[а-яА-ЯЁё]/;
  hidePatronymic = false;

  constructor(
    private _notifier: NotifierService,
    private _loginService: LoginService,
    private _playerRegistrationService: PlayerRegistrationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _linkService: RegistrationLinkService,
    private _phoneOptionService: PhoneNumberOptionService,
    titleService: Title
  ) {
    super();
    titleService.setTitle("Регистрация");
    _loginService.getLoginDataCached().subscribe(data => {
      this.isAuthenticated = data.isAuthenticated;
      this.loginData = data;
      this.setIsReady();
    })
  }

  setIsReady(){
    this.isReady = this.loginData !== null && this.linkExtracted;
  }

  extractLinkId(){
    this._route.params.subscribe(params => {
      var linkId = params['linkId'];

      this.checkRegistrationLink(linkId);
    });
  }

  checkRegistrationLink(link: string){
    if(!link){
      this.linkExtracted = true;
      this.setIsReady();
      return;
    }

    this._linkService.exists(link).subscribe(data => {

      if(!data){
        this.errorMessage = "Ваша ссылка для регистрации не действительна. Пожалуйста, попробуйте создать новую ссылку.";
        NotificaterExtensions.ShowError(this._notifier, this.errorMessage);
        this.hasError = true;
      }
      else{
        this.registrationData.useRegistrationLink = true;
        this.registrationData.registrationLinkId = link;
        this.registrationData.registrationSource = "PlayTennis.Site.ViaBot"
      }
      this.linkExtracted = true;
      this.setIsReady();
    });

  }

  backToSite(){
    this._router.navigate(['']);
  }

  noCityFoundClickHandler(flag:boolean){

    this.registrationData.noCityOrCountryFilled = flag;

    if(this.registrationData.noCityOrCountryFilled){
    }
  }

  submitFirstStep(){

    this.registrationData.ntrpRating = this.ntrpSelect.getValue();

    if(this.registrationData.surname === ""){
      return this.showError("Вы не указали вашу фамилию.");
    }

    if(this.registrationData.name === ""){
      return this.showError("Вы не указали ваше имя.");
    }

    if(this.registrationData.patronymic === ""){
      return this.showError("Вы не указали ваше отчество.");
    }

    if(this.registrationData.sex === null){
      return this.showError("Вы не указали ваш пол.");
    }

    if(this.registrationData.ntrpRating === null){
      return this.showError("Вы не указали NTRP рейтинг");
    }

    if(this.registrationData.aboutMe === ""){
      return this.showError("Вы ничего не указали в разделе обо мне.");
    }

    this.step = 2;
  }

  showError(errorMessage: string){
    NotificaterExtensions.ShowError(this._notifier, errorMessage);
  }

  noCityClickHandler(){

  }

  submitZeroStep(){


    if(!this.citySelect.city || !this.citySelect.country){
      return this.showError("Необходимо выбрать страну и город");
    }

    this.registrationData.cityId = this.citySelect.city.id;
    this.registrationData.countryId = this.citySelect.country.id;

    this._phoneOptionService.get(this.registrationData.countryId).subscribe(data => {
      this.phoneNumberOptions = data;
    });

    this.step = 1;
  }

  ngOnInit() {
    this.extractLinkId();
  }

  setAutoFillOff(event: any) {
    if (event) {
      event.target.attributes['autocomplete'].value = 'chrome-off';
    }
  }

  public registration(): void {

    var sex = this.registrationData.sex as any;

    let registrationDataCopy = JSON.parse(JSON.stringify(this.registrationData)) as PlayerRegistrationRequest;

    registrationDataCopy.birthDate = this.getDate();
    registrationDataCopy.sex = sex === "true";

    registrationDataCopy.phoneNumber = this.phoneNumberOptions.maskedInputPrefix + registrationDataCopy.phoneNumber;
    if (this.cyrillicPattern.test(registrationDataCopy.email)) {
      NotificaterExtensions.ShowError(this._notifier, "В поле Email присутствуют недопустимые символы кирилического алфавита.");
      return;
    }

    this.loading = true;

    this._playerRegistrationService
      .register(registrationDataCopy)
      .pipe(
        switchMap((result) => {
          var message = result.succeeded ? "Вы успешно зарегистрированы" : result.message;
          result.message = message;

          if(result.succeeded){
            NotificaterExtensions.ShowSuccess(this._notifier, result.message);
          }
          else{
            NotificaterExtensions.ShowError(this._notifier, result.message);
          }

          if (result.succeeded) {
            return this._loginService.loginByEmail({
              email: registrationDataCopy.email,
              password: registrationDataCopy.password,
              rememberMe: true,
            });
          } else {
            this.loading = false;
            return EMPTY;
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((result) => {
        this.loading = false;
        if (result) {

          if(result.succeeded){
            NotificaterExtensions.ShowSuccess(this._notifier, "Вы успешно авторизованы");
          }
          else{
            NotificaterExtensions.ShowError(this._notifier, result.errorMessage);
          }

          if (result.succeeded) {
            this._router.navigate(['ptc/info']);
          }
        }
      });
  }

  getDate():string{

    let attrValue = this.birthDatePicker.dateStr;
    let value = new Date(attrValue).toISOString();

    return value;
  }


  public showPasswordToggle(): void {
    this.showPassword = !this.showPassword;
  }
}
