<div *ngIf="showDebugData">
      <div>
        <b>data</b>
      </div>      
      <div>
        {{data | json}}
      </div>
      
      <div style="height:20px"></div>

      <div>
        <b>scoreText</b>
      </div>      
      <div>
        {{scoreText}}
      </div>
</div>


<div>
    

    <div class="d-flex justify-content-between">
        
        <!--Игрок слева-->
        <div class="w-100">
            <ptc-game-player-card [player]="data.player1">
            </ptc-game-player-card>
        </div>

        <!--Посередине-->
        <div style="width: 150px;">
        </div>
        
        <!--Игрок справа-->
        <div class="w-100">
            <ptc-game-player-card [player]="data.player2">
            </ptc-game-player-card>
        </div>
    </div>

    <div *ngIf="!editMode">
        <div style="height:20px">
        </div>
    
        <div *ngIf="winner" class="d-flex justify-content-center">
            
            <ptc-scores-single-as-text 
                [player1]="data.player1" 
                [player2]="data.player2" 
                [isWinning]="isWining"
                [scoreText]="scoreText">
            </ptc-scores-single-as-text>
        </div>
        
    
        <div *ngIf="data.game.imageFileId">
            <div style="height:20px">
            </div>
            <ptc-show-image [imageFileId]="data.game.imageFileId"></ptc-show-image>
        </div>
    </div>
    

    <div style="height:20px">
    </div>

</div>