<mat-card>
    <mat-card-content>
        <div *ngIf="isLoginLoaded && game && data && tournament">
            <a mat-stroked-button [routerLink]="['/ptc/tournament', tournament.id]" href="...">
                Турнир {{tournament.name}}
            </a>
            <div>
                <div>
                    <div style="height: 20px;"></div>
    
                    <!--когда редактируется, показывается только иконки и имена игроков-->
                    <ptc-game-common #gameData 
                        [scoreData]="game.scoreData"
                        [winner]="winner"
                        [data]="data"
                        [dataLoading]="dataLoading"
                        [editMode]="editMode">
                    </ptc-game-common>
    
    
                    <div *ngIf="isAuthenticated">
                        <div *ngIf="game.canSetScore && !game.resultConfirmed && game.hasScoreData && !editMode">
                            <button mat-raised-button class="w-100" (click)="confirmScore()" color="primary">
                                Подтвердить результат
                            </button>
                        </div>
                        <div *ngIf="game.canSetScore && game.resultConfirmed && isAdmin">
                            <button mat-raised-button class="w-100" (click)="removeScoreConfirmation()" color="accent">
                                Сбросить результат
                            </button>
                        </div>
                    </div>
    
                    
                    <div style="height: 20px;"></div>
                    
    
                    <div class="d-flex justify-content-center" *ngIf="!isAuthenticated">
                        <div>
                            Необходимо <a [routerLink]="['/login']">авторизоваться</a> чтобы указывать или редактировать счёт.
                        </div>
                    </div>
    
                    <div *ngIf="isAuthenticated && game.canSetScore && !game.resultConfirmed">
                        <button mat-raised-button color="primary" class="w-100" *ngIf="!editMode" (click)="startEdit()">
                            Редактировать счёт
                        </button>
    
                        <div style="height: 20px;"></div>
    
                        <button mat-raised-button color="warn" class="w-100" *ngIf="editMode" (click)="editMode = false;">
                            Отмена
                        </button>
                    </div>
    
                    
                    <!--Ограничения для игры-->
                    <div *ngIf="!game.resultConfirmed">
                        <div style="height: 20px;"></div>
                        <div class="d-flex justify-content-center" *ngIf="game.dateRestrictionUtc">
                            <h3>Игру необходимо закончить до {{dateRestrictionLocalStr}}</h3>
                        </div>
        
                        <div class="d-flex justify-content-center" *ngIf="!game.dateRestrictionUtc">
                            <div>
                                <h3 class="text-center">Для данной игры нет ограничения по дате</h3>
                            </div>
                        </div>
                    </div>
    
                    <!--Данные о сопернике-->
                    <div *ngIf="opponentData">
                        <div style="height: 20px;">
                        </div>
    
                        <div>
                            <h6>Контактные данные соперника</h6>
                        </div>
    
                        <div>
                            {{opponentData.surname}} {{opponentData.name}} {{opponentData.patronymic}}
                        </div>
                        <div>
                            Номер телефона: {{opponentData.phoneNumber}}
                        </div>
                        <div>
                            Email: {{opponentData.email}}
                        </div>
    
                        <div style="height: 20px;">
                        </div>
                    </div>
    
                    <div style="height: 10px;"></div>
                </div>
            </div>
        </div>
    </mat-card-content>
    
</mat-card>

<div *ngIf="gameResponse && editMode">
    <div style="height: 20px;"></div>

    <div>
        <ptc-set-full-game-data 
            [player1]="gameResponse.players[0]"
            [player2]="gameResponse.players[1]"
            (gameUpdated)="gameUpdatedHandler($event)"
            [controller]="setGameController">
        </ptc-set-full-game-data>
    </div>
</div>

<div style="height: 20px;"></div>

<ptc-tournament-game-admin-card *ngIf="isAdmin" 
    (gameUpdated)="loadGame()"
    [isAdmin]="isAdmin"
    [game]="game">
</ptc-tournament-game-admin-card>

<div style="height: 20px;"></div>