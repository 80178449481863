import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PlayerSimpleModel } from "src/app/game/services/GameService";
import { GenericBaseApiResponse } from "src/models/BaseApiResponse";
import {TournamentEventModel} from '../../common/modules/tournament-admin/services';

export interface TournamentGroupModel {
    name: string;
    participants: Array<TournamentGroupParticipantModel>;
    games: Array<TournamentGroupGameModel>;
}

export interface TournamentGroupGameModel {
    game: TournamentEventModel;
    matchPoints: TennisMatchPointsData;
}

export interface TournamentGroupParticipantModel {
    player: PlayerSimpleModel;
    winsCount: number;
    pointsCount: number;
}

export interface TennisMatchPointsData {
    gameId: string;
    playerPoints: Array<PlayerPointsData>;
}


export interface PlayerPointsData {
    playerId: string;
    points: number;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentGroupService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + "api/ptc/tournament/groups/";
    }

    get(tournamentId:string) {
        return this._httpClient.get<GenericBaseApiResponse<TournamentGroupModel[]>>(this._baseControllerUrl + `get/${tournamentId}`);
    }
}
