import { Component, OnInit } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

interface Option {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'ptc-ntrp-select',
    templateUrl: './ntrp-select.component.html',
    styleUrls: ['./ntrp-select.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class NtrpSelectComponent implements OnInit {


  ratings: Option[] = [
    { value: '1.0', viewValue: '1.0' },
    { value: '1.5', viewValue: '1.5' },
    { value: '2.0', viewValue: '2.0' },
    { value: '2.5', viewValue: '2.5' },
    { value: '3.0', viewValue: '3.0' },
    { value: '3.5', viewValue: '3.5' },
    { value: '4.0', viewValue: '4.0' },
    { value: '4.5', viewValue: '4.5' },
    { value: '5.0', viewValue: '5.0' },
    { value: '5.5', viewValue: '5.5' },
    { value: '6.0', viewValue: '6.0' },
    { value: '6.5', viewValue: '6.5' },
    { value: '7.0', viewValue: '7.0' },
  ];

  data = {
    ntrpRating: "1.0"
  };

  getValue(){
    return this.data.ntrpRating;
  }

  setValue(value: string){
    this.data.ntrpRating = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
