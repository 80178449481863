<div *ngIf="isDebug">

    <mat-card>
        <mat-card-content>
            <h1>Дебаг информация не показывается на проде</h1>

            <div>
                <b>step = {{step}}</b>
            </div>
            <div style="height: 10px;"></div>

            <div>
                <b>player1:</b>
            </div>
            <div>
                {{player1 | json }}
            </div>

            <div style="height: 10px;"></div>

            <div>
                <b>player2:</b>
            </div>
            <div>
                {{player2 | json }}
            </div>

            <div style="height: 10px;"></div>

            <div>
                <b>scoreData:</b>
            </div>
            <div>
                {{model.scoreData | json }}
            </div>

            <div style="height: 10px;"></div>

            <div>
                <b>controller:</b>
            </div>
            <div>
                {{controller | json }}
            </div>

        </mat-card-content>
    </mat-card>

    <div style="height:20px"></div>
</div>


<div [hidden]="step !== 0">
    <mat-card>
        <mat-card-content>
            <ptc-set-score-data-common [player1]="player1" [player2]="player2" (scoreSet)="scoreSetHandler($event)"
                [controller]="scoreController">
            </ptc-set-score-data-common>
        </mat-card-content>
    </mat-card>

    <div style="height: 20px;"></div>
</div>

<div *ngIf="step === 1">

    <mat-card>
        <mat-card-content>
            <ptc-scores-single-as-text [player1]="player1" [player2]="player2" [isWinning]="model.isWinning"
                [scoreText]="model.scoreText">
            </ptc-scores-single-as-text>

            <div style="height: 20px;"></div>

            <button class="w-100" (click)="step = 0;" mat-raised-button color="primary">
                Указать другой счёт
            </button>
        </mat-card-content>

    </mat-card>

    <div style="height: 10px;"></div>

    <mat-card>
        <mat-card-content>
            <ptc-game-court-data (dataHandler)="courtDataHandler($event)">
            </ptc-game-court-data>
        </mat-card-content>
    </mat-card>

    <div style="height: 20px;"></div>
</div>

<div *ngIf="step === 2">

    <mat-card>
        <mat-card-content>
            <ptc-scores-single-as-text [player1]="player1" [player2]="player2" [isWinning]="model.isWinning"
                [scoreText]="model.scoreText">
            </ptc-scores-single-as-text>
        </mat-card-content>
    </mat-card>

    <div style="height: 20px;"></div>

    <mat-card>
        <mat-card-content>
            <ptc-show-court-data [courtData]="model.courtData" (clicked)="step = 1;">
            </ptc-show-court-data>
        </mat-card-content>

    </mat-card>

    <div style="height: 20px;"></div>

    <mat-card>
        <mat-card-content>
            <ptc-game-image [imageFileId]="model.imageFileId"
                (imageUpdated)="model.imageFileId = $event; showToStep3Btn = true;"
                (fileSelected)="showToStep3Btn = false;">
            </ptc-game-image>

            <div *ngIf="showToStep3Btn">
                <div style="height: 10px;"></div>

                <button (click)="step = 3" class="w-100" mat-raised-button color="primary">
                    Далее
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div style="height: 20px;"></div>
</div>

<div *ngIf="step === 3">

    <mat-card>
        <mat-card-content>
            <ptc-scores-single-as-text [player1]="player1" [player2]="player2" [isWinning]="model.isWinning"
                [scoreText]="model.scoreText">
            </ptc-scores-single-as-text>
        </mat-card-content>

    </mat-card>

    <div style="height: 20px;"></div>

    <mat-card>
        <mat-card-content>
            <ptc-show-court-data [courtData]="model.courtData" (clicked)="step = 2;">
            </ptc-show-court-data>
        </mat-card-content>

    </mat-card>

    <div style="height: 20px;"></div>

    <mat-card>
        <mat-card-content>
            <div *ngIf="!model.imageFileId">
                <div>
                    <span class="h6">Вы не загрузили фотографию вашего матча</span>
                </div>

                <div style="height: 20px;"></div>

                <button (click)="step = 2" class="w-100" mat-raised-button color="primary">
                    Хорошо, прикреплю фото
                </button>

                <div style="height: 20px;"></div>

                <button (click)="createGame()" class="w-100" mat-raised-button color="warn">
                    Опубликовать без фотографии
                </button>
            </div>

            <div *ngIf="model.imageFileId">

                <ptc-show-image [imageFileId]="model.imageFileId"></ptc-show-image>

                <div style="height:20px"></div>

                <button (click)="createGame()" class="w-100" mat-raised-button color="primary">
                    Опубликовать игру
                </button>

                <div style="height: 20px;"></div>

                <button (click)="step = 2;" class="w-100" mat-raised-button color="warn">
                    Изменить фотографию
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div style="height: 20px;"></div>

</div>