import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PlayerModel } from '../../services/PlayerQueryService';
import { PlayerSearchListComponent } from '../player-search-list/player-search-list.component';
import { SiteInfoComponent } from '../../../tournament/components/site-info/site-info.component';
import { PlayerDataConfirmEmailComponent } from '../player-data-confirm-email/player-data-confirm-email.component';

@Component({
    selector: 'ptc-players-list',
    templateUrl: './players-list.component.html',
    styleUrls: ['./players-list.component.css'],
    standalone: true,
    imports: [PlayerDataConfirmEmailComponent, SiteInfoComponent, PlayerSearchListComponent]
})
export class PlayersListComponent implements OnInit {

  totalCount = 0;
  pageSize = 10;
  shouldConfirm = false;

  constructor(title: Title,
    private _router: Router 
    ) {
    title.setTitle("Поиск игроков");
  }

  playerSelectedHandler(player:PlayerModel){
    this._router.navigateByUrl(`ptc/player/${player.id}`);
  }
  
  ngOnInit(): void {
  }
}
