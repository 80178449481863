import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentDetailedModel, TournamentAdminPlayerService } from '../../services';
import { SearchPlayersComponent, SearchPlayersFormData } from '../search-players/search-players.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'ptc-edit-tournament-players',
    templateUrl: './edit-tournament-players.component.html',
    styleUrls: ['./edit-tournament-players.component.css'],
    standalone: true,
    imports: [MatExpansionModule, SearchPlayersComponent, NgIf, MatButtonModule, MatCardModule, CdkDropList, NgFor, CdkDrag, CdkDragHandle, RouterLink, MatIconModule]
})
export class EditTournamentPlayersComponent implements OnInit {

  @ViewChild("searchComponent")
  searchComponent: SearchPlayersComponent;

  @Output()
  updated = new EventEmitter<boolean>();

  @Input()
  tournament:TournamentDetailedModel;

  constructor(private _tournamentPlayerService: TournamentAdminPlayerService,
    private _notifier: NotifierService){

  }
  ngOnInit(): void {
  }

  searchPlayersToAdd: (data: SearchPlayersFormData) => Observable<PlayerModel[]>
    = data => this._tournamentPlayerService.getPlayersToAdd({
      tournamentId: this.tournament.id,
      q: data.q,
      count: 10,
      offSet: 0
    }).pipe(map(x => x.list));

  add(){
    this._tournamentPlayerService.add({
      tournamentId:this.tournament.id,
      playerId: this.searchComponent.playerId
    }).subscribe(data => this.handleResponse(data));
  }

  delete(playerId: string){
    this._tournamentPlayerService.delete({
      tournamentId:this.tournament.id,
      playerId
    }).subscribe(data => {
      this.handleResponse(data);
    })
  }

  handleResponse(data: BaseApiResponse){
    NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

    if(data.isSucceeded){
      this.updated.emit(true);
    }
  }

  saveOrder():void{
    this._tournamentPlayerService.saveOrder({
      tournamentId: this.tournament.id,
      playerIds: this.tournament.players.map(x => (x.id))
    }).subscribe(data => {
      this.handleResponse(data);
    })
  }

  drop(event: CdkDragDrop<PlayerSimpleModel[]>) {
    moveItemInArray(this.tournament.players, event.previousIndex, event.currentIndex);
  }
}
