import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { timer } from 'rxjs';
import { CurrentLoginData, LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login-link',
    templateUrl: './login-link.component.html',
    styleUrls: ['./login-link.component.css'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule]
})
export class LoginLinkComponent implements OnInit {

  inProccess = true;

  hasError = false;

  errorText = "";

  loginId: string = "";
  password: string = "";
  command:string = "";

  loginData: CurrentLoginData;

  constructor(private _route: ActivatedRoute,
    private _notifier: NotifierService,
    private _router: Router,
    private _loginService: LoginService) {
  }

  login(){
    this._loginService.loginByLink({
      loginId: this.loginId,
      password: this.password
    }).subscribe(data => {

      if(!data.isSucceeded){
        NotificaterExtensions.ShowError(this._notifier, "Ошибка авторизации по ссылке");
        
        this.errorText = "Ваша ссылка авторизации из Telegram-бота уже не валидна. Авторизация по ссылке доступна в течение 5 минут. Запросите новую ссылку, написав что-нибудь боту.";
        
        this.inProccess = false;
        this.hasError = true;
        return;
      }

      this._loginService.clearLoginDataCache();
      this._loginService.getLoginDataCached().subscribe(login => {
        this.loginData = login;

        timer(1500).subscribe(() => {

          if(this.command){

            var url = this.extractUrl(this.command);
            this._router.navigateByUrl(url);
            return;
          }

          this._router.navigateByUrl('');
        })
      });
    })
  }

  extractUrl(str: string):string{
    if(str === 'root'){
      return '';
    }
    
    var res = "/";

    for(let i = 0; i < str.length; i++){
      if(str[i]=== '.'){
        res += '/';
      }
      else{
        res += str[i];
      }
    }

    return res;
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.loginId = params['loginId'];
      this.password = params['password'];
      this.command = params['command'];
      this.login();
    });
  }
}
