import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { VisualEditorComponent, CrocoHtmlModule } from 'croco-html';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {TournamentPostService} from '../../services';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'blog-post-editor',
    templateUrl: './post-editor.component.html',
    styleUrls: ['./post-editor.component.css'],
    standalone: true,
    imports: [CrocoHtmlModule, MatButtonModule]
})
export class PostEditorComponent implements OnInit {

  @Input()
  tournamentId: string;

  fileIds:number[] = [];

  @ViewChild("htmlEditor")
  htmlEditor: VisualEditorComponent;

  @Output()
  onPostAdded = new EventEmitter<boolean>();

  constructor(private _postService: TournamentPostService,
    private _notifier: NotifierService) {
  }

  ngOnInit(): void {
  }

  addPost(){

    if(!this.htmlEditor.isHtmlSet()){
      NotificaterExtensions.ShowError(this._notifier, "Ваш пост пуст");
      return;
    }

    this._postService.add({
      tournamentId: this.tournamentId,
      bodyHtml: this.htmlEditor.getHtml()
    }).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.htmlEditor.clearHtml();
        this.onPostAdded.emit(true);
      }
    })
  }
}
