<div style="height: 20px;"></div>

<mat-card>
    <mat-card-content>
        <common-country-and-city-select #countryCitySelect (valueChanged)="cityChangedHandler()">
        </common-country-and-city-select>
    
        <div style="height: 10px;">
        </div>
    
        <div>
            <mat-form-field class="w-100">
                <input matInput placeholder="Поисковая строка"
                    new-autocomplete="off" autocomplete="off"
                    [(ngModel)]="model.q">
            </mat-form-field>
    
            <div [hidden]="!isAdmin">
    
                <common-boolean-select-list [options]="accountConfirmedSelectOptions" (valueChanged)="model.accountConfirmed = $event">
                </common-boolean-select-list>
    
                <div>
                    <mat-checkbox [(ngModel)]="useRegistrationSource">
                        Фильтровать по источнику регистрации
                    </mat-checkbox>
                </div>
    
                <div style="height: 10px;">
                </div>
    
                <div [hidden]="!useRegistrationSource">
                    <mat-form-field class="w-100">
                        <mat-label>Источник регистрации</mat-label>
                        <mat-select [(ngModel)]="model.registrationSource">
                            <mat-option *ngFor="let option of registrationSourceOptions" value="{{option.value}}">{{option.text}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
            </div>
    
        </div>
    
        <div style="height: 10px;"></div>
    
        <button mat-stroked-button color="primary" (click)="searchPlayers(0)">
            Поиск
        </button>
    </mat-card-content>   
</mat-card>

<div style="height: 20px;">
</div>

<div *ngFor="let player of players">
    <mat-card>
        <mat-card-content>
            <ptc-player-card [player]="player" (playerClicked)="playerSelectedHandler($event)"></ptc-player-card>
        </mat-card-content>
    </mat-card>
    <div style="height: 10px;"></div>
</div>

<base-app-page-numbers-select (onPageUpdated)="onPageUpdatedHandler($event)" [totalCount]="totalCount">
</base-app-page-numbers-select>

<div style="height: 20px;"></div>
