import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/clt/services/LoginService';
import { Observable } from 'rxjs';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { PtcRoles } from '../roles';

@Injectable({
  providedIn: 'root',
})
export class HasPtcAdminGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) { }
  canActivate(): Observable<boolean | UrlTree> {
    return this.loginService.getLoginDataCached().pipe(map(({ roles }) => {
      return roles.includes(PtcRoles.admin) ? true : this.router.parseUrl('/login');
    }));
  }
}
