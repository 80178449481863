import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { EditTournament, TournamentService } from '../../services/TournamentService';
import { TRestrictionsSelectComponent } from '../t-restrictions-select/t-restrictions-select.component';
import {TournamentDetailedModel, TournamentQueryService} from '../../services';
import { MessagingPanelComponent } from '../messaging-panel/messaging-panel.component';
import { EditTournamentPlayersComponent } from '../edit-tournament-players/edit-tournament-players.component';
import { TournamentNewsEditorComponent } from '../tournament-news-editor/tournament-news-editor.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ExternalDataEditorComponent } from '../external-data-editor/external-data-editor.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-edit-tournament',
    templateUrl: './edit-tournament.component.html',
    styleUrls: ['./edit-tournament.component.css'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatTabsModule, NgIf, MatCardModule, MatExpansionModule, ExternalDataEditorComponent, MatFormFieldModule, FormsModule, MatInputModule, TRestrictionsSelectComponent, TextFieldModule, TournamentNewsEditorComponent, EditTournamentPlayersComponent, MessagingPanelComponent]
})
export class EditTournamentComponent implements AfterViewInit {

  @ViewChild("restrictionsSelect")
  restrictionsSelect: TRestrictionsSelectComponent;

  tournamentId: string;
  tournament: TournamentDetailedModel;

  tournamentRenameModel = {
    name: "",
    description: "",
    genderRestriction: null
  };

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _tournamentService: TournamentService,
    private _queryService: TournamentQueryService,
    private _notifier: NotifierService,
    private _title: Title,
    private _cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.getIdAndTournament();
  }

  finish(){
    this._tournamentService
      .finish(this.tournamentId)
      .subscribe(data => this.handleResponse(data));
  }

  deleteTournament(){
    this._tournamentService
      .delete(this.tournamentId)
      .subscribe(data => {
        NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

        if(data.isSucceeded){
          this._router.navigate(['']);
        }
      });
  }

  ngOnInit(): void {
    this.getIdAndTournament();
  }

  getIdAndTournament(){
    this._route.params.subscribe(params => {
      this.tournamentId = params['id'];
      this.loadTournament();
    });
  }

  ngAfterViewChecked(): void {
    this._cdr.detectChanges();
  }

  updateNews(){
    this._tournamentService.editNewsHtml({
      id:this.tournament.id,
      newsHtml: this.tournament.newsHtml
    }).subscribe(data => this.handleResponse(data));
  }

  changeParticipationCost(){
    this._tournamentService.editCost({
      id: this.tournament.id,
      participationCostRub: this.tournament.participationCostRub
    }).subscribe(data => {
      this.handleResponse(data);
    })
  }

  clearGames(){
    this._tournamentService.clearGames(this.tournamentId).subscribe(data => {
      this.handleResponse(data);
    })
  }

  computeGames(){
    this._tournamentService.computeGames(this.tournamentId).subscribe(data => {
      this.handleResponse(data);
    })
  }

  rename(){
    let model: EditTournament = {
      id:this.tournament.id,
      name:this.tournamentRenameModel.name,
      description: this.tournamentRenameModel.description,
      genderRestriction: this.tournamentRenameModel.genderRestriction
    };

    this._tournamentService.edit(model).subscribe(data => {
      this.handleResponse(data);
    })
  }

  onRestrictionChanged(restriction: boolean | null){
    this.tournamentRenameModel.genderRestriction = restriction;
  }

  openForParticipation(){
    this._tournamentService.editParticipation({
      id:this.tournamentId,
      open:true
    }).subscribe(data => {
      this.handleResponse(data);
    })
  }

  start(){
    this._tournamentService.start(this.tournamentId).subscribe(data => {
      this.handleResponse(data);
    });
  }

  handleResponse(data: BaseApiResponse){
    NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    this.loadTournament();
  }

  loadTournament(): void{
    this.tournament = null;

    this._queryService.getById(this.tournamentId).subscribe(data => {
      this.tournament = data;

      this.tournamentRenameModel = JSON.parse(JSON.stringify(this.tournament));

      this.restrictionsSelect?.setValue(this.tournament.genderRestriction);

      this._title.setTitle(this.tournament.name);
    });
  }
}
