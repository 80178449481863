import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface Option {
  value: string;
  text: string
}

export class CourtTypes {
  public static readonly Hard = "Hard";
  public static readonly Clay = "Clay";
  public static readonly Grass = "Grass";

  public static readonly CourtTypesWithDescriptions: Option[] = [
    {
      value: CourtTypes.Hard,
      text: "Хард"
    },
    {
      value: CourtTypes.Clay,
      text: "Грунт"
    },
    {
      value: CourtTypes.Grass,
      text: "Трава"
    },
  ];

  public static getDescription(type: string):string{
    let value = CourtTypes.CourtTypesWithDescriptions
      .find(x => x.value === type);

    if(value !== null){
      return value.text;
    }

    return "Не найдено";
  }
};

@Component({
    selector: 'ptc-court-types-select',
    templateUrl: './court-types-select.component.html',
    styleUrls: ['./court-types-select.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class CourtTypesSelectComponent implements OnInit {

  valueStr: string = CourtTypes.Hard;

  options = CourtTypes.CourtTypesWithDescriptions;

  changedHandler(data:MatSelectChange){
    this.valueStr = data.value.toString();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
