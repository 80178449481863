<mat-expansion-panel
  [hideToggle]="!menuItem.children"
  [disabled]="!menuItem.children"
>
  <mat-expansion-panel-header
    *ngIf="!menuItem.children; else headerWithoutLink"
    [routerLink]="menuItem.link"
    [collapsedHeight]="'48px'"
    [expandedHeight]="'48px'"
  >
    <mat-panel-title [style.padding-left]="nesting * 15 + 'px'">
      {{ menuItem?.title }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template #headerWithoutLink>
    <mat-expansion-panel-header
      [collapsedHeight]="'48px'"
      [expandedHeight]="'48px'"
    >
      <mat-panel-title [style.padding-left]="nesting * 15 + 'px'">
        {{ menuItem?.title }}
      </mat-panel-title>
    </mat-expansion-panel-header>
  </ng-template>

  <app-side-nav-menu-item
    *ngFor="let childItem of menuItem.children"
    [menuItem]="childItem"
    [nesting]="nesting + 1"
  ></app-side-nav-menu-item>
</mat-expansion-panel>