import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';
import { GameScoreDataComponent } from 'src/app/tournament/components/game-score-data/game-score-data.component';
import { PlayerSimpleModel } from '../../services/GameService';
import { SetDataExtensions } from '../scores-main/SetDataExtensions';
import {TennisMatchData, TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { ShowImageComponent } from '../show-image/show-image.component';
import { ScoresSingleAsTextComponent } from '../scores-single-as-text/scores-single-as-text.component';
import { GamePlayerCardComponent } from '../../../tournament/components/game-player-card/game-player-card.component';
import { NgIf, JsonPipe } from '@angular/common';

export interface GameCommonComponentData {
  game: TournamentEventModel,
  player1: PlayerSimpleModel,
  player2: PlayerSimpleModel
}

@Component({
    selector: 'ptc-game-common',
    templateUrl: './game-common.component.html',
    styleUrls: ['./game-common.component.css'],
    standalone: true,
    imports: [NgIf, GamePlayerCardComponent, ScoresSingleAsTextComponent, ShowImageComponent, JsonPipe]
})
export class GameCommonComponent implements OnInit, OnChanges {

  showDebugData = false;

  @Input()
  dataLoading = false;

  @Input()
  editMode=false;

  @Input()
  scoreData: TennisMatchData;

  @Input()
  winner: PlayerModel;

  @Input()
  data: GameCommonComponentData;

  @ViewChild("scoreDataComponent")
  scoreDataComponent: GameScoreDataComponent;

  isWining = false;
  scoreText = "";

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init(){
    var setsData = SetDataExtensions.toSetsData(this.data.game.scoreData);

    this.isWining = SetDataExtensions.isWining(setsData);
    this.scoreText = SetDataExtensions.getStringValue(setsData);
  }

  ngOnInit(): void {
    this.init();
  }
}
