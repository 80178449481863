import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentLoginData, LoginService } from 'src/app/clt/services/LoginService';
import {FilePathProvider} from '../../../../services';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ClientAvatarComponent } from '../../../shared/components/client-avatar/client-avatar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login-data',
    templateUrl: './login-data.component.html',
    styleUrls: ['./login-data.component.css'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatMenuModule, ClientAvatarComponent, RouterLink, MatIconModule]
})
export class LoginDataComponent implements OnInit, OnDestroy {

  isPlayer = false;
  text: string = "Login";
  name = "Пользователь";
  loginData: CurrentLoginData;
  hasAvatar = false;
  avatarFilePath: string = null;
  unreadMessagesCount = 0;

  constructor(private _loginService: LoginService,
    private _filePathProvider: FilePathProvider) {
    this._loginService.getLoginDataCached().subscribe(data => this.loginDataHandler(data));
  }

  ngOnDestroy(): void {
  }

  loginDataHandler(res: CurrentLoginData){
    this.loginData = res;

    if(!this.loginData.isAuthenticated){
      return;
    }

    this.name = (this.loginData.surname + ' ' + this.loginData.name).trim();

    this.hasAvatar = !!this.loginData.avatarFileId;
    if(this.hasAvatar){
      this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.loginData.avatarFileId);
    }

    this.isPlayer = this.loginData.roles.includes("Ptc.Player");
  }

  logOut(){
    this._loginService.logOut().subscribe(res => {

      if(res.succeeded){
        this._loginService
          .getLoginDataCached()
          .subscribe(data => this.loginDataHandler(data));
      }
    });

  }

  ngOnInit() {
  }
}
