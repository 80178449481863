import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PlayerSimpleModel } from "src/app/game/services/GameService";
import { PlayerModel } from "src/app/player/services/PlayerQueryService";
import { GenericBaseApiResponse } from "src/models/BaseApiResponse";
import {TournamentEventModel, TournamentSimpleModel} from '../../common/modules/tournament-admin/services';

export interface TournamentGameDetailedModel {
    game: TournamentEventModel;
    players: Array<PlayerSimpleModel>;
    tournament: TournamentSimpleModel;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentGameQueryService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + "api/ptc/tournament/game/";
    }

    getById(id: string) {
        return this._httpClient.get<TournamentEventModel>(this._baseControllerUrl + `get/byId/${id}`);
    }

    getByIdDetailed(id: string){
        return this._httpClient.get<TournamentGameDetailedModel>(this._baseControllerUrl + `get/byId/${id}/Detailed`);
    }

    getOpponentData(id:string){
        return this._httpClient.get<GenericBaseApiResponse<PlayerModel>>(this._baseControllerUrl + `${id}/OpponentData`);
    }
}

