import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core'
import { PlayerInfo } from '../t-grid.component'
import {FilePathProvider} from '../../../../common/services';
import { AvatarModule } from 'ngx-avatars';

@Component({
    selector: 't-grid-team',
    templateUrl: './t-grid-team.component.html',
    styleUrls: ['./t-grid-team.component.scss'],
    host: {
        '[class.t-grid-team_lose]': '!playerInfo?.isWinner'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AvatarModule]
})
export class TGridTeamComponent {
  avatarFilePath: string;
  @Input() playerInfo: PlayerInfo

  constructor(private _filePathProvider: FilePathProvider){
  }

  get playerName(): string {
    return this.playerInfo?.player ? (this.playerInfo?.player?.name + ' ' + this.playerInfo?.player?.surname) : '-'
  }

  getPlayerScoreByIndex(index:number): string {
    const scoreValue = this.playerInfo?.scores[index] && this.playerInfo.scores[index] !== '' ? this.playerInfo.scores[index]  : '-'
    return this.playerInfo?.isWinner === undefined ? '' : scoreValue
  }

  ngOnInit(): void {
    if(!this.playerInfo?.player?.avatarFileId){
      return;
    }

    this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.playerInfo.player.avatarFileId);
  }
}
