<form>
    <div class="form-group">
        <label for="album">Выбор города</label>
        <ng-select [(ngModel)]="city"
            (change)="onCityChanged($event)"
            [items]="cities"
            #select
            bindLabel="name"
            (search)="onSearchChanged($event)"
            [loading]="loading"
            [loadingText]="'Загружаю города'"
            [notFoundText]="'Ничего не найдено'"
            dropdownPosition="auto"
            bindValue=""
            [searchFn]="searchFunction"
            labelForId="city"
            placeholder="Выбрать город"
            [virtualScroll]="true"
            name="city">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                    <span class="h6">{{item.name}}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</form>


<div *ngIf="isDebug">
    {{city | json}}
</div>