import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { GenericInterfaceComponent } from 'src/app/generic-interface-components/generic-interface/generic-interface.component';
import {SettingsService} from '../../services';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';


@Component({
    selector: 'app-app-setting',
    templateUrl: './app-setting.component.html',
    standalone: true,
    imports: [MatTabsModule, GenericInterfaceComponent, MatButtonModule]
})
export class AppSettingComponent implements AfterViewInit {
  public Ready = false;

  @ViewChild('editAccountSettingsInterface') private editAccountSettingsInterface: GenericInterfaceComponent;
  @ViewChild('editSmtpEmailSettingsInterface') private editSmtpEmailSettingsInterface: GenericInterfaceComponent;
  constructor(
    private _settingsService: SettingsService,
    private _notifierService: NotifierService
  ) {
  }

  InterfaceReadyHandler(){
    this.Ready = true;
    this._settingsService.GetAccountSettings().subscribe(data => this.editAccountSettingsInterface.customSetValue(data));

    this._settingsService.GetSmtpEmailSettings().subscribe(data=>this.editSmtpEmailSettingsInterface.customSetValue(data));
  }

  SaveDataAccountSettings(){
    var data = this.editAccountSettingsInterface.customGetValue();
    this._settingsService.SaveAccountSettings(data).subscribe(response => NotificaterExtensions.ShowBaseApiResponse(this._notifierService,response));
  }

  SaveDataSmtpEmailSettings(){

  }


  ngAfterViewInit(): void {

  }
}
