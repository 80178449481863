import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CityModel, GetListResult } from '../../common/models';
import { BaseApiResponse } from 'src/models/BaseApiResponse';

export interface PlayerData {
    aboutMe: string;
    sex: boolean;
    dataFilled: boolean;
    name: string;
    surname: string;
    patronymic: string;
    accountConfirmed: boolean;
    phoneNumberConfirmed: boolean;
    emailConfirmed: boolean;
    noEmail: boolean;
    email: string;
    phoneNumber: string;
    birthDate: string | Date;
    createdOn: Date | string;
    cityId: string;
    countryId: string;
    telegramUserId: number;
    telegramUserName: string;
    ntrpRating: string;
    rating: number;
}

export interface UpdateDataByPlayerRequest {
    name: string;
    surname: string;
    patronymic: string;
    aboutMe: string;
    sex: boolean;
}

export interface UpdateCommunicationsDataByPlayerRequest {
    email: string;
    phoneNumber: string;
}

export interface PlayerBalanceData {
    playerId: string;
    balanceRub: number;
    reservedBalanceRub: number;
}

export interface GetUserBalanceTransactions {
    playerId: string;
    count: number | null;
    offSet: number;
}

export interface AccountTransactionModel {
    id: string;
    accountId: string;
    transactionType: string;
    description: string;
    dataJson: string;
    sum: number;
    occuredOnUtc: Date;
}

export interface UpdateNtrpRatingRequest {
    ntrpRating: string;
}

export interface UpdateCountryAndCityDataRequest {
    countryId: string;
    cityId: string;
}

export interface PlayerLocationData {
    id: string;
    country: CountryNameModel;
    city: CityModel;
}


export interface CountryNameModel {
    name: string;
    id: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlayerAccountService {

    baseControlerUrl: string;

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + 'Api/ptc/Player/';
    }

    public getData(): Observable<PlayerData> {
        return this._httpClient.get<PlayerData>(this.baseControlerUrl + 'data/get');
    }

    public getBalanceData(): Observable<PlayerBalanceData> {
        return this._httpClient.get<PlayerBalanceData>(this.baseControlerUrl + 'data/balance/get');
    }

    public getLocationData(): Observable<PlayerLocationData> {
        return this._httpClient.get<PlayerLocationData>(this.baseControlerUrl + 'data/location/get');
    }

    public getTransactions(model: GetUserBalanceTransactions) {
        return this._httpClient.post<GetListResult<AccountTransactionModel>>(this.baseControlerUrl + 'data/balance/transactions', model);
    }

    public getBalanceDataById(id: string): Observable<PlayerBalanceData> {
        return this._httpClient.get<PlayerBalanceData>(this.baseControlerUrl + `data/balance/GetById/${id}`);
    }

    public updateData(model: UpdateDataByPlayerRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + 'data/update', model);
    }

    public updateCityData(model: UpdateCountryAndCityDataRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + 'data/city/update', model);
    }

    public updateNtrpRating(model: UpdateNtrpRatingRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + 'data/Ntrp/Update', model);
    }

    public updateCommunicationsData(model: UpdateCommunicationsDataByPlayerRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + 'data/communications/update', model);
    }

    public confirmEmail(code: string): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `data/Email/Confirm/${code}`, {});
    }
}



