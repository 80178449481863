<mat-card>
    <mat-card-content>
        <my-date-time-picker (onDateChanged)="dateChangedHandler($event)"></my-date-time-picker>

        <div class="d-flex justify-content-center">

            <button mat-raised-button color="primary" (click)="updateDate()">
                Изменить дедлайн игры
            </button>
        </div>

        <div style="height: 20px;"></div>

        <div class="d-flex justify-content-center">

            <button mat-raised-button color="primary" (click)="removeDate()">
                Удалить дедлайн игры
            </button>
        </div>
    </mat-card-content>
</mat-card>