export enum UserInterfaceType {
	CustomInput = <any> 'CustomInput',
	TextBox = <any> 'TextBox',
	NumberBox = <any> 'NumberBox',
	Password = <any> 'Password',
	TextArea = <any> 'TextArea',
	DropDownList = <any> 'DropDownList',
	Hidden = <any> 'Hidden',
	DatePicker = <any> 'DatePicker',
	MultipleDropDownList = <any> 'MultipleDropDownList',
	GenericInterfaceForClass = <any> 'GenericInterfaceForClass',
	GenericInterfaceForArray = <any> 'GenericInterfaceForArray',
	AutoCompleteForSingle = <any> 'AutoCompleteForSingle',
	AutoCompleteForMultiple = <any> 'AutoCompleteForMultiple'
}