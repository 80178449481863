<div *ngIf="options">
    <mat-card>
        <mat-card-content>
            <div *ngIf="options.baseUrl">
                <div>
                    Админ панель на Croco <a [attr.href]="options.baseUrl" target="_blank">перейти</a>
                </div>

                <div>
                    {{options | json}}
                </div>
            </div>

            <div *ngIf="!options.baseUrl">
                <div>
                    Опции Croco
                </div>
                <div>
                    {{options | json}}
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>