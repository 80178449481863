<label class="label-for-autocomplete">{{ interfaceBlock.labelText }}
  <br>
  <ng-select
    [formControl]="valueControl"
    [multiple]="true"
    bindLabel="text"
    [clearable]="false"
    [loading]="loading"
    (close)="close()"
    appendTo="body"
    [compareWith]="compare"
    [items]="autoCompleteOptions"
    (search)="search($event)">
  </ng-select>
</label>