import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/clt/services/LoginService';
import { MatButtonModule } from '@angular/material/button';
import { InstructionsListComponent } from '../../../instructions/components/instructions-list/instructions-list.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'ptc-site-info',
    templateUrl: './site-info.component.html',
    styleUrls: ['./site-info.component.css'],
    standalone: true,
    imports: [MatExpansionModule, InstructionsListComponent, MatButtonModule]
})
export class SiteInfoComponent implements OnInit {

  panelOpenState = false;
  isAuthenticated = false;
  
  constructor(loginService: LoginService) {
    loginService.getLoginDataCached().subscribe(data => {
      this.isAuthenticated = data.isAuthenticated;
      this.panelOpenState = !data.isAuthenticated;
    })
  }

  ngOnInit(): void {
  }
}
