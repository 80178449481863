import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import {BotTokenAndChatIdsModel, PublicTelegramChatForCityModel, SendHtmlMessageToCityChatRequest} from '../models';

@Injectable({
    providedIn: 'root',
})
export class CityChatModifierService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/dlv/cities/';
    }

    getCityChatByIdCached(cityId: string) {
        return this._httpClient.get<PublicTelegramChatForCityModel>(this._baseControllerUrl + `${cityId}/city-chat/cached`);
    }

    getCityChatById(cityId: string) {
        return this._httpClient.get<PublicTelegramChatForCityModel>(this._baseControllerUrl + `${cityId}/city-chat`);
    }

    setCityChatById(cityId: string, model: PublicTelegramChatForCityModel) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `${cityId}/city-chat`, model);
    }

    getBotDataById(cityId: string) {
        return this._httpClient.get<BotTokenAndChatIdsModel>(this._baseControllerUrl + `${cityId}/bot-data`);
    }

    setBotDataById(cityId: string, model: BotTokenAndChatIdsModel) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `${cityId}/bot-data`, model);
    }

    sendMessageToCityChat(model: SendHtmlMessageToCityChatRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `send-html`, model);
    }
}
