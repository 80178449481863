<div>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Создать новую игру
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ptc-create-singles-game (created)="gameCreatedHandler($event)"></ptc-create-singles-game>
    </mat-expansion-panel>
</div>

<div *ngIf="loginData">
    <div style="height: 20px;">
    </div>

    <div *ngFor="let game of games">
        <mat-card>
            <mat-card-content>
                <ptc-single-game-card [showOpponent]="true" [playerId]="loginData.userId" [game]="game">
                </ptc-single-game-card>
            </mat-card-content>
        </mat-card>

        <div style="height: 10px;"></div>
    </div>

    <div style="height: 30px;"></div>
</div>