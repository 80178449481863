import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateValueService, GetAutocompleteDataService, GenericInterfaceService } from './common/services';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu, 'ru');
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { NotifierModule } from 'angular-notifier';
import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './common/modules/page-numbers-select/services';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { LoginDataComponent } from './common/modules/app-nav/components/login-data/login-data.component';
import { NavMenuComponent } from './common/modules/app-nav/components/nav-menu/nav-menu.component';
import { SideNavMenuItemComponent } from './common/modules/app-nav/components/side-nav-menu-item/side-nav-menu-item.component';
import { SideNavMenuComponent } from './common/modules/app-nav/components/side-nav-menu/side-nav-menu.component';
const avatarSourcesOrder = [AvatarSource.CUSTOM, AvatarSource.INITIALS];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    NotifierModule,
    AvatarModule.forRoot({
      sourcePriorityOrder: avatarSourcesOrder,
    }),
    NgxMaskModule.forRoot(),
    FontAwesomeModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    CommonModule,
    NotifierModule,
    MatSidenavModule,
    LoginDataComponent,
    NavMenuComponent,
    SideNavMenuComponent,
    SideNavMenuItemComponent,
    MatNativeDateModule,
  ],
  providers: [
    UntypedFormBuilder,
    CreateValueService,
    GetAutocompleteDataService,
    GenericInterfaceService,
    { provide: MAT_DATE_LOCALE, useValue: 'ru' },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
