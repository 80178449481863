<div class="container padding-top-bottom padding-on-mobile">

  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-4">
      <div class="login-wrap p-0">
        <h3 class="mb-4 text-center">Авторизация</h3>
        <ng-container *ngIf="curentLoginData">
          <ng-container *ngIf="!curentLoginData.isAuthenticated">
            <form
              [formGroup]="loginForm"
              class="example-form"
              (submit)="login()"
            >
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    placeholder="Email или номер телефона"
                    autocomplete="off"
                    new-autocomplete="off"
                    formControlName="emailOrPhoneNumber"
                  />
                </mat-form-field>
              </div>
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    placeholder="Пароль"
                    [attr.type]="showPassword ? 'text' : 'password'"
                    new-autocomplete="off"
                    autocomplete="off"
                    formControlName="password"
                  />
                </mat-form-field>
                <div class="show-password-icon-wraper">
                  <fa-icon
                    (click)="showPasswordToggle()"
                    [icon]="showPassword ? faEyeSlash : faEye"
                  ></fa-icon>
                </div>
              </div>
              <div class="form-group d-md-flex">
                <div class="w-50">
                  <label class="checkbox-wrap checkbox-primary"
                    >Запомнить меня
                    <input type="checkbox" formControlName="rememberMe" />
                    <span class="checkmark"
                      ><fa-icon
                        [icon]="
                          loginForm.get('rememberMe').value
                            ? faCheckSquare
                            : faSquare
                        "
                      ></fa-icon
                    ></span>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-primary submit px-3 btn-submit"
              >
                Войти
              </button>
            </form>
            <p></p>
            <div class="form-group d-md-flex">
              <div class="w-50">
                <a routerLink="/registration" class="white-color"
                  >Зарегистрироваться</a
                >
              </div>
              <div class="w-50 text-md-end">
                <a routerLink="/forgot-password" class="white-color"
                  >Забыли пароль?</a
                >
              </div>
              <div style="height: 250px;">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
