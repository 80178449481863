<div *ngIf="isAdmin">
    <a mat-stroked-button [routerLink]="['/ptc/tournament/create']" href="...">
        Создать новый турнир
    </a>

    <div style="height: 20px;">
    </div>
</div>

<div [hidden]="!shouldConfirm">
    <ptc-player-data-confirm-email (shouldConfirmEmitter)="shouldConfirm = $event">
    </ptc-player-data-confirm-email>
    <div style="height: 10px;"></div>
</div>


<div style="height: 20px;"></div>

<div>
    <mat-card>
        <mat-card-content>
            <common-country-and-city-select #countryCitySelect (valueChanged)="cityChangedHandler()">
            </common-country-and-city-select>
    
            <div style="height: 10px;">
            </div>
            
            <section [hidden]="!isAuthenticated">
                <mat-checkbox [(ngModel)]="filter.showMine" (change)="showMineChanged($event.checked)">
                    <div class="text-wrap">
                        Показывать турниры, в которых я участвую
                    </div>
                </mat-checkbox>
            </section>
        </mat-card-content>
    </mat-card>
    <div style="height: 20px;"></div>
</div>

<div *ngIf="isAdmin && cityId">
    <mat-card>
        <mat-card-content>
            <button (click)="sendMessagesForCity()" mat-raised-button color="primary">
                Разослать сообщения
            </button>
        </mat-card-content>
    </mat-card>
</div>

<ptc-tournaments-list-group [header]="'Текущие турниры'"
    [tournaments]="currentTournaments"
    [isAuthenticated]="isAuthenticated"
    (onChange)="getTournaments()"
    [noTournamentsHeader]="'Сейчас нет текущих турниров'">
</ptc-tournaments-list-group>

<div style="height: 20px;"></div>

<ptc-tournaments-list-group [header]="'Открытые для записи турниры'"
    [tournaments]="openedForParicipantsTournaments"
    [isAuthenticated]="isAuthenticated"
    (onChange)="getTournaments()"
    [noTournamentsHeader]="'Сейчас нет открытых для записи турниров'">
</ptc-tournaments-list-group>

<div style="height: 20px;"></div>

<ptc-tournaments-list-group [header]="'Будущие турниры'"
    [tournaments]="plannedTournaments"
    [isAuthenticated]="isAuthenticated"
    (onChange)="getTournaments()"
    [noTournamentsHeader]="'Сейчас нет запланированных турниров'">
</ptc-tournaments-list-group>

<div style="height: 20px;"></div>

<ptc-tournaments-list-group [header]="'Завершённые турниры'"
    [tournaments]="finishedTournaments"
    [isAuthenticated]="isAuthenticated"
    (onChange)="getTournaments()"
    [noTournamentsHeader]="'Сейчас нет завершённых турниров'">
</ptc-tournaments-list-group>

<div style="height: 20px;"></div>