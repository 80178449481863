<div>
    <div>
        Дата: {{game.playedOnUtc}}
    </div>
    <div>
        Корт: {{game.court}} 
    </div>
    <div>
        Покрытие корта: [{{courtTypeDisplayName}}]
    </div>

    <div *ngIf="opponent">
        Ваш соперник: <a [routerLink]="['/ptc/player', opponent.id]" href="...">{{opponent.surname}} {{opponent.name}}</a>      
    </div>
    
    <div style="height: 10px;"></div>
    
    <t-grid-match (clickMatch)="matchClicked()" [playersInfo]="players"></t-grid-match>

    <div *ngIf="game.imageFileId">
        <div style="height: 20px;"></div>
    
        <div>
            <img class="mx-auto d-block img-fluid" [src]="getSrc(game.imageFileId)"/>
        </div>
    </div>

    <div *ngIf="showActions">
        <div style="height: 20px;"></div>
        <button class="w-100" (click)="delete()" mat-raised-button color="warn">
            Удалить игру
        </button>
    </div>

    <div style="height: 10px;"></div>


</div>