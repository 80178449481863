import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { VisualEditorComponent, CrocoHtmlModule } from 'croco-html';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {TournamentPostModel, TournamentPostService} from '../../../common/modules/tournament-admin/services';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

export interface PostWithFormattedDate{
  post: TournamentPostModel;
  date:string;
}

@Component({
    selector: 'ptc-post-block',
    templateUrl: './post-block.component.html',
    styleUrls: ['./post-block.component.css'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatIconModule, RouterLink, CrocoHtmlModule]
})
export class PostBlockComponent implements OnInit {

  @ViewChild("htmlEditor")
  htmlEditor: VisualEditorComponent;

  isEdit:boolean = false;

  @Input()
  post:PostWithFormattedDate;

  @Input()
  isAdmin:boolean = false;

  @Input()
  userId:string = "";

  @Output()
  postUpdated = new EventEmitter<boolean>();

  @Output()
  postDeleted = new EventEmitter<boolean>();

  constructor(private _postService: TournamentPostService,
    private _notifier: NotifierService) { }

  ngOnInit(): void {
  }

  edit(){
    this.isEdit = true;
  }

  save(){
    this._postService.edit({
      postId: this.post.post.id,
      bodyHtml: this.htmlEditor.html
    }).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.post.post.bodyHtml = this.htmlEditor.html;
        this.postUpdated.emit(true);
        this.isEdit = false;
      }
    });
  }

  remove(){
    this._postService.delete(this.post.post.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.postDeleted.emit(true);
      }
    })
  }

}
