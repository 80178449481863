import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {CountryAndCitySelectComponent} from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import {CreateTournament} from '../../../common/modules/tournament-admin/services';
import { TRestrictionsSelectComponent } from '../../../common/modules/tournament-admin/components/t-restrictions-select/t-restrictions-select.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TournamentTypesSelectComponent } from '../tournament-types-select/tournament-types-select.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'ptc-tournament-form',
    templateUrl: './tournament-form.component.html',
    styleUrls: ['./tournament-form.component.css'],
    standalone: true,
    imports: [MatCheckboxModule, FormsModule, CountryAndCitySelectComponent, TournamentTypesSelectComponent, MatFormFieldModule, MatInputModule, TextFieldModule, TRestrictionsSelectComponent]
})
export class TournamentFormComponent implements OnInit, AfterViewInit, AfterContentChecked {

  @ViewChild("citySelect")
  citySelect: CountryAndCitySelectComponent;

  model: CreateTournament = {
    name: "",
    description: "",
    participationCostRub: 0,
    cityId: null,
    genderRestriction: null,
    type: null,
    isExternal: false
  };

  constructor(private cdr: ChangeDetectorRef) {}

  cityChangedHandler(){
    this.model.cityId = this.citySelect.city?.id ?? null
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }
}
