import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { CityModel } from '../../../common/models';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { CourtTypesSelectComponent } from '../court-types-select/court-types-select.component';
import {CountryNameModel} from '../../../common/services';
import {CountryAndCitySelectComponent} from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MyDateTimePickerComponent, MyDateTimePickerComponentData } from '../../../common/modules/shared/components/my-date-time-picker/my-date-time-picker.component';

export interface GameCourtDataModel{
  court: string;
  courtType: string;
  country: CountryNameModel;
  city: CityModel;
  dateAndTime: MyDateTimePickerComponentData;
  dateIsoString: string;
}

@Component({
    selector: 'ptc-game-court-data',
    templateUrl: './game-court-data.component.html',
    styleUrls: ['./game-court-data.component.css'],
    standalone: true,
    imports: [CountryAndCitySelectComponent, MyDateTimePickerComponent, MatFormFieldModule, MatInputModule, FormsModule, CourtTypesSelectComponent, MatButtonModule]
})
export class GameCourtDataComponent implements OnInit {

  @ViewChild("datePicker")
  datePicker: MyDateTimePickerComponent;


  @ViewChild("courtType")
  courtTypeSelect: CourtTypesSelectComponent;

  @ViewChild("countryCitySelect")
  countryCitySelect: CountryAndCitySelectComponent;

  @Output()
  dataHandler = new EventEmitter<GameCourtDataModel>();

  inProccess = false;

  constructor(private _notifier: NotifierService) {
  }

  model:GameCourtDataModel = {
    court: "",
    courtType: "",
    city: null,
    country: null,
    dateAndTime: null,
    dateIsoString: ""
  }

  btnHandler(){

    if(this.inProccess){
      return;
    }
    this.inProccess = true;

    this.model.court = this.model.court.trim();
    if(this.model.court === ""){
      this.errorHandler("Вы не указали корт");
      this.inProccess = false;
      return;
    }

    this.model.country = this.countryCitySelect.country;

    if(!this.model.country){
      this.errorHandler("Вы не указали страну");
      this.inProccess = false;
      return;
    }

    this.model.city = this.countryCitySelect.city;

    if(!this.model.city){
      this.errorHandler("Вы не указали город");
      this.inProccess = false;
      return;
    }

    this.model.courtType = this.courtTypeSelect.valueStr;
    this.model.dateAndTime = this.datePicker.data;
    this.model.dateIsoString = this.datePicker.dateStr;

    this.dataHandler.emit(this.model);

    this.inProccess = false;
  }

  errorHandler(error: string){
    NotificaterExtensions.ShowError(this._notifier, error);
  }

  ngOnInit(): void {
  }
}
