<label class="label-for-autocomplete">{{ interfaceBlock.labelText }}
        <br>
        <ng-select
                [formControl]="valueControl"
                bindLabel="text"
                [clearable]="false"
                appendTo="body"
                (search)="getAutocompleteOptions($event)"
                [searchFn]="searchFn"
                [loading]="loading"
                [items]="autoCompleteOptions">
        </ng-select>
</label>
