<div [ngSwitch]="interfaceBlock.interfaceType" [hidden]="!interfaceBlock.isVisible">
  <app-generic-interface-textbox-block [formControl]="valueControl" *ngSwitchCase="'TextBox'" [interfaceBlock]="interfaceBlock"></app-generic-interface-textbox-block>
  <app-generic-interface-textbox-block [formControl]="valueControl" *ngSwitchCase="'NumberBox'" [interfaceBlock]="interfaceBlock"></app-generic-interface-textbox-block>
  <app-generic-interface-textbox-block [formControl]="valueControl" *ngSwitchCase="'Hidden'" [interfaceBlock]="interfaceBlock"></app-generic-interface-textbox-block>
  <app-generic-interface-textbox-block [formControl]="valueControl" *ngSwitchCase="'Password'" [interfaceBlock]="interfaceBlock"></app-generic-interface-textbox-block>
  <app-generic-interface-textarea [formControl]="valueControl" *ngSwitchCase="'TextArea'" [interfaceBlock]="interfaceBlock"></app-generic-interface-textarea>
  <app-generic-interface-dropdown-list [formControl]="valueControl" *ngSwitchCase="'DropDownList'" [interfaceBlock]="interfaceBlock"></app-generic-interface-dropdown-list>
  <app-generic-interface-date-picker [formControl]="valueControl" *ngSwitchCase="'DatePicker'" [interfaceBlock]="interfaceBlock"></app-generic-interface-date-picker>
  <app-generic-interface-multiple-dropdown-list [formControl]="valueControl" *ngSwitchCase="'MultipleDropDownList'" [interfaceBlock]="interfaceBlock"></app-generic-interface-multiple-dropdown-list>
  <app-generic-interface-for-array [formControl]="valueControl" *ngSwitchCase="'GenericInterfaceForArray'" [interfaceBlock]="interfaceBlock"></app-generic-interface-for-array>
  <app-generic-interface-for-class [formControl]="valueControl" *ngSwitchCase="'GenericInterfaceForClass'" [interfaceBlock]="interfaceBlock"></app-generic-interface-for-class>
  <!--TODO: write current type for app-generic-interface-autocomplete-->
  <app-generic-interface-autocomplete [formControl]="valueControl" *ngSwitchCase="'AutoCompleteForSingle'" [interfaceBlock]="interfaceBlock"></app-generic-interface-autocomplete>
  <app-generic-interface-autocomplete-multiple [formControl]="valueControl" *ngSwitchCase="'AutoCompleteForMultiple'" [interfaceBlock]="interfaceBlock"></app-generic-interface-autocomplete-multiple>
  <div *ngSwitchDefault>{{interfaceBlock.interfaceType}} Other</div>
</div>