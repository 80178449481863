import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CityModel, SearchCitiesFormData } from '../../models';
import { CityQueryService, CountryNameModel } from '../../../../services';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';

@Component({
  selector: 'common-city-select',
  templateUrl: './city-select.component.html',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    NgIf
  ]
})
export class CitySelectComponent implements OnInit, OnChanges, AfterViewInit {

  searchModel: SearchCitiesFormData = {
    q: ''
  };

  @Input()
  isDebug = false;

  @Input()
  country: CountryNameModel = null;

  @Input()
  searchCities: (data: SearchCitiesFormData) => Observable<CityModel[]>;

  @Output()
  cityChanged = new EventEmitter<CityModel>();

  loading = false;
  city: CityModel = null;

  form: UntypedFormGroup;
  cities: CityModel[] = [];

  setCity(city: CityModel) {
    this.city = city;
    this.loadCities();
    this.cityChanged.emit(this.city);
  }

  constructor(
    private fb: UntypedFormBuilder,
    private readonly _cityService: CityQueryService,
    private cdr: ChangeDetectorRef) {
    this.form = this.fb.group({
      city: '',
    });

    this.searchCities = (data) => {

      if (!this.country) {
        return of<CityModel[]>([]);
      }

      return this._cityService.search({
        q: data.q,
        offSet: 0,
        count: 10,
        countryId: this.country.id
      }).pipe(map(result => {
        return result.list;
      }));
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCities();
  }

  searchFunction(term: string, item: CityModel): boolean {
    return item.name.toLowerCase().includes(term.toLowerCase());
  }


  onCityChanged(city: CityModel) {
    this.city = city;
    this.cityChanged.emit(this.city);
  }

  ngOnInit(): void {
    this.loadCities();
  }

  public loadCities() {
    this.loading = true;
    this.searchCities(this.searchModel).subscribe(data => {
      this.cities = [...data];
      this.loading = false;
    });
  }

  onSearchChanged(q: { term: string, items: object[] }) {
    this.searchModel.q = q.term;
    this.loadCities();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
