<div [hidden]="inProccess || imageFileId">
    <div>
        <common-edit-image #imageEditor 
            [hidden]="true"
            [showCropper]="showCropper"
            [cropperAspets]="cropperAspets"
            (fileSelected)="fileSelectedHandler()"
            (imageHandler)="fileUploadHandler($event)">
        </common-edit-image>
        
        <button *ngIf="showUploadBtn" mat-raised-button class="w-100" (click)="clickBtnHandler()" color="accent">
            Загрузить фотографию
        </button>
    </div>
    <div style="height: 20px;"></div>
</div>

<div *ngIf="inProccess">
    <div class="d-flex justify-content-center align-items-center flex-column" style="min-height: 300px">
        <mat-spinner [diameter]="50" style="display: block;"></mat-spinner>
        
        <div>
            Идёт загрузка изображения
        </div>
    </div>
</div>

<div *ngIf="imageFileId">
    <div>
        <img class="mx-auto d-block img-fluid" [src]="getSrc(imageFileId)" />
    </div>
    <div style="height: 20px;">
    </div>

    <div>
        <button mat-raised-button class="w-100" (click)="imageFileId = null;" color="warn">
            Выбрать другое
        </button>
    </div>
</div>