import { GameCommonComponentData } from 'src/app/game/components/game-common/game-common.component';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';
import { TournamentGameDetailedModel } from '../../services/TournamentGameQueryService';

export interface PlayersModel{
  player1: PlayerSimpleModel; 
  player2: PlayerSimpleModel;
}

export class PlayerExtractionMethods {
  static extractPlayer(game: TournamentGameDetailedModel, index: number) {
    return game.players.length > index
      ? game.players[index]
      : null;
  }

  static extractPlayers(game: TournamentGameDetailedModel): PlayersModel {

    let player1 = PlayerExtractionMethods.extractPlayer(game, 0);
    let player2 = PlayerExtractionMethods.extractPlayer(game, 1);

    return {
      player1,
      player2
    };
  }

  static extractData(game: TournamentGameDetailedModel): GameCommonComponentData {
    let playersData = PlayerExtractionMethods.extractPlayers(game);

    return {
      player1: playersData.player1,
      player2: playersData.player2,
      game: game.game
    };
  }

  static getWinner(response: TournamentGameDetailedModel): PlayerSimpleModel {

    let data = PlayerExtractionMethods.extractData(response);

    let winnerId = response.game.scoreData.winnerPlayerId;

    if (!winnerId) {
      return null;
    }

    return data.player1.id === winnerId
      ? data.player1
      : data.player2;
  }
}
