import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ExternalTournamentDataModel } from './TournamentQueryService';
import { BaseApiResponse } from 'src/models/BaseApiResponse';


@Injectable({
    providedIn: 'root',
})
export class TournamentExternalDataService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/tournament/external-data/';
    }

    update(id: string, model: ExternalTournamentDataModel) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `update/${id}`, model);
    }

    delete(id: string) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `delete/${id}`, {});
    }
}
