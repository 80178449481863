import { EventEmitter } from '@angular/core';
import { Component, Output } from '@angular/core';
import {SettingsService} from '../../../../services';
import { LoginDataComponent } from '../login-data/login-data.component';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule, RouterLink, NgClass, LoginDataComponent]
})
export class NavMenuComponent {

  @Output()
  public sideBarControlClickEmmiter = new EventEmitter<void>();

  appName:string = "";

  isExpanded = false;

  constructor(settingsService: SettingsService){
    settingsService.getAppOptions().subscribe(data => {
      this.appName = data.applicationName;
    })
  }

  collapse() {
    this.isExpanded = false;
  }

  public sideBarControlClick(): void {
    this.sideBarControlClickEmmiter.emit();
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
