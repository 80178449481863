import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseApiResponse } from "src/models/BaseApiResponse";

export interface ChangePlayerPasswordRequest{
    playerId: string;
    password: string;
}

export interface ChangePlayerRatingRequest{
    playerId: string;
    rating: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlayerAdminService {

    baseControlerUrl: string;

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + "Api/ptc/Player/Admin/";
    }

    public confirm(id: string): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `confirm/${id}`, {});
    }

    public login(id: string): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `login/${id}`, {});
    }

    public changePassword(model:ChangePlayerPasswordRequest){
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `edit/password`, model);
    }

    public changeRating(model:ChangePlayerRatingRequest){
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `edit/rating`, model);
    }
}
