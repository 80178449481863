import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CltRoutes } from './clt/routes';
import { PtcGameRoutes } from './game/routes';
import { PtcInstructionRoutes } from './instructions/routes';
import { PtcPlayerRoutes } from './player/routes';
import { TournamentsListComponent } from './tournament/components/tournaments-list/tournaments-list.component';
import { PtcTournamentRoutes } from './tournament/routes';
import { HasLoginGuard } from './common/guards';
import { AppSettingComponent } from './common/components';
import { ExternalAdminPanelComponent } from './common/components/external-admin-panel/external-admin-panel.component';

const routes: Routes = [
  {
    path: '',
    component: TournamentsListComponent,
    pathMatch: 'full'
  },
  {
    path: 'app-settings',
    component: AppSettingComponent,
    canActivate: [HasLoginGuard],
  },
  {
    path: 'mobile-apps',
    loadChildren: () => import('./mobile-apps/mobile-apps.module').then(m => m.MobileAppsModule),
  },
  {
    path: 'croco-admin',
    component: ExternalAdminPanelComponent,
    canActivate: [HasLoginGuard],
  },
  {
    path: 'files',
    loadChildren: () => import('./files/files.module').then(m => m.FilesModule),
    canActivate: [HasLoginGuard],
  },
  {
    path: 'type-description',
    loadChildren: () => import('croco-type-description').then(m => m.TypeDescriptionModule),
    canActivate: [HasLoginGuard],
  },
  {
    path: 'type-description/:typeDisplayFullName',
    loadChildren: () => import('croco-type-description').then(m => m.TypeDescriptionModule),
    canActivate: [HasLoginGuard],
  },
  {
    path: 'js-open-api',
    loadChildren: () => import('angular-js-open-api').then(m => m.AngularJsOpenApiModule),
    canActivate: [HasLoginGuard],
  },
  {
    path: 'modules/ptc/tournament-group-editor',
    loadChildren: () => import('./tournament-group-editor/tournament-group-editor.module').then(m => m.TournamentGroupEditorModule),
    canActivate: [HasLoginGuard],
  },
  {
    path: 'ptc/court-page',
    loadChildren: () => import('./courts/courts.module').then(m => m.CourtsModule),
  },
  {
    path: 'ptc/shop-page',
    loadChildren: () => import('./shops/shops.module').then(m => m.ShopsModule),
  },
  {
    path: 'ptc/communities-page',
    loadChildren: () => import('./communities/communities.module').then(m => m.CommunitiesModule),
  },
  {
    path: 'ptc/trainers-page',
    loadChildren: () => import('./trainers/trainers.module').then(m => m.TrainersModule),
  },
  {
    path: 'dlv-page',
    loadChildren: () => import('./dlv/dlv.module').then(m => m.DlvModule),
  },
  {
    path: 'ptc/game-request-page',
    loadChildren: () => import('./game-request/game-request.module').then(m => m.GameRequestModule),
  },
  ...CltRoutes,
  ...PtcTournamentRoutes,
  ...PtcPlayerRoutes,
  ...PtcGameRoutes,
  ...PtcInstructionRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
