import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/app/clt/services/LoginService';
import { DateExtensions } from 'src/app/extensions/DateExtensions';
import { PostWithFormattedDate, PostBlockComponent } from '../post-block/post-block.component';
import {TournamentPostService} from '../../../common/modules/tournament-admin/services';
import { PtcRoles } from 'src/app/common/roles';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ptc-posts-list',
    templateUrl: './posts-list.component.html',
    styleUrls: ['./posts-list.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, NgFor, PostBlockComponent]
})
export class PostsListComponent implements OnInit {

  @Input()
  tournamentId:string;

  isAdmin:boolean = false;
  userId:string = "";

  posts:PostWithFormattedDate[] = [];

  constructor(private _postService: TournamentPostService,
    private _loginService: LoginService) {

  }

  ngOnInit(): void {
    this._loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.admin);
      this.userId = data.userId;
    });
    this.loadPosts();
  }

  loadPosts(){
    this._postService.get({
      tournamentId: this.tournamentId,
      count: 20,
      offSet:0
    }).subscribe(data => {
      this.posts = data.list.map(x => ({
        post: x,
        date: this.formatDate(x.createdOnUtc),
        isEdit:false
      }));
    });
  }

  postDeletedHandler(){
    this.loadPosts();
  }

  postEditedHandler(){
    this.loadPosts();
  }

  formatDate(dateUtcStr: string | Date):string{
    return DateExtensions.formatDate(dateUtcStr);
  }

}
