import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetListResult } from 'src/app/common/models';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentDeletePlayerRequest, SavePlayersOrderRequest } from './TournamentService';

export interface GetPlayersNotInTournament {
    q: string;
    tournamentId: string;
    count: number | null;
    offSet: number;
}

export interface AddPlayerToTournamentRequest {
    playerId: string;
    tournamentId: string;
}

@Injectable({
    providedIn: 'root',
})
export class TournamentAdminPlayerService {
    private _baseControllerUrl = '';

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + 'api/ptc/tournament-admin/players/';
    }

    delete(model: TournamentDeletePlayerRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `Delete`, model);
    }

    saveOrder(model: SavePlayersOrderRequest): Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `Order`, model);
    }

    getPlayersToAdd(model: GetPlayersNotInTournament) {
        return this._httpClient.post<GetListResult<PlayerModel>>(this._baseControllerUrl + `search/out`, model);
    }

    add(model: AddPlayerToTournamentRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `Add`, model);
    }
}


