import { Component, Input, OnInit } from '@angular/core';
import { TournamentGroupModel } from '../../services/TournamentGroupService';
import {TournamentDetailedModel} from '../../../common/modules/tournament-admin/services';
import { TournamentGroupMatchCardComponent } from '../tournament-group-match-card/tournament-group-match-card.component';
import { MatCardModule } from '@angular/material/card';
import { NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'ptc-tournament-group',
    templateUrl: './tournament-group.component.html',
    styleUrls: ['./tournament-group.component.css'],
    standalone: true,
    imports: [MatTableModule, RouterLink, NgFor, MatCardModule, TournamentGroupMatchCardComponent]
})
export class TournamentGroupComponent implements OnInit {

  displayedColumns: string[] = ['demo-position', 'demo-name', 'demo-weight', 'demo-weight-2'];

  @Input()
  group: TournamentGroupModel;

  @Input()
  tournament: TournamentDetailedModel;

  constructor() { }

  ngOnInit(): void {

  }

}
