<div *ngIf="loginData?.isAuthenticated">
    <button mat-button [matMenuTriggerFor]="beforeMenu">
        <span class="d-flex align-items-center">
            <app-client-avatar 
                [size]="30" class="avatar" 
                [clientAvatarData]="loginData">
            </app-client-avatar>
            {{loginData.email}}
        </span>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
        <a mat-menu-item *ngIf="!isPlayer" [routerLink]="['/client']">
            <mat-icon>manage_accounts</mat-icon>
            <span>Профиль</span>
        </a>
        <a mat-menu-item *ngIf="isPlayer" [routerLink]="['/ptc/my-games']">
            <mat-icon>list</mat-icon>
            <span>Мои игры</span>
        </a>
        <a mat-menu-item *ngIf="isPlayer" [routerLink]="['/ptc/info']">
            <mat-icon>manage_accounts</mat-icon>
            <span>Профиль</span>
        </a>

        <a (click)="logOut()" mat-menu-item color="warn">
            <mat-icon>logout</mat-icon>
            <span>Выйти</span>
        </a>
    </mat-menu>
</div>

<a *ngIf="!loginData?.isAuthenticated" mat-button [routerLink]="['/login']">Логин</a>