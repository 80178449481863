import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateValueService } from '../../common/services';
import { MatButtonModule } from '@angular/material/button';
import { GenericInterfaceBlockSwitcherComponent } from '../generic-interface-block-switcher/generic-interface-block-switcher.component';
import { NgIf, NgFor } from '@angular/common';

export interface ModalOutput {
  value: string;
  indexControl: number;
}

@Component({
    selector: 'app-generic-interface-modal',
    templateUrl: './generic-interface-modal.component.html',
    styleUrls: ['./generic-interface-modal.component.css'],
    standalone: true,
    imports: [MatDialogModule, NgIf, FormsModule, ReactiveFormsModule, NgFor, GenericInterfaceBlockSwitcherComponent, MatButtonModule]
})
export class GenericInterfaceModalComponent implements OnInit {

  valueGroup: UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<GenericInterfaceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private fb: UntypedFormBuilder, private createValueService: CreateValueService) {
    }

  ngOnInit() {
    const controls = {};
    for (const block of this.data.interfaceBlock.innerGenericInterface.blocks) {
      controls[block.propertyName] =
      [this.data.values && this.data.values[block.propertyName]
      ? this.data.values[block.propertyName]
      : this.createValueService.createValueInterface(block)];
    }
    this.valueGroup = this.fb.group(controls);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
