<div>
    <a mat-stroked-button [routerLink]="['/ptc/tournaments']" href="...">
        Все турниры
    </a>

    <div style="height: 10px;">
    </div>
</div>

<div *ngIf="type === 'For8AllPlaces'">
    <mat-card>
        <mat-card-content>
            <h3>Тип турнира: {{displayName}}</h3>

            <div>
                В данном турнире каждый участник играет 3 игры.
            </div>
            <p></p>

            <div>
                Из восьми участников формируется четыре пары четвертьфиналистов. Победители этих пар формируют следующие
                полуфинальные игры.
                Победители полуфиналов отправляются в финал, а проигравшие полуфиналисты, отправляются разыгрывать
                третье
                место.
            </div>
            <p></p>

            <div>
                Так как победители четвертьфиналов в дальнейшем разыгрывают между собой с 1 по 4 места,
                то участники, которые проиграли свои первые игры в первом круге, они отправляются в мини-сетку,
                где разыгрываются места с 5 по 8. Данная сетка аналогична сетке выше.
                Чтобы занять 5-ое место необходимо выиграть следующие две игры. Чтобы занять 6 место нужно выиграть
                первую игру, отправиться в розыгрыш 5-6 места и проиграть эту игру. Проигравшие две игры подряд
                отправляются
                на
                розыгрыш 7-8 места.
            </div>
            <p></p>
        </mat-card-content>

    </mat-card>
</div>

<div *ngIf="type === 'For8WithTwoGroups'">
    <mat-card>
        <mat-card-content>
            <h3>Тип турнира: {{displayName}}</h3>

            <div>
                8 участников разделяются на 2 группы по 4 человека. В каждой группе 4 участника играют друг с другом.
                В "Группе А" и "Группе B" участники играют между собой и набирают очки.
            </div>
            <p></p>

            <div>
                Участник занявший 1 место в "группе А" встретится в полуфинале с участником занявшим
                2 место в "группе B". Аналогично, участник, занявший 2 место в "группе А" встретится в полуфинале с
                участником занявшим 1 место в "группе B".
            </div>
            <p></p>

            <div>
                Победители полуфиналов, разыгрывают 1 место в финале. 3 место в данном турнире не разыгрывается.
            </div>
            <p></p>


        </mat-card-content>
    </mat-card>

    <div style="height: 10px;"></div>

    <mat-accordion>
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Как начисляются очки в группе
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                Очки начисляются по следующим правилам:
                <div>
                    За победу в матче добавляется <b>1000</b> очков. За поражение очки не списываются.
                </div>
                <div>
                    За победу в сете начисляется <b>100</b> очков. За поражение в сете списывается <b>100</b> очков.
                </div>
                <div>
                    Если победа будет одержана в двух сетах, то победителю начислится <b>200</b> очков и столько же
                    спишется с проигравшего.
                </div>
                <div>
                    А если победа будет одержана в трех сетах (2:1), то победителю начислится только <b>100</b> очков и
                    так же эти <b>100</b> очков спишутся с проигравшего.
                </div>
                <div>
                    За победу в гейме начисляется <b>10</b> очков, а за поражение списывается <b>10</b> очков.
                </div>
                <div>
                    Таким образом максимум может быть начислено <b>1320</b> очков. Победа в двух сетах со счётом 6:0
                    6:0.
                </div>
                <div>
                    С проигравшего в данном случае будет списано <b>320</b> очков
                </div>

                <div style="height: 10px;"></div>
                <div>
                    Например. <b>Иван</b> сыграл с <b>Сергеем (6:2; 6:4)</b>.
                </div>
                <div style="height: 10px;"></div>

                <div>
                    В итоге <b>Ивану</b> начислится:
                </div>
                <div>
                    <b>+1000</b> (за победу в матче)
                </div>
                <div>
                    <b>+100</b> (за победу в 1-ом сете)
                </div>
                <div>
                    <b>+100</b> (за победу во 2-ом сете)
                </div>
                <div>
                    <b>+40</b> (6:2 в 1-ом сете)
                </div>
                <div>
                    <b>+20</b> (6:4 во 2-ом сете)
                </div>
                <div style="height: 10px;">

                </div>

                <div>
                    А у <b>Сергеея</b> спишется:
                </div>
                <div>
                    <b>-100</b> (за поражение в 1-ом сете)
                </div>
                <div>
                    <b>-100</b> (за поражение во 2-ом сете)
                </div>
                <div>
                    <b>-40</b> (6:2 в 1-ом сете)
                </div>
                <div>
                    <b>-20</b> (6:4 во 2-ом сете)
                </div>


                <div style="height: 30px;"></div>
                <div>
                    Другой пример. <b>Иван</b> сыграл с <b>Сергеем (6:2; 4:6; 7:6)</b>.
                </div>
                <div style="height: 10px;"></div>
                <div>
                    В итоге <b>Ивану</b> начислится:
                </div>
                <div>
                    <b>+1000</b> (за победу в матче)
                </div>
                <div>
                    <b>+100</b> (за победу в 1-ом сете)
                </div>
                <div>
                    <b>-100</b> (за поражение во 2-ом сете)
                </div>
                <div>
                    <b>+100</b> (за победу в 3-ем сете)
                </div>
                <div>
                    <b>+40</b> (6:2 в 1-ом сете)
                </div>
                <div>
                    <b>-20</b> (4:6 во 2-ом сете)
                </div>
                <div>
                    <b>+10</b> (7:6 в 3-ем сете)
                </div>

                <div style="height: 10px;"></div>

                <div>
                    А у <b>Сергеея</b> спишется:
                </div>
                <div>
                    <b>-100</b> (за поражение в 1-ом сете)
                </div>
                <div>
                    <b>+100</b> (за победу во 2-ом сете)
                </div>
                <div>
                    <b>-100</b> (за поражение в 3-ем сете)
                </div>
                <div>
                    <b>-40</b> (6:2 в 1-ом сете)
                </div>
                <div>
                    <b>+20</b> (4:6 во 2-ом сете)
                </div>
                <div>
                    <b>-10</b> (7:6 в 3-ем сете)
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>


<div *ngIf="type !== 'For8AllPlaces' && type !== 'For8WithTwoGroups'">
    <mat-card>
        <mat-card-content>
            <h3>Тип турнира {{type}} не найден</h3>
        </mat-card-content>
    </mat-card>
</div>