import {
  catchError,
  distinctUntilChanged,
  finalize,
  publishReplay,
  refCount,
  repeatWhen,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  Observable,
  AsyncSubject,
  timer,
  Subscription,
  from,
  fromEvent,
  Subject,
} from 'rxjs';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { NotifierNotificationOptions } from 'angular-notifier/lib/models/notifier-notification.model';
import {NotificationModel} from '../../ecc/services/NotificationService';

@Injectable({
  providedIn: 'root',
})
export class NotificationFromSocketService implements OnDestroy {
  private _notificationsListener: Observable<NotificationModel>;
  private _notificationsConnection: signalR.HubConnection;
  private _ngUnsubscribe = new AsyncSubject<void>();
  private _notificationsSubscribe: Subscription;
  private _notificationsConnected: Observable<void>;
  private showNotificationWithHtml$ = new Subject<NotifierNotificationOptions>();
  constructor(
    @Inject('BASE_URL') private _baseUrl: string
  ) {
    this._notificationsConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this._baseUrl + 'notification')
      .build();
    this._notificationsConnected = from(
      this._notificationsConnection.start()
    ).pipe(
      tap(() => console.log('SignalR Connected!')),
      catchError((err) => {
        console.error('error', err.toString());
        return err;
      }),
      shareReplay<void>(1),
      takeUntil(this._ngUnsubscribe)
    );

    this._notificationsListener = fromEvent<NotificationModel>(
      this._notificationsConnection,
      'ReceivedNotification'
    ).pipe(publishReplay(1), refCount(), takeUntil(this._ngUnsubscribe));

    this.getNotificationsListener()
      .pipe(
        distinctUntilChanged((a, b) => a?.id === b?.id),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe((data) => {
        if (data) {
          this.notificationWithHtmlHandler(data);
        }
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

  public getNotificationsListener(): Observable<NotificationModel> {
    this._notificationsSubscribe = this.getNotifications()
      .pipe(
        repeatWhen(() => timer(5000, 5000)),
        takeUntil(this._ngUnsubscribe)
      )
      .subscribe();
    return this._notificationsListener.pipe(
      finalize(() => this._notificationsSubscribe.unsubscribe())
    );
  }

  public getNotifications(): Observable<void> {
    return this._notificationsConnected.pipe(
      switchMap(() =>
        from(this._notificationsConnection.send('GetClientNotification'))
      )
    );
  }

  public getNotificationsWithHtml(): Observable<NotifierNotificationOptions> {
    return this.showNotificationWithHtml$.asObservable();
  }

  public showNotificationWithHtml(
    notification: NotifierNotificationOptions
  ): void {
    this.showNotificationWithHtml$.next(notification);
  }

  public notificationWithHtmlHandler(data: NotificationModel) {
    const notification = `<b>${data.title}</b><br/>${data.text}`;

    this.showNotificationWithHtml({
      id: data?.id,
      type: data.type.toString().toLowerCase(),
      message: notification,
    });
  }
}
