import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {ExternalTournamentDataModel, TournamentDetailedModel, TournamentExternalDataService} from '../../services';
import { MatButtonModule } from '@angular/material/button';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'ptc-tournament-external-data-editor',
    templateUrl: './external-data-editor.component.html',
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule, TextFieldModule, MatButtonModule]
})
export class ExternalDataEditorComponent implements OnInit {

  @Input()
  tournament: TournamentDetailedModel;

  model: ExternalTournamentDataModel = {
    exists: true,
    link: "",
    description: ""
  };

  constructor(private _service: TournamentExternalDataService,
    private _notifier: NotifierService) {
  }

  getData() {
    this.model = JSON.parse(JSON.stringify(this.tournament.external));
  }

  ngOnInit(): void {
    this.getData();
  }

  updateData(){
    this._service.update(this.tournament.id, this.model).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    })
  }

  deleteData(){
    this._service.delete(this.tournament.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    })
  }

}
