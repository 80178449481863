import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApiResponse } from "src/models/BaseApiResponse";

export interface AddRubsToPlayer {
    playerId: string; 
    sum: number; 
    description: string; 
}

@Injectable({
    providedIn: 'root',
})
export class BalanceAdminService {

    baseControlerUrl: string;

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + "api/ptc/balance-admin/";
    }

    addTransaction(model:AddRubsToPlayer){
        return this._httpClient.post<BaseApiResponse>(this.baseControlerUrl + `add-transaction`, model);
    }
}
