import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { LoginService } from 'src/app/clt/services/LoginService';
import { PlayerModel, PlayerQueryService, SearchPlayersRequest } from '../../services/PlayerQueryService';
import { CountryAndCitySelectComponent } from '../../../common/modules/country-and-city-select/components/country-and-city-select/country-and-city-select.component';
import { Option } from '../../../common/modules/tournament-admin/components';
import { PtcRoles } from 'src/app/common/roles';
import { BooleanSelectListComponentOptions } from 'src/app/common/components/boolean-select-list/boolean-select-list.component';
import { PageNumbersSelectComponent } from '../../../common/modules/page-numbers-select/components/page-numbers-select/page-numbers-select.component';
import { PlayerCardComponent } from '../player-card/player-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BooleanSelectListComponent } from '../../../common/components/boolean-select-list/boolean-select-list.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'ptc-player-search-list',
    templateUrl: './player-search-list.component.html',
    styleUrls: ['./player-search-list.component.css'],
    standalone: true,
    imports: [MatCardModule, CountryAndCitySelectComponent, MatFormFieldModule, MatInputModule, FormsModule, BooleanSelectListComponent, MatCheckboxModule, MatSelectModule, NgFor, MatOptionModule, MatButtonModule, PlayerCardComponent, PageNumbersSelectComponent]
})
export class PlayerSearchListComponent implements OnInit {

  totalCount = 0;
  pageSize = 10;
  shouldConfirm = false;

  accountConfirmedSelectOptions: BooleanSelectListComponentOptions = {
    trueText: "Да",
    falseText: "Нет",
    nullText: "Не важно",
    label: "Поддтвержденный аккаунт"
  };

  @ViewChild('countryCitySelect') createFormCountryCitySelect: CountryAndCitySelectComponent;

  @Output()
  playerSelected = new EventEmitter<PlayerModel>();

  constructor(
    private _playerQueryService: PlayerQueryService,
    private _loginService: LoginService){
  }

  registrationSourceOptions:Option[] = [];
  players:PlayerModel[] = [];

  model = {
    q: "",
    registrationSource: null,
    cityId: null,
    imageFileId: null,
    accountConfirmed: true,
  };

  useRegistrationSource = false;
  isAdmin = false;

  playerSelectedHandler(player: PlayerModel){
    this.playerSelected.emit(player);
  }

  cityChangedHandler() {
    this.model.cityId = this.createFormCountryCitySelect.city?.id;
    this.searchPlayers(0);
  }

  searchPlayers(pageIndex: number){
    let searchModel: SearchPlayersRequest = {
      q: this.model.q,
      accountConfirmed: true,
      sex: null,
      dataFilled: null,
      emailConfirmed: true,
      cityId: this.model.cityId,
      registrationSource: null,
      count: this.pageSize,
      offSet: pageIndex * this.pageSize
    };

    if(this.useRegistrationSource){
      searchModel.registrationSource = this.model.registrationSource;
      searchModel.accountConfirmed = this.model.accountConfirmed;
    }

    this._playerQueryService.search(searchModel).subscribe(data => {
      this.players = data.list;
      this.totalCount = data.totalCount;
    })
  }

  getLoginData(){
    this._loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.admin);

      if(this.isAdmin){
        this.getRegistrationSources();
      }
    })
  }

  getRegistrationSources(){
    this._playerQueryService.getRegistratonSources().subscribe(data => {
      this.registrationSourceOptions = data.map(x => ({value:x, text:x}));
      this.model.registrationSource = data[0];
    });
  }

  ngOnInit(): void {

    this.getLoginData();
    this.searchPlayers(0);
  }

  onPageUpdatedHandler(data: PageEvent){
    this.pageSize = data.pageSize;
    this.searchPlayers(data.pageIndex);
  }
}
