import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface PlayerRegistrationRequest {
    email: string; 
    name: string; 
    surname: string; 
    patronymic: string; 
    phoneNumber: string; 
    password: string; 
    aboutMe: string; 
    cityId: string; 
    countryId: string; 
    ntrpRating: string; 
    sex: boolean; 
    birthDate: Date | string; 
    noCityOrCountryFilled: boolean; 
    cityOrCountry: string; 
    noEmail: boolean; 
    useRegistrationLink: boolean; 
    registrationLinkId: string; 
    registrationSource: string; 
}

export interface PlayerRegistrationResult {
    succeeded: boolean; 
    message: string; 
    playerId: string; 
}

@Injectable({
    providedIn: 'root',
})
export class PlayerRegistrationService {

    baseControlerUrl:string;
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + "Api/ptc/Player/registration/";
    }

    public register(data: PlayerRegistrationRequest): Observable<PlayerRegistrationResult> {
        return this._httpClient.post<PlayerRegistrationResult>(this.baseControlerUrl + 'v1', data);
    }
}
