<mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Инструкция
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
        <div>

            <ptc-instructions-list></ptc-instructions-list>

            <div style="height: 20px;"></div>
            
            <div>
                <button (click)="panelOpenState=false;" mat-raised-button color="primary">Закрыть инструкцию</button>
            </div>
        </div>
    </div>

</mat-expansion-panel>
