import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CurrentLoginData, LoginService } from 'src/app/clt/services/LoginService';
import { GameService, SinglesGameSimpleModel } from '../../services/GameService';
import { GameCardComponent } from '../game-card/game-card.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';
import { CreateSinglesGameComponent } from '../create-singles-game/create-singles-game.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'ptc-games-main',
    templateUrl: './games-main.component.html',
    styleUrls: ['./games-main.component.css'],
    standalone: true,
    imports: [MatExpansionModule, CreateSinglesGameComponent, NgIf, NgFor, MatCardModule, GameCardComponent]
})
export class GamesMainComponent implements OnInit, AfterViewInit {

  games: SinglesGameSimpleModel[] = [];
  loginData: CurrentLoginData;

  constructor(title:Title, 
    private _gameService: GameService,
    private _cdr: ChangeDetectorRef,
    private _loginDataService: LoginService) {
    title.setTitle("Мои игры");

    this._loginDataService.getLoginDataCached().subscribe(data => {
      this.loginData = data;
    })
  }

  gameCreatedHandler(data){
    this.loadGames();
  }

  ngOnInit(): void {
    this.loadGames();
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  loadGames(){
    this._gameService.searchMine({
      count:20,
      offSet:0
    }).subscribe(data => {
      this.games = data.list
    });
  }

}
