<div *ngIf="!player.telegramUserId">
    
    <div *ngIf="!linkResponse">
        <div *ngIf="showTitle">
            У вас нет привязки с аккаунтом в телеграме
        </div>
        <div>
            <button (click)="generateTelegramLink()" mat-raised-button color="primary">
                Создать привязку
            </button>
        </div>
    </div>
    

    <div *ngIf="linkResponse">
        <div>
            Введите данную команду в TelegramBot
        </div>

        <div>
            <b>{{linkResponse.command}}</b>
        </div>

        <div style="height: 10px;">
        </div>

        <div>
            <button (click)="copyLink()" mat-raised-button color="primary">
                Скопировать команду
            </button>
        </div>
        
        <div style="height: 10px;">
        </div>

        <div>
            <a href="https://t.me/tennis_play_bot" target="_blank">Ссылка на телеграм бот</a>
        </div>

        <div>
            После этого обновите страницу.
        </div>
        
    </div>
</div>

<div *ngIf="player.telegramUserId">
    <a [attr.href]="telegramLink" target="_blank">Ссылка на ваш телеграм</a>

    <div>
        Id: {{player.telegramUserId}}
    </div>

    <div>
        UserName: {{player.telegramUserName}}
    </div>
</div>