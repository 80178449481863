import { Component, Input, OnInit } from '@angular/core';
import {FilePathProvider} from '../../../../services';
import { AvatarModule } from 'ngx-avatars';

export interface ClientAvatarData{
  name: string;
  surname: string;
  avatarFileId: number | null;
}

@Component({
    selector: 'app-client-avatar',
    templateUrl: './client-avatar.component.html',
    styleUrls: ['./client-avatar.component.scss'],
    standalone: true,
    imports: [AvatarModule]
})
export class ClientAvatarComponent implements OnInit {

  avatarFilePath: string = null;
  @Input() clientAvatarData: ClientAvatarData;
  @Input() size = 30;
  @Input() class: string;

  constructor(private _filePathProvider: FilePathProvider) { }

  ngOnInit(): void {
    if(!this.clientAvatarData.avatarFileId){
      return;
    }
    this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.clientAvatarData.avatarFileId);
  }
}
