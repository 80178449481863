import { PlayerSimpleModel } from "src/app/game/services/GameService";
import { PlayerInfo } from "../t-grid.component";
import {TennisMatchData, TournamentEventPlayerModel} from '../../../../common/modules/tournament-admin/services';

export interface GameBaseModel {
    players: Array<TournamentEventPlayerModel>;
    scoreData: TennisMatchData;
}

export class MappingExtensions{
    static getPlayersByEvent(event: GameBaseModel, players: PlayerSimpleModel[]): PlayerInfo[] {
        return event.players.map((player, index) => ({
            player: players.find(({ id }) => player.userId === id),
            scores: event.scoreData.sets.map((set) => index ? set.score2 : set.score1),
            isWinner: event.scoreData.winnerPlayerId ? player.userId === event.scoreData.winnerPlayerId : undefined
        }))
    }
}
