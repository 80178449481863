<common-country-and-city-select #countryCitySelect>
</common-country-and-city-select>

<div style="height: 15px;"></div>

<my-date-time-picker #datePicker [dateLabel]="'Дата игры'" [timeLabel]="'Время начала игры'"
    [showLocalTime]="false">
</my-date-time-picker>

<div>
    <mat-form-field class="w-100">
        <input matInput placeholder="Корт" new-autocomplete="off" autocomplete="off" [(ngModel)]="model.court">
    </mat-form-field>
</div>

<ptc-court-types-select #courtType></ptc-court-types-select>

<button class="w-100" (click)="btnHandler()" mat-raised-button color="primary">
    Далее
</button>