<div>
    <div style="height: 10px;"></div>

    <button class="w-100" mat-raised-button color="primary" (click)="sendPlanningMessage()">
        Призвать на турнир
    </button>

    <div style="height: 10px;"></div>

    <button class="w-100" mat-raised-button color="primary" (click)="sendLongGamesMessages()">
        Длинные игры
    </button>
</div>