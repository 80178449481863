<div class="container padding-top-bottom padding-on-mobile">
  <div class="row justify-content-center">
    <div class="col-md-6 text-center mb-5">
      <h2 class="heading-section">Забыли пароль?</h2>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-4">
      <div class="login-wrap p-0">
            <form *ngIf="!submited; else formSubmited" [formGroup]="forgotPasswordForm" class="example-form" (submit)="sendRequest()">
              <div class="position-relative mb-3">
                <mat-form-field class="w-100">
                  <input
                    matInput
                    placeholder="Почта"
                    autocomplete="off"
                    new-autocomplete="off"
                    formControlName="email"
                  />
                </mat-form-field>
              </div>

              <button
                type="submit"
                class="btn btn-primary submit px-3 btn-submit"
                [disabled]="loading"
              >
                Создать заявку на смену пароля
              </button>
            </form>

            <div class="form-group mt-3 d-md-flex">
              <div class="w-50 white-color">
                <a routerLink="/login" class="white-color"
                  >Войти</a
                >
              </div>
            </div>

            <ng-template #formSubmited>
              На ваш почтовый ящик <b>{{ this.forgotPasswordForm.get('email').value }}</b> отправлена ссылка для восстановления пароля
            </ng-template>
            <div style="height: 290px;"></div>
      </div>
    </div>
  </div>
</div>
