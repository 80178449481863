import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoginService } from 'src/app/clt/services/LoginService';
import { DateExtensions } from 'src/app/extensions/DateExtensions';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { GameCommonComponent, GameCommonComponentData } from 'src/app/game/components/game-common/game-common.component';
import { SetDataExtensions } from 'src/app/game/components/scores-main/SetDataExtensions';
import { SetFullGameDataController, SetGameModelData } from 'src/app/game/components/set-full-game-data/set-full-game-data.component';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { TournamentGameQueryService, TournamentGameDetailedModel } from '../../services/TournamentGameQueryService';
import { TournamentGameService } from '../../services/TournamentGameService';
import { PlayerExtractionMethods } from './PlayerExtractionMethods';
import {TournamentEventModel, TournamentSimpleModel} from '../../../common/modules/tournament-admin/services';
import { PtcRoles } from 'src/app/common/roles';
import { TournamentGameAdminCardComponent } from '../tournament-game-admin-card/tournament-game-admin-card.component';
import { SetFullGameDataComponent } from '../../../game/components/set-full-game-data/set-full-game-data.component';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
    selector: 'app-tournament-game-screen',
    templateUrl: './tournament-game-screen.component.html',
    styleUrls: ['./tournament-game-screen.component.css'],
    standalone: true,
    imports: [
      MatCardModule, 
      NgIf, 
      MatButtonModule, 
      RouterLink, 
      GameCommonComponent,
      SetFullGameDataComponent, 
      TournamentGameAdminCardComponent,
      MatDatepickerModule
    ]
})
export class TournamentGameScreenComponent implements OnInit {

  @Input()
  gameId: string;

  dateRestrictionLocalStr: string = "";

  userId: string;

  gameResponse:TournamentGameDetailedModel;

  dataLoading = false;
  editMode = false;

  isAdmin = false;

  game: TournamentEventModel;
  winner: PlayerSimpleModel;
  public data: GameCommonComponentData;
  tournament: TournamentSimpleModel;

  setGameController = new SetFullGameDataController();

  opponentData: PlayerModel;

  @ViewChild("gameData")
  gameCommonComponent: GameCommonComponent;

  gamesUpdated = new EventEmitter<boolean>();

  isAuthenticated = false;
  isLoginLoaded = false;

  constructor(
    private _route: ActivatedRoute,
    private _gameService: TournamentGameService,
    private _gameQueryService: TournamentGameQueryService,
    private _notifier: NotifierService,
    loginService: LoginService,
    private _title: Title) {
    loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.admin);
      this.isAuthenticated = data.isAuthenticated;
      this.userId = data.userId;
      this.isLoginLoaded = true;
    })
  }

  hasPlayer():boolean{
    return this.game.players.findIndex(x => x.userId === this.userId) >= 0
  }

  showOpponentData(){
    this._gameQueryService.getOpponentData(this.gameId).subscribe(data => {

      if(data.isSucceeded){
        this.opponentData = data.responseObject;
        return;
      }
    })
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.gameId = params['id'];
      this.loadGame();
    });
  }

  startEdit(){
    this.editMode = true;
  }

  removeScoreConfirmation() {
    this._gameService.removeScoreConfirmation(this.game.id).subscribe(data => {
      this.handleAndReloadData(data);

      if(data.isSucceeded){
        this.gamesUpdated.emit(true);
      }
    })
  }

  confirmScore() {
    this._gameService.confirmScore(this.game.id).subscribe(data => {
      this.handleAndReloadData(data);

      if(data.isSucceeded){
        this.gamesUpdated.emit(true);
      }
    })
  }

  gameUpdatedHandler(data:SetGameModelData){

    this._gameService.updateScore({
      gameId: this.data.game.id,
      imageFileId: data.imageFileId,
      gameData: SetDataExtensions.toTennisMatchData(data.scoreData, this.game.players[0].userId, this.game.players[1].userId),
    }).subscribe(data => {
      this.handleAndReloadData(data);
    });
  }

  saveScore(data: TournamentEventModel) {

    this.editMode = false;

    this._gameService.updateScore({
      gameId: data.id,
      imageFileId: data.imageFileId,
      gameData: data.scoreData
    }).subscribe(data => {
      this.handleAndReloadData(data);
    });
  }

  handleAndReloadData(data: BaseApiResponse) {
    NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

    if (!data.isSucceeded) {
      return;
    }

    this.loadGame();
  }

  loadGame() {
    this.dataLoading = true;

    this._gameQueryService.getByIdDetailed(this.gameId).subscribe(resp => {
      this.handleGameModel(resp);
    });
  }

  handleGameModel(response: TournamentGameDetailedModel){

    this.gameResponse = response;
    this.dateRestrictionLocalStr = DateExtensions
      .formatDate(response.game.dateRestrictionUtc)

    this.tournament = response.tournament;
    this._title.setTitle(`Игра в турнире ${this.tournament.name}`);

    this.data = PlayerExtractionMethods.extractData(response);
    this.game = response.game;

    this.dataLoading = false;
    this.editMode = false;

    this.winner = PlayerExtractionMethods.getWinner(response);

    this.showOpponentData();

    this.setGameController.setScore(response.game.scoreData);
    this.setGameController.model.imageFileId = response.game.imageFileId;
  }

  dateChangedHandler(dateStr:string){

  }
}
