import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { TournamentFormComponent } from '../tournament-form/tournament-form.component';
import {TournamentCreationResult, TournamentService} from '../../../common/modules/tournament-admin/services';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-create-tournament',
    templateUrl: './create-tournament.component.html',
    styleUrls: ['./create-tournament.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, TournamentFormComponent, MatButtonModule]
})
export class CreateTournamentComponent implements OnInit {

  isReady = false;

  @ViewChild("form")
  form: TournamentFormComponent;

  creationResult: TournamentCreationResult;

  constructor(title: Title,
    private _tournamentService: TournamentService,
    private _notifier: NotifierService,
    private _router: Router) {
    title.setTitle("Создать турнир");
  }

  create(){
    this._tournamentService
      .create(this.form.model)
      .subscribe(data => {
        
        if(data.succeeded){
          NotificaterExtensions.ShowSuccess(this._notifier, "Турнир создан успешно");
          this.creationResult = data;
        }
        else{
          NotificaterExtensions.ShowError(this._notifier, data.errorMessage);
        }
      })
  }

  interfaceReadyHandler(){
    this.isReady = true;
  }

  goToTournament(){
    this._router.navigate([`/ptc/tournament`, this.creationResult.tournamentId]);
  }

  ngOnInit(): void {
  }

}
