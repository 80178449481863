<mat-tab-group>
  <mat-tab label="Настройки аккаунта">
    <p></p>
    <app-generic-interface #editAccountSettingsInterface (interfaceReady)="InterfaceReadyHandler()" [typeDisplayFullName]="'Clt.Contract.Settings.AccountSettingsModel'" ></app-generic-interface>
    <button
        mat-raised-button
        color="primary"
        (click)="SaveDataAccountSettings()"
      >
        Сохранить изменения
      </button>
  </mat-tab>
  <mat-tab label="Настройки почты"> 
    <p></p>
    <app-generic-interface #editSmtpEmailSettingsInterface (interfaceReady)="InterfaceReadyHandler()" [typeDisplayFullName]="'Ecc.Contract.Settings.SmtpEmailSettingsModel'" ></app-generic-interface>
    <button
        mat-raised-button
        color="primary"
        (click)="SaveDataSmtpEmailSettings()"
      >
        Сохранить изменения
      </button>
  </mat-tab>
  <mat-tab label="Настройка 3"> Настройка 3 </mat-tab>
</mat-tab-group>
