<mat-card>
    <mat-card-content>
        <div>
            «новичок» - до 2.5
        </div>
        <div>
            «любитель» - от 2.6 до 3.5
        </div>
        <div>
            «продвинутый любитель» - от 3.6 до 4.5.
        </div>
        <div>
            «полу-профи» - от 4.6 до 5.5.
        </div>
        <div>
            «профик» - от 5.6 до 7.0
        </div>
        
        <div style="height: 20px;"></div>
        
        <div>
            Основные характеристики уровня игры в теннис по версии NTRP
        </div>
        
        <div style="margin-top: 5px;">
            <b>1.0</b> Этот игрок только начинает играть в теннис
        </div>
        
        <div style="margin-top: 5px;">
            <b>1.5</b> Этот игрок имеет начальные навыки игры, и работает в основном над тем, чтобы удержать мяч в пределах корта
        </div>
        
        <div style="margin-top: 5px;">
            <b>2.0</b> Этому игроку не хватает игрового опыта, у него очевидные недостатки во всех ударах, но он знаком с основными положениями одиночной и парной игр
        </div>
        
        <div style="margin-top: 5px;">
            <b>2.5</b> Этот игрок учится предугадывать направление мяча, хотя чувство корта развито слабо. Может недолго удерживать мяч в пределах корта в невысоком темпе с игроками подобного уровня
        </div>
        
        <div style="margin-top: 5px;">
            <b>3.0</b> Этот игрок довольно успешно отбивает мячи средней сложности, но чувствует себя неуверенно при выполнении ударов, пытаясь контролировать их направление, глубину или силу
        </div>
        
        <div style="margin-top: 5px;">
            <b>3.5</b> Этот игрок добился хорошего контроля за направлением мячей умеренной сложности, но ему еще недостает чувства корта и разнообразия. Проявляет более агрессивную игру у сетки и работает над улучшением командной парной игры
        </div>
        
        <div style="margin-top: 5px;">
            <b>4.0</b> Этот игрок хорошо контролирует направление и глубину ударов средней сложности как открытой, так и закрытой ракеткой. Довольно успешно использует свечи, перебросы, игру в ноги. Иногда случаются двойные ошибки при подаче. Длительные розыгрыши обычно проигрывает, благодаря нетерпеливости. Командная работа в парах очевидна
        </div>
        
        <div style="margin-top: 5px;">
            <b>4.5</b> Этот игрок уже использует силу удара и подкрутки, начинает контролировать темп игры, имеет хорошую работу ног, и начинает менять игровую тактику в зависимости от соперника. Имеет сильную первую подачу и стабильную вторую. Пытается контратаковать на сложных ударах. Агрессивная игра у сетки в парах
        </div>
        
        <div style="margin-top: 5px;">
            <b>5.0</b> Этот игрок имеет хорошее чувство мяча, и зачастую выдающийся удар или свойство, позволяющее выстраивать структуру игры. Регулярно делает выигрышные удары или заставляет соперника совершать ошибки, уверенно держит мяч при игре на задней линии, успешно выполняет свечи, навесы, укороченные удары и оверхеды. Хорошо контролирует глубину и подкрутку второй подачи.
        </div>
        
        <div style="margin-top: 5px;">
            <b>5.5</b> Этот игрок вполне развил силу ударов и/или постоянство, чтобы использовать их в качестве основного оружия. Может варьировать стратегию и стиль игры в зависимости от обстоятельств, и возвращает сложные удары в непростых ситуациях.
        </div>
        
        <div style="margin-top: 5px;">
            <b>6.0 - 7.0</b> Эти игроки обычно не нуждаются в рейтинге NTRP. Игрок уровня 6.0, как правило, усиленно тренировался для участия в национальных турнирах, и приобрел национальный рейтинг. Игрок уровня 6.5 имеет все шансы достичь уровня 7.0 и имеет опыт участия в турнирах-сателлитах. Уровень 7.0 - игрок мирового класса, участвующий в турнирах международного уровня и зарабатывающий участием в турнирах.
        </div>    
    </mat-card-content>
</mat-card>