<h1 mat-dialog-title>{{data.interfaceBlock.labelText}}</h1>
<div mat-dialog-content>
    <div *ngIf="valueGroup" [formGroup]="valueGroup">
      <div  *ngFor="let element of data?.interfaceBlock?.innerGenericInterface?.blocks">
        <app-generic-interface-block-switcher formControlName="{{element.propertyName}}" [interfaceBlock]="element"></app-generic-interface-block-switcher>
      </div>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="{value: valueGroup?.value, indexControl: data?.indexControl}" cdkFocusInitial>Ok</button>
</div>