import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMenu } from '../models';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private baseControllerUrl: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.baseControllerUrl = baseUrl + "api/menu/";
    }


    public getMenu(name: string): Observable<IMenu> {
        return this.http.get<IMenu>(this.baseControllerUrl + name);
    }
}
