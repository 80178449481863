import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import {AccountSettingsModel, AppOptions, SmtpEmailSettingsModel} from '../models';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private baseControllerUrl: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.baseControllerUrl = baseUrl + 'api/settings/';
    }

    public GetAccountSettings(): Observable<AccountSettingsModel> {
      return this.http.get<AccountSettingsModel>(this.baseControllerUrl + 'accountSettings/get');
    }

    public SaveAccountSettings(settings: AccountSettingsModel): Observable<BaseApiResponse> {
        return this.http.post<BaseApiResponse>(this.baseControllerUrl + 'accountSettings/update', settings);
    }

    public GetSmtpEmailSettings(): Observable<SmtpEmailSettingsModel> {
        return this.http.get<SmtpEmailSettingsModel>(this.baseControllerUrl + 'smtpEmailSettings/get');
    }

    public SaveSmtpEmailSettings(settings: SmtpEmailSettingsModel): Observable<BaseApiResponse> {
        return this.http.post<BaseApiResponse>(this.baseControllerUrl + 'smtpEmailSettings/update', settings);
    }

    public getAppOptions(): Observable<AppOptions> {
      return this.http.get<AppOptions>(this.baseControllerUrl + 'app-options/get');
    }
}
