import { Component, OnInit } from '@angular/core';
import { AdminAppOptionsService, CrocoAdminAppOptions } from '../../services/AdminAppOptionsService';
import { MatCardModule } from '@angular/material/card';
import { NgIf, JsonPipe } from '@angular/common';

@Component({
    selector: 'app-external-admin-panel',
    templateUrl: './external-admin-panel.component.html',
    styleUrls: ['./external-admin-panel.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, JsonPipe]
})
export class ExternalAdminPanelComponent implements OnInit {

  options: CrocoAdminAppOptions;

  constructor(private _service: AdminAppOptionsService) { }

  ngOnInit(): void {
    this._service.get().subscribe(data => {
      this.options = data;
    })
  }

}
