import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import {
  CurrentLoginData,
  LoginService,
} from 'src/app/clt/services/LoginService';
import { faCheckSquare, faEye, faEyeSlash, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, FontAwesomeModule, RouterLink]
})
export class LoginComponent implements OnInit  {
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faCheckSquare = faCheckSquare;
  public faSquare = faSquare;
  public showPassword = false;
  public curentLoginData: CurrentLoginData;
  
  public loginForm = new UntypedFormGroup({
    emailOrPhoneNumber: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    rememberMe: new UntypedFormControl(false)
  });

  constructor(
    private _loginService: LoginService,
    private _notifier: NotifierService,
    private router: Router,
    private _titleService: Title
  ) {
    this._loginService.getLoginDataCached().subscribe(res => {
      this.curentLoginData = res;

      if(this.curentLoginData.isAuthenticated){
        this.router.navigate(['']);
      }
    });

    this._titleService.setTitle("Логин");
  }

  ngOnInit() {
  }

  public login() {
    this._loginService.loginByEmailOrPhoneNumber(this.loginForm.value)
    .subscribe((res) => {
      
      if(res.succeeded){
        NotificaterExtensions.ShowSuccess(this._notifier, "Вы успешно авторизованы");
      }
      else{
        NotificaterExtensions.ShowError(this._notifier, res.errorMessage);
      }

      if (res.succeeded) {
        this.router.navigate(['']);
      }
    });
  }

  public showPasswordToggle():void {
    this.showPassword = !this.showPassword
  }
}
