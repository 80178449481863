import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseApiResponse, GenericBaseApiResponse } from "src/models/BaseApiResponse";

export interface ClientModel {
  id: string; 
  name: string; 
  birthDate: Date | null; 
  registrationDate: Date; 
  surname: string; 
  patronymic: string; 
  sex: boolean | null; 
  phoneNumber: string; 
  email: string; 
  avatarFileId: number | null; 
}

export interface EditClient {
  name: string; 
  birthDate: Date | null; 
  surname: string; 
  patronymic: string; 
  sex: boolean | null; 
  phoneNumber: string; 
}

@Injectable({providedIn: 'root'})
export class ClientService {
    
    _baseClientUrl: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
      this._baseClientUrl = baseUrl + 'api/client/';
    }

    public GetClientData(): Observable<GenericBaseApiResponse<ClientModel>>
    {
      return this.http.get<GenericBaseApiResponse<ClientModel>>(this._baseClientUrl + 'getdata'
      );
    }

    public EditClientData(model: EditClient): Observable<BaseApiResponse>
    {
      return this.http.post<BaseApiResponse>(this._baseClientUrl + 'edit', model);
    }

    public UpdatePhoto(fileId: number): Observable<BaseApiResponse>
    {
      return this.http.post<BaseApiResponse>(this._baseClientUrl + `Avatar/Update?fileId=${fileId}`, {});
    }

    public DeletePhoto(): Observable<BaseApiResponse>
    {
      return this.http.post<BaseApiResponse>(this._baseClientUrl + `Avatar/Delete`, {});
    }
}