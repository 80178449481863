<ngx-avatars [round]="false"
     [src]="avatarFilePath"
     size="45"
     bgColor="#e5e5e5"
     [name]="playerInfo?.player ? (playerInfo?.player?.name + ' ' + playerInfo?.player?.surname) : '-'"></ngx-avatars>

<div class="t-grid-team__name">
    {{playerInfo?.player?.surname}} {{playerInfo?.player?.name}}
</div>

<div class="t-grid-team__score">
    <span class="t-grid-team__score-cell"> {{ getPlayerScoreByIndex(0) }} </span>
    <span class="t-grid-team__score-cell"> {{ getPlayerScoreByIndex(1) }} </span>
    <span class="t-grid-team__score-cell"> {{ getPlayerScoreByIndex(2) }} </span>
</div>
