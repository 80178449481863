import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '../../../common/components';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UserForgotPasswordService } from '../../services/UserForgotPasswordService';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-create-new-password',
    templateUrl: './create-new-password.component.html',
    styleUrls: ['./create-new-password.component.scss'],
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FontAwesomeModule]
})
export class CreateNewPasswordComponent
  extends DestroyableComponent
  implements OnInit {
  public newPassword = new UntypedFormControl('');
  public loading = false;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public showPassword = false;
  private userId = this.activatedRoute.snapshot.params.userId;
  private token = this.activatedRoute.snapshot.params.token;

  constructor(
    private userForgotPasswordService: UserForgotPasswordService,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {}

  public sendRequest() {
    if (!this.newPassword.valid) {
      return;
    }
    this.loading = true;

    this.userForgotPasswordService
      .ChangePasswordByToken({
        newPassword: this.newPassword.value,
        token: this.token,
        userId: this.userId,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.loading = false;
        if (result.isSucceeded) {
          this.router.navigate(['/login']);
        } else {
          this._snackBar.open(result.message, 'Закрыть', { duration: 1500 });
        }
      });
  }

  public showPasswordToggle ():void {
    this.showPassword = !this.showPassword
  }
}
