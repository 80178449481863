import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BaseApiResponseWithFilesIds } from 'croco-html/lib/services/file-upload.service';
import { CropperAspects, EditImageComponent } from 'src/app/common/components/edit-image/edit-image.component';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {FilePathProvider, FileUploadService} from '../../../common/services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-game-image',
    templateUrl: './game-image.component.html',
    styleUrls: ['./game-image.component.css'],
    standalone: true,
    imports: [EditImageComponent, NgIf, MatButtonModule, MatProgressSpinnerModule]
})
export class GameImageComponent implements OnInit {

  @ViewChild("imageEditor")
  imageEditor: EditImageComponent;

  @Input()
  imageFileId: number = null;

  showUploadBtn = true;

  cropperAspets:CropperAspects = {
    maintainAspectRatio: false,
    aspectRatio: 1 / 1
  };

  showCropper = false;

  inProccess = false;

  @Output()
  imageUpdated = new EventEmitter<number>();

  @Output()
  fileSelected = new EventEmitter<boolean>();

  constructor(private _notifier: NotifierService,
    private _fileService: FileUploadService,
  private readonly _filePathProvider: FilePathProvider) { }

  fileUploadHandler(file: File){
    this._fileService.postFile(file, true).subscribe(data => {
      this.inProccess = true;
      this.onFilesUploadedHandler(data);
    })
  }

  fileSelectedHandler(){
    this.showUploadBtn = false;
    this.fileSelected.emit(true);
  }

  onFilesUploadedHandler(data: BaseApiResponseWithFilesIds){

    this.inProccess = false;

    if(!data.isSucceeded){
      NotificaterExtensions.ShowError(this._notifier, data.message);
      return;
    }

    this.imageFileId = data.responseObject[0];
    this.imageUpdated.emit(this.imageFileId);
  }

  clickBtnHandler(){
    this.imageEditor.openImageEditor();
  }

  getSrc(fileId: number){
    return this._filePathProvider.getMediumImageFilePath(fileId);
  }

  ngOnInit(): void {
  }

}
