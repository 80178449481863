<div *ngIf="!finished">
    <common-edit-image 
        [fileName]="fileName"
        (imageHandler)="uploadAvatar($event)">
    </common-edit-image>

</div>


<div *ngIf="finished">
    Вы только что изменили вашу аватарку
</div>