<div>

    <div>
        <mat-checkbox [(ngModel)]="model.isExternal">Внешний турнир</mat-checkbox>
    </div>
    <div style="height: 10px;"></div>

    <common-country-and-city-select #citySelect (valueChanged)="cityChangedHandler()"></common-country-and-city-select>

    <div [hidden]="model.isExternal">
        <div style="height: 10px;"></div>
        <common-tournament-types-select (typeChanged)="model.type = $event">
        </common-tournament-types-select>
    </div>
    
    <div style="height: 10px;"></div>
    <div>
        <div>
            <mat-form-field class="w-100">
                <input matInput placeholder="Название" 
                    new-autocomplete="off" autocomplete="off"
                    [(ngModel)]="model.name">
            </mat-form-field>
    
            <mat-form-field class="w-100">
                <textarea cdkTextareaAutosize matInput placeholder="Описание" 
                    new-autocomplete="off" autocomplete="off" 
                    [(ngModel)]="model.description"></textarea>
            </mat-form-field>

            <t-restrictions-select (onRestrictionChanged)="model.genderRestriction = $event"></t-restrictions-select>

            

            <div style="height: 20px;"></div>

            <div [hidden]="model.isExternal">
                <mat-form-field class="w-100">
                    <mat-label>Стоимость участия</mat-label>
                    <input type="number" matInput
                        [(ngModel)]="model.participationCostRub">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>