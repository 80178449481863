import { Component, Input, OnInit } from '@angular/core';
import { GameService, SinglesGameSimpleModel } from 'src/app/game/services/GameService';
import { GameCardComponent } from '../../../game/components/game-card/game-card.component';
import { MatCardModule } from '@angular/material/card';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ptc-player-games',
    templateUrl: './player-games.component.html',
    styleUrls: ['./player-games.component.css'],
    standalone: true,
    imports: [NgFor, MatCardModule, GameCardComponent]
})
export class PlayerGamesComponent implements OnInit {

  games: SinglesGameSimpleModel[] = [];

  @Input()
  playerId: string;

  @Input()
  isAdmin:boolean = false;

  constructor(
    private _gameService: GameService) { }

  getGames(){
    this._gameService.search({
      count: 20,
      offSet: 0,
      q: null,
      playerId: this.playerId,
      opponentPlayerId: null
    }).subscribe(data => {
      this.games = data.list;
    })
  }

  ngOnInit(): void {
    this.getGames();
  }

}
