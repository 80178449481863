<div>
    <div class="form-group">
        <label for="album">Выбор страны</label>
        <ng-select [(ngModel)]="country"
                (change)="onCountryChanged($event)"
                [items]="countries"
                #select
                bindLabel="name"
                (search)="onSearchChanged($event)"
                [loading]="loading"
                [loadingText]="'Загружаю страны'"
                [notFoundText]="'Ничего не найдено'"
                [searchFn]="searchFunction"
                dropdownPosition="auto"
                bindValue=""
                labelForId="country"
                placeholder="Выбрать страну"
                [virtualScroll]="true">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                    <span class="h6">{{item.name}}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</div>

<div *ngIf="isDebug">
    {{country | json}}
</div>