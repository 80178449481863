import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class RegistrationLinkService {
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string) {
    }

    public exists(id: string): Observable<boolean> {
        return this._httpClient.get<boolean>(this.baseUrl + `api/ptc/player-registration-link/exists/${id}`);
    }
}