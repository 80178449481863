<div *ngIf="showDebugInfo">
    <div>Дебаг информация ptc-set-score-data-common</div>
    
    <div>
        {{controller | json}}
    </div>
</div>

<div class="text-center">
    <span class="h4">Укажите счёт</span> 
</div>

<div style="height: 20px;"></div>

<game-scores-main #scores [controller]="controller"></game-scores-main>

<div style="height: 50px;"></div>

<button class="w-100" (click)="setScore()" mat-raised-button color="primary">
    Указать счёт
</button>