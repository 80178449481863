<div>
    <span class="h5">{{header}}:</span>
    <div style="height: 10px;"></div>
    
    <div *ngIf="!tournaments">
        <mat-spinner style="margin:0 auto;">
        </mat-spinner>
    </div>

    <div *ngIf="tournaments && tournaments.length > 0">
        <ptc-tournament-card 
            *ngFor="let tournament of tournaments" 
            [tournament]="tournament"
            [isAuthenticated]="isAuthenticated"
            (onChange)="getTournaments()">
        </ptc-tournament-card>
    </div>

    <div *ngIf="tournaments && tournaments.length === 0">
        <mat-card>
            <mat-card-content>
                {{noTournamentsHeader}}
            </mat-card-content>
        </mat-card>
    </div>
</div>