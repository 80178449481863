import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface Option {
  value: string;
  text: string
}

export interface BooleanSelectListComponentOptions{
  label: string;
  nullText: string;
  trueText: string;
  falseText: string;
}

class OptionNames {
  static readonly null = "NULL";
  static readonly true = "true";
  static readonly false = "false";
}

class OptionProvider{

  static getOptions(model: BooleanSelectListComponentOptions){
    return [
      {
        value: OptionNames.null,
        text: model.nullText,
      },
      {
        value: OptionNames.true,
        text: model.trueText,
      },
      {
        value: OptionNames.false,
        text: model.falseText,
      },
    ];
  }
}

class StringToBoolMapper{
  
  static toValue(data: string){
    if(data === OptionNames.true){
      return true;
    }

    if(data === OptionNames.false){
      return false;
    }

    return null;
  }
}

@Component({
    selector: 'common-boolean-select-list',
    templateUrl: './boolean-select-list.component.html',
    styleUrls: ['./boolean-select-list.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class BooleanSelectListComponent implements OnInit {
  
  @Input()
  options:BooleanSelectListComponentOptions = {
    label: "common-boolean-select-list. Переопределите options",
    nullText: "Выбрано NULL. Переопределите options",
    falseText: "Выбрано FALSE. Переопределите options",
    trueText: "Выбрано TRUE. Переопределите options"
  };

  @Output()
  valueChanged = new EventEmitter<boolean>()

  selectOptions:Option[] = [];

  model = {
    value: OptionNames.null
  }

  valueChangedHandler(data: string){

    let val = StringToBoolMapper.toValue(data);
    this.valueChanged.emit(val);
  }

  ngOnInit(): void {
    this.selectOptions = OptionProvider.getOptions(this.options);
  }
}
