import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface PhoneNumberOptions {
    maskedInputPrefix: string; 
    maskedInputMask: string; 
    length: number; 
    phoneNumberSymbols: string; 
    phoneNumberSymbolsCheckErrorMessage: string; 
}

@Injectable({
    providedIn: 'root',
})
export class PhoneNumberOptionService {
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string) {
    }

    public get(countryId: string): Observable<PhoneNumberOptions> {
        return this._httpClient.get<PhoneNumberOptions>(this.baseUrl + `api/ptc/dlv/phone-number-options/${countryId}`);
    }
}
