import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

export interface TournamentTypeDescription {
    name: string; 
    description: TournamentDescription; 
}

export interface TournamentDescription {
    displayName: string; 
    maxNumberOfParticipants: number; 
}

@Injectable({
    providedIn: 'root',
})
export class TournamentTypesService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + "api/ptc/tournament/types/";
    }

    list(){
        return this._httpClient.get<TournamentTypeDescription[]>(this._baseControllerUrl + 'list')
    }
}
