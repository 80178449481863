import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy, forwardRef  } from '@angular/core';
import { UserInterfaceBlock } from 'src/models/genericInterface';
import { Subscription } from 'rxjs';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-generic-interface-multiple-dropdown-list',
    templateUrl: './generic-interface-multiple-dropdown-list.component.html',
    styleUrls: ['./generic-interface-multiple-dropdown-list.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericInterfaceMultipleDropdownListComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, NgFor, MatOptionModule]
})
export class GenericInterfaceMultipleDropdownListComponent implements OnInit, ControlValueAccessor, OnDestroy {

  valueControl: UntypedFormControl = new UntypedFormControl;
  @Input() interfaceBlock: UserInterfaceBlock;
  onChange: Function;
  onTouched: Function;
  subscribe: Subscription;
  selectValues: string[] = ['"Value 3"'];

  constructor() {
  }

  ngOnDestroy(){
    if(this.subscribe) this.subscribe.unsubscribe();
  }

  ngOnInit() {
    const value = this.interfaceBlock.dropDownData.selectList.filter(x => x.selected).map(x => x.value);
    this.value = value;
    this.subscribe = this.valueControl.valueChanges.subscribe((v)=>{
      if(this.onChange){
        this.onChange(v)
      }
    });
  }

  writeValue(obj: string[]): void {
      this.value = obj;
  }

  registerOnChange(fn: any): void {
      this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  set value(value) {
      this.valueControl.setValue(value);
      if (this.onChange) {
          this.onChange(value);
      }
  }

  get value(): string[] {
      return this.valueControl.value;
  }
}