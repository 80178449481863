import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'ptc-rating-info',
    templateUrl: './rating-info.component.html',
    styleUrls: ['./rating-info.component.css'],
    standalone: true,
    imports: [MatCardModule]
})
export class RatingInfoComponent implements OnInit {

  constructor(title:Title) {
    title.setTitle("Как расчитывается рейтинг NTRP");
  }

  ngOnInit(): void {
  }

}
