import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserInterfaceBlock } from 'src/models/genericInterface';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoCompleteSuggestion } from 'src/models/genericInterface/AutoCompleteSuggestion';
import { GetAutocompleteDataService } from '../../common/services';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-generic-interface-autocomplete',
    templateUrl: './generic-interface-autocomplete.component.html',
    styleUrls: ['./generic-interface-autocomplete.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericInterfaceAutocompleteComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgSelectModule, FormsModule, ReactiveFormsModule]
})
export class GenericInterfaceAutocompleteComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  valueControl: UntypedFormControl = new UntypedFormControl();
  @Input() interfaceBlock: UserInterfaceBlock;
  onChange: Function;
  onTouched: Function;
  subscribe: Subscription;
  autoCompleteOptions: AutoCompleteSuggestion[];
  searchSubscribe: Subscription;
  loading: boolean;

  constructor(private getAutocompleteDataService: GetAutocompleteDataService) {
    this.subscribe = this.valueControl.valueChanges.subscribe((v) => {
      if (this.onChange) {
        this.onChange(this.value);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.searchSubscribe) this.searchSubscribe.unsubscribe();
  }

  ngOnInit() {
    this.getAutocompleteOptions();
  }

  getAutocompleteOptions({ term = '' } = {}): void {
    if (this.searchSubscribe) this.searchSubscribe.unsubscribe();
    this.loading = true;
    this.searchSubscribe = this.getAutocompleteDataService
      .getData(
        term,
        this.interfaceBlock.autoCompleteData.dataProviderTypeFullName
      )
      .pipe(
        tap(() => {
          this.loading = false;
        })
      )
      .subscribe((autoCompleteSuggestion) => {
        this.autoCompleteOptions = autoCompleteSuggestion;
      });
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public searchFn (term: string, item: unknown): boolean {
    return true;
  }

  set value(value) {
    this.valueControl.setValue(value);
    if (this.onChange) {
      this.onChange(value);
    }
  }

  get value(): any {
    return { ...this.valueControl.value, autocompleteFlag: true };
  }


}
