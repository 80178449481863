<div *ngIf="showDebug">
    <div>
        <b>showCropper</b>: {{showCropper}}
    </div>

    <div>
        <b>imageChangedEvent</b>:
    </div>

    <div>
        {{imageChangedEvent | json}}
    </div>
</div>

<div *ngIf="!finished">
    
    <div *ngIf="!isfileSelected">
        <button [hidden]="hidden" mat-raised-button color="primary" (click)="filesInput.click()">{{btnTexts.selectImage}}</button>
    </div>

    <input #filesInput [hidden]="true" type="file" accept="image/*" (change)="fileChangeEvent($event)" />

    <div [hidden]="!showCropper">
        
        <image-cropper 
            [imageChangedEvent]="imageChangedEvent" 
            [maintainAspectRatio]="cropperAspets.maintainAspectRatio"
            [aspectRatio]="cropperAspets.aspectRatio"
            format="png" 
            (imageCropped)="imageCropped($event)" 
            (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()" 
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>        
    </div>

    
    <div *ngIf="croppedImage">

        <div style="height: 20px;"></div>

        <div class="d-flex justify-content-center">
            <div>
                <h5>Итоговое изображение</h5>
            </div>
        </div>

        <div>
            <div class="d-flex justify-content-between">
                <button mat-mini-fab color="primary" (click)="rotateLeft()">
                    <mat-icon>rotate_left</mat-icon>
                </button>
    
                <div *ngIf="fileSelected && showCropper">
                    <button (click)="showCropper = false;" class="w-100" mat-raised-button color="primary">
                        <mat-icon>crop</mat-icon>
                        Сохранить пропорции
                    </button>
                </div>
                
                <div *ngIf="!showCropper">
                    <button (click)="showCropper = true;" class="w-100" mat-raised-button>
                        <mat-icon>crop</mat-icon>
                        Изменить пропорции
                    </button>
                </div>

                <button mat-mini-fab color="primary" (click)="rotateRight()">
                    <mat-icon>rotate_right</mat-icon>
                </button>
            </div>
        </div>

        <div style="height:20px"></div>

        <div class="d-flex justify-content-center">
            <img class="img-fluid" [src]="croppedImage" />
        </div>

        <div style="height: 20px;"></div>

        <div class="d-flex justify-content-center">
            <button (click)="imageisReadyUpload()" mat-raised-button color="primary" class="w-100">{{btnTexts.uploadImage}}</button>
        </div>

    </div>
</div>

<div *ngIf="finished">
    
</div>