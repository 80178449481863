import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { GameBaseModel, MappingExtensions } from 'src/app/tournament/components/t-grid/extensions/MappingExtensions';
import { PlayerInfo } from 'src/app/tournament/components/t-grid/t-grid.component';
import { GameService, PlayerSimpleModel, SinglesGameSimpleModel } from '../../services/GameService';
import { CourtTypes } from '../court-types-select/court-types-select.component';
import { MatButtonModule } from '@angular/material/button';
import { TGridMatchComponent } from '../../../tournament/components/t-grid/t-grid-match/t-grid-match.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { FilePathProvider } from 'src/app/common/services';

@Component({
    selector: 'ptc-single-game-card',
    templateUrl: './game-card.component.html',
    styleUrls: ['./game-card.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, TGridMatchComponent, MatButtonModule]
})
export class GameCardComponent implements OnInit {

  players: PlayerInfo[] =[];

  @Input()
  game:SinglesGameSimpleModel = null;

  @Input()
  playerId: string;

  @Input()
  showOpponent = false;

  @Input()
  showActions = false;

  @Output()
  onDeleted = new EventEmitter<string>();

  opponent:PlayerSimpleModel;

  courtTypeDisplayName = "";
  
  constructor(private readonly _gameService: GameService, 
    private readonly _notifier: NotifierService,
    private readonly _filePathPathProvider: FilePathProvider) {
  }

  ngOnInit(): void {
    let gameModel:GameBaseModel = {
      players: this.game.players.map(t => ({userId:t.player.id, isWinner:t.isWinner})), 
      scoreData:this.game.scoreData
    };

    let playerModels = this.game.players.map(t => (t.player));
    this.players = MappingExtensions.getPlayersByEvent(gameModel, playerModels);

    this.courtTypeDisplayName = CourtTypes.getDescription(this.game.courtType);

    if(this.showOpponent){
      this.opponent = this.getOpponent();
    }
  }

  getSrc(fileId: number){
    return this._filePathPathProvider.getMediumImageFilePath(fileId);
  }

  getOpponent():PlayerSimpleModel{
    return this.game.players.find(x => x.player.id !== this.playerId).player;
  }

  delete(){
    this._gameService.delete(this.game.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.onDeleted.emit(this.game.id);
      }
    })
  }

  matchClicked(){
    //this._router.navigate(['/ptc/tournament/game', this.game.id]);
  }

}
