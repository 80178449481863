import { SetRawData, SetData } from './scores-main.component';
import {TennisMatchData, TennisSetData} from '../../../common/modules/tournament-admin/services';

export class SetDataExtensions {

  static toSetsData(value: TennisMatchData):SetData[]{

    let result = [];
    for(let i = 0; i < value.sets.length; i++){
      result.push(SetDataExtensions.toSetData(value.sets[i]));
    }

    return result;
  }

  static toSetData(value: TennisSetData): SetData{
    return {
      score1: +value.score1,
      score2: +value.score2,
      tieBreak: {
        score1: +value.tieBreak.score1,
        score2: +value.tieBreak.score2,
      }
    }
  }

  static toTennisMatchData(value: SetData[], player1Id: string, player2Id: string): TennisMatchData{

    let isWining = this.isWining(value);

    return {
      winnerPlayerId: isWining? player1Id: player2Id,
      sets: value.map(x => (this.toTennisSetData(x)))
    }
  }

  static toTennisSetData(value: SetData): TennisSetData{
    return {
      score1: value.score1.toString(),
      score2: value.score2.toString(),
      tieBreak: {
        score1: "",
        score2: ""
      }
    }
  }

  static getStringValue(value: SetData[]):string {
    var str = "";

    for (var i = 0; i < value.length; i++) {
      var setData = value[i];
      str += `${setData.score1}:${setData.score2}`;

      if (i != value.length - 1) {
        str += " ";
      }
    }

    return str;
  }

  static parseFromRaw(value: SetRawData, setNumber: number, onError: (e: string) => void): SetData {
    var scores = value.score.split(':');

    var score1 = parseInt(scores[0]);
    var score2 = parseInt(scores[1]);

    if (isNaN(score1)) {
      onError(`Не удалось распознать очки слева в ${setNumber + 1} сете`);
      score1 = 0;
      return null;
    }

    if (isNaN(score2)) {
      onError(`Не удалось распознать очки справа в ${setNumber + 1} сете`);
      score2 = 0;
      return null;
    }

    return {
      score1: +score1,
      score2: +score2,
      tieBreak: null
    };
  }

  static isWining(value: SetData[]): boolean {

    let score1 = 0;
    let score2 = 0;

    for (var i = 0; i < value.length; i++) {
      var setData = value[i];

      if (this.isWinningSetData(setData)) {
        score1++;
      } else {
        score2++;
      }
    }

    return score1 > score2;
  }

  static isWinningSetData(setData: SetData): boolean {

    return setData.score1 > setData.score2;
  }

  static isValidMatchData(
    value: SetData[],
    onError: (s: string) => void
  ): boolean {

    var setScore1 = 0;
    var setScore2 = 0;

    for (var i = 0; i < value.length; i++) {
      var setData = value[i];

      if (!this.isValidSetData(setData, i, onError)) {
        return false;
      }

      if (this.isWinningSetData(setData)) {
        setScore1++;
      } else {
        setScore2++;
      }
    }

    if (setScore1 == setScore2) {
      onError("Неправильно указан счёт в матче. Очки сета равны.");
      return false;
    }

    return true;
  }

  static isValidSetData(
    setData: SetData,
    setNum: number,
    onError: (s: string) => void
  ): boolean {

    let score1 = setData.score1;
    let score2 = setData.score2;

    if (score1 == score2) {
      onError(
        `Неправильно указан счёт в ${setNum + 1} сете. Равное количество геймов.`);
      return false;
    }

    if (score1 < 0 || score2 < 0) {
      onError(
        `Неправильно указан счёт в ${setNum + 1} сете. Отрицательное число в одном из геймов.`);
      return false;
    }

    if (score1 > 7 || score2 > 7) {
      onError(
        `Неправильно указан счёт в ${setNum + 1} сете. В одном из геймов указано более 7 очков.`);
      return false;
    }

    if (score1 < 6 && score2 < 6) {
      onError(`Неправильно указан счёт в ${setNum + 1} сете. 2`);
      return false;
    }

    if (score1 == 7 && score2 != 6 && score2 != 5) {
      onError(`Неправильно указан счёт в ${setNum + 1} сете. 3`);
      return false;
    }

    if (score1 != 6 && score1 != 5 && score2 == 7) {
      onError(`Неправильно указан счёт в ${setNum + 1} сете. 4`);
      return false;
    }

    return true;
  }
}
