import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ClipboardService } from 'ngx-clipboard';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {PlayerAccountTelegramService, PlayerData, TelegramLinkResponse} from '../../../../services';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'ptc-telegram-player-data',
    templateUrl: './telegram-player-data.component.html',
    standalone: true,
    imports: [NgIf, MatButtonModule]
})
export class TelegramPlayerDataComponent implements OnInit {

  @Input()
  player: PlayerData;

  @Input()
  showTitle = true;

  linkResponse: TelegramLinkResponse;

  telegramLink:string = '';

  constructor(private _telegramService: PlayerAccountTelegramService,
    private _notifierService: NotifierService,
    private _clipboardService: ClipboardService,) { }



  ngOnInit(): void {

    if (this.player.telegramUserName === '' || !this.player.telegramUserId){
      return;
    }

    if (this.player.telegramUserName !== '') {
      this.telegramLink = `https://t.me/${this.player.telegramUserName}`;
      return;
    }

    if (this.player.telegramUserId) {
      this.telegramLink = `https://t.me/${this.player.telegramUserId}`;
      return;
    }
  }

  generateTelegramLink(): void {

    this._telegramService.createLink().subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifierService, data);

      this.linkResponse = data.responseObject;
    });
  }

  copyLink(): void {
    this._clipboardService.copy(this.linkResponse.command);
    NotificaterExtensions.ShowSuccess(this._notifierService, 'Команда скопирована в буфер обмена');
  }
}
