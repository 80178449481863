<div *ngIf="client">
    <mat-card class="w-100">
        <mat-card-header>
          <app-client-avatar [size]="100" [class]="mat-card-avatar" [clientAvatarData]="client"></app-client-avatar>
          
          <mat-card-title>{{client.surname}} {{client.name}} {{client.patronymic}}</mat-card-title>
          <mat-card-subtitle *ngIf="!client.noEmail">{{client.email}}</mat-card-subtitle>
        </mat-card-header>
        
        <mat-card-content>
            <div>NTRP Рейтинг: {{client.ntrpRating}}</div>
            <div>Игровой рейтинг: {{client.rating.toFixed(2)}}</div>
            <div>Номер телефона: {{client.phoneNumber || 'Не указано'}}</div>
            <div>Дата рождения: {{birthDateStr}}</div>
            <div>Дата регистрации: {{createdOnStr}}</div>
            <div>Пол: {{sex}}</div>
        </mat-card-content>
    </mat-card>
</div>