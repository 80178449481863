import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/clt/services/LoginService';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CreateGameInstructionComponent } from '../create-game-instruction/create-game-instruction.component';
import { RespondGameRequestInstructionComponent } from '../respond-game-request-instruction/respond-game-request-instruction.component';
import { CreateGameRequestInstructionComponent } from '../create-game-request-instruction/create-game-request-instruction.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'ptc-instructions-list',
    templateUrl: './instructions-list.component.html',
    styleUrls: ['./instructions-list.component.css'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, CreateGameRequestInstructionComponent, RespondGameRequestInstructionComponent, CreateGameInstructionComponent, RouterLink, NgIf]
})
export class InstructionsListComponent implements OnInit {

  isAuthenticated = false;
  
  constructor(loginService: LoginService) {
    loginService.getLoginDataCached().subscribe(data => {
      this.isAuthenticated = data.isAuthenticated;
    });
  }


  ngOnInit(): void {
  }

  step = -1;

  setStep(index: number) {
    this.step = index;
  }

}
