import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';
import { MappingExtensions } from './extensions/MappingExtensions';
import {TournamentDetailedModel, TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { TGridMatchComponent } from './t-grid-match/t-grid-match.component';
import { NgTemplateOutlet, NgIf, NgFor } from '@angular/common';

export interface PlayerInfo {
  player?: PlayerSimpleModel,
  scores?: string[],
  isWinner?: boolean
}

@Component({
    selector: 't-grid',
    templateUrl: './t-grid.component.html',
    styleUrls: ['./t-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgTemplateOutlet, NgIf, NgFor, TGridMatchComponent]
})
export class TGridComponent implements OnInit {

  constructor(private _router: Router){
  }

  @Input()
  tournamentDetailedModel: TournamentDetailedModel;

  events: TournamentEventModel[][] = []
  additionalEvents: TournamentEventModel[][] = []

  readonly roundTitleByRoundIndex = [
    '1-ый круг',
    '2-ой круг',
    '3-ий круг'
  ]
  private readonly eventNameByIdentifier = {
    7: 'Финал',
    8: 'Игра за 3-е место',
    9: 'Игра за 5-е место 1-ый круг',
    11: 'Игра за 5-е место',
    12: 'Игра за 7-е место'
  }

  ngOnInit (): void {
    const events = this.tournamentDetailedModel.events.sort((event1, event2) => event1.eventNumberIdentifier - event2.eventNumberIdentifier)
    this.events.push(
      events.slice(0, 4),
      events.slice(4, 6),
      events.slice(6, 8)
    )

    if(events.length > 8) {
      this.additionalEvents.push(
        events.slice(8, 10),
        events.slice(10, 12)
      )
    }
  }

  getEventNameByIdentifier(identifier) {
    return this.eventNameByIdentifier[identifier] ?? ''
  }

  onMatchClick(game: TournamentEventModel):void {
    this._router.navigate(['/ptc/tournament/game', game.id]);
  }

  getPlayersByEvent (event: TournamentEventModel): PlayerInfo[] {
    return MappingExtensions.getPlayersByEvent(event, this.tournamentDetailedModel.players);
  }
}
