<ng-container *ngTemplateOutlet="grid; context: {isAdditionalEvents: false, events }"></ng-container>
<ng-container *ngTemplateOutlet="grid; context: {isAdditionalEvents: true, events: additionalEvents }"></ng-container>

<ng-template #grid let-isAdditionalEvents="isAdditionalEvents" let-events="events">
    <div *ngIf="events.length" class="t-grid__rounds t-grid__rounds-additional" [class.t-grid__rounds-additional]="isAdditionalEvents">
        <div class="t-grid__round" *ngFor="let column of events; let columniIndex = index">
            <p *ngIf="!isAdditionalEvents" class="t-grid__round-title">{{roundTitleByRoundIndex[columniIndex]}}</p>
            <div class="t-grid__match-list">
                <div *ngFor="let event of column; let eventIndex = index"  class="t-grid__match-wrapper">
                    <p *ngIf="getEventNameByIdentifier(event.eventNumberIdentifier)" class="t-grid__match-title">
                        {{ getEventNameByIdentifier(event.eventNumberIdentifier) }}
                    </p>
                    <t-grid-match (clickMatch)="onMatchClick(event)" class="t-grid__match" [playersInfo]="getPlayersByEvent(event)">
                    </t-grid-match>
                </div>
            </div>
        </div>
    </div>
</ng-template>




