<div>
    <span class="h6">Страна: {{courtData.country.name}}</span>
    
</div>
<div>
    <span class="h6">Город: {{courtData.city.name}}</span>
    
</div>
<div>
    <span class="h6">
        Корт: {{courtData.court}}
    </span>
</div>

<div>
    <span class="h6">
        Тип покрытия: {{courtData.courtType}}
    </span>
</div>

<div style="height: 20px;"></div>

<button class="w-100" (click)="clickHandler()" mat-raised-button color="primary">
    Назад
</button>