import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-create-game-instruction',
    templateUrl: './create-game-instruction.component.html',
    styleUrls: ['./create-game-instruction.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class CreateGameInstructionComponent implements OnInit {

  widgetUrl: SafeResourceUrl;
  
  constructor(private _domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.widgetUrl = this._domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/rWduw5-4uZc")
  }
}
