<div>
    <mat-card>
        <mat-card-content>
            <div>
                <h4>
                    <a [routerLink]="['/ptc/tournament', tournament.id]" href="...">
                        {{tournament.name}}
                    </a>
                </h4>
            </div>

            <ptc-tournament-type-link [type]="tournament.type"></ptc-tournament-type-link>

            <div *ngIf="tournament.description && tournament.description.length > 0"
                [innerHTML]="tournament.description">
            </div>

            <div style="height: 10px;"></div>

            <div *ngIf="tournament.participationCostRub > 0">
                Стоимость участия: {{tournament.participationCostRub}}₽
            </div>
            <div>
                Участников: {{tournament.currentNumberOfParticipants}} из {{tournament.maxNumberOfParticipants}}
            </div>

            <div *ngIf="tournament.openForParticipantsJoining && isAuthenticated">
                <div *ngIf="!tournament.isInTournament">
                    <div style="height: 10px;"></div>
                    <button (click)="join()" *ngIf="!showAddMoneyButton" mat-raised-button color="primary">
                        {{joinTitle}}
                    </button>

                    <a [routerLink]="['/ptc/info']" *ngIf="showAddMoneyButton">
                        Пополнить баланс
                    </a>
                </div>

                <div *ngIf="tournament.isInTournament">
                    <div>
                        Вы записаны на этот турнир
                    </div>
                    <div style="height: 10px;">

                    </div>
                    <button (click)="leave()" mat-raised-button color="primary">
                        Выйти из турнира
                    </button>
                </div>
            </div>

            <div *ngIf="!isAuthenticated">
                <div style="height:20px"></div>
                <div>
                    <b>Вы не авторизованы.</b>
                </div>

                <div>
                    Для участия в этом турнире вам необходимо <a [routerLink]="['/login']" href="...">авторизоваться</a>
                    или <a [routerLink]="['/registration']" href="...">зарегистрироваться</a>.
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <div style="height: 10px;"></div>
</div>