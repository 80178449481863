import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ClientService } from 'src/app/clt/services/ClientService';
import { CurrentLoginData, LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {FileUploadService} from '../../../common/services';
import { EditImageComponent } from '../../../common/components/edit-image/edit-image.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-player-avatar-cropper',
    templateUrl: './player-avatar-cropper.component.html',
    styleUrls: ['./player-avatar-cropper.component.css'],
    standalone: true,
    imports: [NgIf, EditImageComponent]
})
export class PlayerAvatarCropperComponent implements OnInit {

  finished = false;
  loginData: CurrentLoginData;
  fileName = '';

  constructor(private _loginService: LoginService,
    private _notifier: NotifierService,
    private _fileService: FileUploadService,
    private _cltService: ClientService){
    this._loginService.getLoginDataCached().subscribe(data => {
      this.loginData = data;
      this.fileName = `avatar_${this.loginData.userId}`;
    });
  }

  uploadAvatar(file: File){


    this._fileService.postFile(file, true).subscribe(data => {

      if(!data.isSucceeded){
        NotificaterExtensions.ShowError(this._notifier, 'Произошла ошибка при загрузке изображения на сервер');
        return;
      }

      this._cltService.UpdatePhoto(data.responseObject[0]).subscribe(photoResp => {
        if(!photoResp.isSucceeded){
          NotificaterExtensions.ShowError(this._notifier, 'Произошла ошибка при установке новой аватарки');
          return;
        }

        this._loginService.clearLoginDataCache();
        this._loginService.getLoginDataCached().subscribe(() => {
        });

        NotificaterExtensions.ShowSuccess(this._notifier, 'Ваш аватар изменён');
        this.finished = true;
      });

    });
  }

  ngOnInit(): void {
  }

}
