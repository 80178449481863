import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { Injectable } from '@angular/core';
import { concatMap, tap } from 'rxjs/operators';

export interface LoginViaLinkRequest {
  loginId: string;
  password: string;
}

export interface LoginViaLinkResult {
  isSucceeded: boolean;
  errorMessage: string;
  errorType: LoginViaLinkError;
  executionError: LinkActivationError | null;
}

export enum LoginViaLinkError {
  ModelNotValid = 'ModelNotValid',
  ActionExecution = 'ActionExecution',
  NoLoginData = 'NoLoginData',
  AuthenticationError = 'AuthenticationError'
}

export enum LinkActivationError {
  ModelValidation = 'ModelValidation',
  LinkNotFound = 'LinkNotFound',
  AlreadyExecuted = 'AlreadyExecuted',
  DateExpired = 'DateExpired',
  WrongPassword = 'WrongPassword',
  DatabaseError = 'DatabaseError'
}

export interface LogoutResponse {
  succeeded: boolean;
  errorMessage: string;
  errorType: LogoutErrorType;
}

export enum LogoutErrorType {
  NotAuthenticated = 'NotAuthenticated',
  SignoutError = 'SignoutError'
}


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loginData$ = new BehaviorSubject<CurrentLoginData>(null);
  private loginDataCached$ = this.loginData$.pipe(concatMap(data => {
    return data ? of(data) : this.getLoginData();
  }))

  constructor(
    private readonly _httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
  }

  clearLoginDataCache() {
    this.loginData$.next(null);
  }

  loginByEmail(data: LoginModel): Observable<LoginResultModel> {
    return this.loginByEmailApi(data).pipe(tap(res => {
      if (res.succeeded) {
        this.getLoginData().subscribe();
      }
    }));
  }

  loginByEmailOrPhoneNumber(data: LoginByEmailOrPhoneNumber): Observable<LoginResultModel> {
    return this.loginByEmailOrPhoneNumberApi(data).pipe(tap(res => {
      if (res.succeeded) {
        this.getLoginData().subscribe();
      }
    }));
  }

  loginByLink(model: LoginViaLinkRequest) {
    return this._httpClient.post<LoginViaLinkResult>(this.baseUrl + "api/account/Login/ByLink", model);
  }

  private loginByEmailApi(data: LoginModel): Observable<LoginResultModel> {
    return this._httpClient
      .post<LoginResultModel>(this.baseUrl + 'api/Account/Login/ByEmail', data)
  }

  private loginByEmailOrPhoneNumberApi(data: LoginByEmailOrPhoneNumber): Observable<LoginResultModel> {
    return this._httpClient
      .post<LoginResultModel>(this.baseUrl + 'api/Account/Login', data)
  }

  logOut(): Observable<LogoutResponse> {
    return this._httpClient
      .post<LogoutResponse>(this.baseUrl + 'api/Account/LogOut', {})
      .pipe(tap(res => {
        if (res.succeeded || (!res.succeeded && res.errorType === LogoutErrorType.NotAuthenticated)) {
          this.clearLoginDataCache();
          this.getLoginData().subscribe();
        }
      }));
  }

  public getLoginData(): Observable<CurrentLoginData> {
    return this.getLoginDataApi().pipe(tap(data => {
      this.loginData$.next(data);
    }));
  }

  getLoginDataCached(): Observable<CurrentLoginData> {
    return this.loginDataCached$;
  }

  private getLoginDataApi(): Observable<CurrentLoginData> {
    return this._httpClient
      .get<CurrentLoginData>(this.baseUrl + 'api/Account/User');
  }
}

export interface CurrentLoginData {
  isAuthenticated: boolean;
  userId: string;
  email: string;
  roles: Array<string>;
  avatarFileId: number | null;
  name: string;
  surname: string;
  patronymic: string;
  noEmail: boolean;
  noPhoneNumber: boolean;
}

export interface LoginModel {
  email: string;
  /* Пароль */
  password: string;
  /* Запомнить меня */
  rememberMe: boolean;
}

export interface LoginByEmailOrPhoneNumber {
  emailOrPhoneNumber: string;
  /* Пароль */
  password: string;
  /* Запомнить меня */
  rememberMe: boolean;
}

export interface LoginResultModel {
  errorType: LoginErrorType | null;
  errorMessage: string;
  succeeded: boolean;
}

export enum LoginErrorType {
  Error = <any>'Error',
  ModelNotValid = <any>'ModelNotValid',
  AlreadyAuthenticated = <any>'AlreadyAuthenticated',
  UnSuccessfulAttempt = <any>'UnSuccessfulAttempt',
  EmailNotConfirmed = <any>'EmailNotConfirmed',
  UserDeactivated = <any>'UserDeactivated'
}
