<div>
    
    <div *ngIf="showLocalTime">
        <div>
            Ваше локальное время: {{showDate()}}
        </div>
    
        <div style="height: 10px;">
        </div>
    </div>
    
    <mat-form-field [hidden]="!showDateTimePicker" class="w-100">
        <mat-label>{{dateLabel}}</mat-label>
        <input #dateInput 
            (keydown)="false"
            (click)="picker.open()"
            (dateChange)="updateDOB($event.value)"
            appDisableAutofill
            [(ngModel)]="data.utcDateIso" 
            (ngModelChange)="onDateChangedHandler()" 
            autocomplete="new-password"
            matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker ng-model-options="{timezone: 'utc'}" touchUi #picker>
        </mat-datepicker>
    </mat-form-field>

    <mat-form-field class="w-100" [hidden]="!showTime">
        <mat-label>{{timeLabel}}</mat-label>
        <input type="time" matInput (change)="setTime($event.srcElement.value)" 
            [value]="data.time" min="00:00" max="23:59" >
    </mat-form-field>
</div>