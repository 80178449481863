import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameCourtDataModel } from '../game-court-data/game-court-data.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'ptc-show-court-data',
    templateUrl: './show-court-data.component.html',
    styleUrls: ['./show-court-data.component.css'],
    standalone: true,
    imports: [MatButtonModule]
})
export class ShowCourtDataComponent implements OnInit {

  @Input()
  courtData: GameCourtDataModel = null;

  @Output()
  clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  clickHandler(){
    this.clicked.emit(true);
  }
}
