<h4>{{group.name}}</h4>

<div style="height: 10px;"></div>

<table mat-table [dataSource]="group.participants" class="mat-elevation-z8 w-100">
  <!-- Position Column -->
  <ng-container matColumnDef="demo-position">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="demo-name">
    <th mat-header-cell *matHeaderCellDef> Имя </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="['/ptc/player', element.player.id]" href="...">{{element.player.surname}}
        {{element.player.name}}</a>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="demo-weight">
    <th mat-header-cell *matHeaderCellDef> Кол-во побед </th>
    <td mat-cell *matCellDef="let element"> {{element.winsCount}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="demo-weight-2">
    <th mat-header-cell *matHeaderCellDef> Очки </th>
    <td mat-cell *matCellDef="let element"> {{element.pointsCount}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row [class.success] = "(i === 0 || i === 1)" *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
</table>

<div style="height: 10px;"></div>

<div class="d-flex" style="overflow-x: auto;">
  <div *ngFor="let game of group.games" style="margin:5px">
    <mat-card>
      <mat-card-content>
        <ptc-tournament-group-match-card [tournament]="tournament" [game]="game"></ptc-tournament-group-match-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div style="height: 10px;"></div>
