<div>
    <div style="height: 10px;"></div>

    <div *ngFor="let game of games">
        <mat-card>
            <mat-card-content>
                <ptc-single-game-card [showActions]="isAdmin" (onDeleted)="getGames()" [game]="game">
                </ptc-single-game-card>
            </mat-card-content>
        </mat-card>

        <div style="height: 10px;"></div>
    </div>
</div>