<div *ngIf="inProccess">
    <div class="d-flex justify-content-center align-items-center flex-column" style="min-height: 300px">
        <mat-spinner [diameter]="50" style="display: block;"></mat-spinner>
        <div *ngIf="!loginData">
            Идёт авторизация
        </div>

        <div *ngIf="loginData">
            Здравствуйте, {{loginData.surname}} {{loginData.name}}!
        </div>
    </div>
</div>

<div *ngIf="!inProccess && hasError" class="d-flex justify-content-center align-items-center flex-column">
    <div>
        <span class="h6">
            {{errorText}}
        </span>
    </div>
</div>