<div>
    <div class="form-group">
        <label for="album">Тип турнира</label>
        <ng-select [(ngModel)]="type"
            (ngModelChange)="onTypeChanged($event)"
            [items]="types"
            #select
            bindLabel="description.displayName"
            (search)="onSearchChanged($event)"
            [loading]="loading"
            [loadingText]="'Загружаю типы'"
            dropdownPosition="auto"
            bindValue="name"
            labelForId="type"
            placeholder="Выбрать тип"
            [virtualScroll]="true">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                    <span class="h6">{{item.description.displayName}}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
</div>