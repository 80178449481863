<div style="height: 10px;"></div>

<mat-card *ngIf="noGroups">
    <mat-card-content>
        Турнир ещё не начался
    </mat-card-content>
</mat-card>

<div *ngFor="let group of groups">
    <mat-card style="margin: 2px;">
        <mat-card-content>
            <ptc-tournament-group [tournament]="tournament" [group]="group"></ptc-tournament-group>
        </mat-card-content>
    </mat-card>
    <div style="height: 10px;"></div>
</div>

<mat-card *ngIf="!noGroups">
    <h4>Финальные игры</h4>
    <div style="height: 10px;"></div>
    <div>
        <div *ngFor="let game of finalGames">
            <mat-card>
                <mat-card-content>
                    <ptc-tournament-simple-match-card [game]="game" [tournament]="tournament">
                    </ptc-tournament-simple-match-card>
                </mat-card-content>
            </mat-card>
            
            <div style="height: 10px;"></div>
        </div>
    </div>
</mat-card>

<div style="height: 20px;"></div>