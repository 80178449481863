<mat-card>
    <mat-card-content>
        <h4>Игры на сайте:</h4>
    </mat-card-content>
</mat-card>

<div style="height: 10px;"></div>

<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Как создать заявку на игру
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ptc-create-game-request-instruction></ptc-create-game-request-instruction>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Как откликнуться на заявку
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ptc-respond-game-request-instruction></ptc-respond-game-request-instruction>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Как внести сыгранную игру
            </mat-panel-title>
        </mat-expansion-panel-header>

        <ptc-create-game-instruction></ptc-create-game-instruction>
    </mat-expansion-panel>

</mat-accordion>

<div style="height: 10px;"></div>

<mat-card>
    <mat-card-content>
        <h4>Турниры:</h4>
    </mat-card-content>
</mat-card>

<div style="height: 10px;"></div>

<mat-accordion>
    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Как проводится турнир
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
            <h5>
                Игроки <a [routerLink]="['/registration']" href="...">регистрируются</a> на сайте и записываются на открытые
        турниры.
            </h5>
            
        </div>
        
        <div style="height: 10px;"></div>
        <div>
            <h5>
                Соперники сами договариваются друг с другом о своей встрече в рамках турнира.
            </h5>
        </div>
        <div>
            <img class="img-fluid mx-auto d-block" src="images/dialog.jpg">
        </div>
        <div style="height: 10px;"></div>
        <div>
            <h5>
                После того как вы сыграли игру, вы или ваш соперник указываете счёт игры на сайте.
            </h5>
        </div>
        <img class="img-fluid mx-auto d-block" src="images/score.jpg">
        <div style="height: 10px;"></div>
        <div>
            <h5>
                А ваш соперник или вы (зависит от того, кто указывал счёт встречи) подтверждает указанный счёт.
            </h5>
        </div>
        <img class="img-fluid mx-auto d-block" src="images/confirm-result.jpg">
        <div>
            <div style="height: 10px;"></div>
            Здесь показаны текущие и планируемые турниры, вы можете записаться на участие в любой трунир, в который
            открыта
            запись.
        </div>

        <div *ngIf="!isAuthenticated">
            <div style="height:10px"></div>
            <b>Вы не авторизованы.</b>
            <div>
                Для участия в турнире вам необходимо <a [routerLink]="['/login']" href="...">авторизоваться</a> или <a
                    [routerLink]="['/registration']" href="...">зарегистрироваться</a>.
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
