<ng-container
  *ngIf="!(withoutMenuContainer$ | async); else routerOutletWithoutContainer"
>
  <mat-drawer-container hasBackdrop="true" autoFocus="false">
    <mat-drawer #drawer mode="over">
      <app-side-nav-menu></app-side-nav-menu>
    </mat-drawer>
    <mat-drawer-content>
      <app-nav-menu
        (sideBarControlClickEmmiter)="drawer.toggle()"
      ></app-nav-menu>
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>
<ng-template #routerOutletWithoutContainer
  ><router-outlet></router-outlet
></ng-template>
<ng-template #notifierWithHtmlTemplate let-notificationData="notification">
  <my-custom-alert type="{{ notificationData.type }}">
    <div [innerHTML]="notificationData.message"></div>
  </my-custom-alert>
  <button
    #dismissButton
    type="button"
    title="dismiss"
    class="notifier__notification-button notification-button-ok ng-star-inserted"
    (click)="dismiss(notificationData, dismissButton)"
  >
    OK
  </button>
</ng-template>
<notifier-container></notifier-container>
