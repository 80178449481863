import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {CountrySimpleModel, SearchCitiesFormData, SearchCountriesFormData} from '../../models';
import {CountryNameModel} from '../../../../services';
import { CountryQueryService } from 'src/app/tournament/services/CountryQueryService';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'common-country-select',
  templateUrl: './country-select.component.html',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    NgIf
  ]
})
export class CountrySelectComponent implements OnChanges, OnInit {

  @Input()
  searchModel: SearchCitiesFormData = {
    q: ''
  };

  @Input()
  isDebug = false;

  @Input()
  searchCountries: (data: SearchCountriesFormData) => Observable<CountrySimpleModel[]>;

  @Output()
  countryChanged = new EventEmitter<CountryNameModel>();

  loading = false;

  country: CountryNameModel = null;
  countries: CountrySimpleModel[] = [];

  setCountry(country: CountryNameModel){
    this.country = country;
  }

  searchFunction(term: string, item: CountrySimpleModel): boolean{
    return item.name.toLowerCase().includes(term.toLowerCase());
  }

  constructor(
    private readonly _countryService: CountryQueryService) {
    

    this.searchCountries = (data) => {
      return this._countryService.search({
        q: data.q,
        offSet: 0,
        count: 10,
      }).pipe(map(result => {
        return result.list;
      }));
    };
  }

  selectCountry(country: CountrySimpleModel): void {
    this.country = country;
    this.onCountryChanged(this.country);
  }

  onCountryChanged(country: CountryNameModel): void {
    this.countryChanged.emit(country);
  }

  ngOnInit(): void {
    this.loadCountries();
  }

  public loadCountries() {
    this.loading = true;
    this.searchCountries(this.searchModel).subscribe(data => {
      this.countries = [...data];
      this.loading = false;
    });
  }

  onSearchChanged(q: { term: string, items: CountrySimpleModel[] }) {
    this.searchModel.q = q.term;
    this.loadCountries();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadCountries();
  }
}
