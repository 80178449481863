import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {TournamentDetailedModel, TournamentMessagingService} from '../../services';
import {CityModel} from '../../../../models';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'ptc-messaging-panel',
    templateUrl: './messaging-panel.component.html',
    standalone: true,
    imports: [MatButtonModule]
})
export class MessagingPanelComponent implements OnInit {

  @Input()
  tournament: TournamentDetailedModel;

  city: CityModel;

  constructor(private _service: TournamentMessagingService,
    private _notifier: NotifierService) { }

  ngOnInit(): void {
  }

  sendPlanningMessage(){

    this._service.sendPlanningMessage(this.tournament.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    })
  }

  sendLongGamesMessages(){

    this._service.sendLongGamesMessages(this.tournament.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);
    })
  }
}
