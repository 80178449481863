<div>
    <div>
        <mat-form-field class="w-100">
            <input matInput placeholder="Ссылка" 
                new-autocomplete="off" autocomplete="off"
                [(ngModel)]="model.link">
        </mat-form-field>

        <mat-form-field class="w-100">
            <textarea cdkTextareaAutosize matInput placeholder="Описание" 
                new-autocomplete="off" autocomplete="off" 
                [(ngModel)]="model.description"></textarea>
        </mat-form-field>
    </div>

    <div class="d-flex justify-content-between">
        <button mat-raised-button color="primary" (click)="updateData()">
            Сохранить
        </button>
    
        <button mat-raised-button color="warn" (click)="deleteData()">
            Удалить
        </button>
    </div>
    
</div>