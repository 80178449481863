import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApiResponse, GenericBaseApiResponse } from "src/models/BaseApiResponse";

export interface TournamentJoiningResponse {
    isSucceeded: boolean; 
    errorType: TournamentJoiningErrorType | null; 
}


export enum TournamentJoiningErrorType {
   IsNotPlayer = <any> 'IsNotPlayer',
   AccountNotConfirmed = <any> 'AccountNotConfirmed',
   TournamentNotFound = <any> 'TournamentNotFound',
   TournamentNotOpenedForJoining = <any> 'TournamentNotOpenedForJoining',
   MaxNumberOfParticipantsReached = <any> 'MaxNumberOfParticipantsReached',
   AlreadyInTournament = <any> 'AlreadyInTournament',
   OppositeGender = <any> 'OppositeGender',
   NotEnoughMoney = <any> 'NotEnoughMoney',
   SystemError = <any> 'SystemError'
}

@Injectable({
    providedIn: 'root',
})
export class TournamentPlayerService {
    private _baseControllerUrl = "";
    
    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
            this._baseControllerUrl = baseUrl + "api/ptc/tournament/player/"
    }

    join(id: string){
        return this._httpClient.post<GenericBaseApiResponse<TournamentJoiningResponse>>(this._baseControllerUrl + `join/${id}`, {}); 
    }

    leave(id: string){
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `leave/${id}`, {}); 
    }
}