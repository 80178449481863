export class DateExtensions{
    static formatDate(dateUtcStr: string | Date):string{
        let date = new Date(dateUtcStr);
    
        let dateOffSet = new Date().getTimezoneOffset() * 60 * 1000;
    
        let nDate = new Date(date.getTime() - dateOffSet);
    
        return nDate.toLocaleString();
      }
}