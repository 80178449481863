import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, LoadedImage, ImageCropperModule } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import {ImageUtils} from '../../utils';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, JsonPipe } from '@angular/common';

export interface CropperAspects{
  maintainAspectRatio:boolean;
  aspectRatio: number;
}

export interface EditImageBtnTexts{
  selectImage: string;
  uploadImage: string;
}

@Component({
    selector: 'common-edit-image',
    templateUrl: './edit-image.component.html',
    styleUrls: ['./edit-image.component.css'],
    standalone: true,
    imports: [NgIf, MatButtonModule, ImageCropperModule, MatIconModule, JsonPipe]
})
export class EditImageComponent implements OnInit {

  showDebug = !environment.production;

  finished = false;

  @Input()
  showCropper = true;
  isFileSelected = false;

  btnTexts: EditImageBtnTexts= {
    uploadImage: "Загрузить изображение",
    selectImage: "Выбрать изображение"
  };

  @Input()
  hidden = false;

  @Input()
  cropperAspets:CropperAspects = {
    maintainAspectRatio: true,
    aspectRatio: 1 / 1
  };

  @Input()
  fileName = "image"

  @Output()
  imageHandler = new EventEmitter<File>();

  @Output()
  fileSelected = new EventEmitter<boolean>();

  imageFormat = "png";

  @ViewChild("filesInput")
  filesInput: ElementRef<HTMLInputElement>;

  constructor(){
  }

  imageChangedEvent: any = '';
  croppedImage: string = '';
  originalImage:string = '';

  openImageEditor(){
    this.filesInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.isFileSelected = true;
    this.fileSelected.emit(true);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    this.originalImage = image.original.base64;
    if(!this.showCropper){
      this.croppedImage = this.originalImage;
    }
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  rotateLeft(){
    this.croppedImage = ImageUtils.rotateBase64Image90deg(this.croppedImage, false);
  }

  rotateRight(){
    this.croppedImage = ImageUtils.rotateBase64Image90deg(this.croppedImage, true);
  }


  imageisReadyUpload(){
    var file = ImageUtils.dataURLtoFile(this.croppedImage, `${this.fileName}.${this.imageFormat}`);

    this.imageHandler.emit(file);
  }

  ngOnInit(): void {
  }


}
