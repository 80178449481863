import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatPaginatorIntl, PageEvent, MatPaginatorModule } from '@angular/material/paginator';

@Component({
    selector: 'base-app-page-numbers-select',
    templateUrl: './page-numbers-select.component.html',
    standalone: true,
    imports: [MatPaginatorModule]
})
export class PageNumbersSelectComponent {

  @Input() totalCount: number;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [5, 10, 25, 100];

  @Output() onPageUpdated = new EventEmitter<PageEvent>()

  constructor(private _intl: MatPaginatorIntl) {
    this._intl.lastPageLabel = 'Следующая страница';
  }

  pageUpdated(pageEvent: PageEvent): void {
    this.onPageUpdated.emit(pageEvent);
  }
}
