import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import {PlayerAccountService, PlayerData} from '../../../common/services';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-player-data-confirm-email',
    templateUrl: './player-data-confirm-email.component.html',
    styleUrls: ['./player-data-confirm-email.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, RouterLink]
})
export class PlayerDataConfirmEmailComponent implements OnInit {

  confirmForm = new UntypedFormGroup({
    "code": new UntypedFormControl(),
  });

  player: PlayerData;

  @Output()
  codeConfirmed = new EventEmitter<boolean>();


  @Output()
  shouldConfirmEmitter = new EventEmitter<boolean>();

  codeData = {
    code: ""
  };

  constructor(private _playerAccountService: PlayerAccountService,
    private _notifier: NotifierService) {
  }

  confirmCode(){
    this._playerAccountService.confirmEmail(this.codeData.code).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.codeConfirmed.emit(true);
        this.shouldConfirmEmitter.emit(false);
      }

      this.getPlayerData();
    })
  }

  getPlayerData(){
    this._playerAccountService.getData().subscribe(data => {

      if(data === null){
        this.shouldConfirmEmitter.emit(false);
        return;
      }

      this.player = data;
      this.shouldConfirmEmitter.emit(!this.player.emailConfirmed || !this.player.accountConfirmed);
    });
  }

  ngOnInit(): void {
    this.getPlayerData();
  }

}
