import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { PlayerInfo } from '../t-grid.component'
import { TGridTeamComponent } from '../t-grid-team/t-grid-team.component';

@Component({
    selector: 't-grid-match',
    templateUrl: './t-grid-match.component.html',
    styleUrls: ['./t-grid-match.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [TGridTeamComponent]
})
export class TGridMatchComponent {
  @Input() playersInfo: PlayerInfo[] = [];
  @Output() clickMatch = new EventEmitter()
  
  onPlayerClick(): void {
    this.clickMatch.emit()
  }
 }
