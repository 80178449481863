import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { DateExtensions } from 'src/app/extensions/DateExtensions';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { TournamentGameRestrictionService, UpdateTournamentGameRestrictionRequest } from '../../services/TournamentGameRestrictionService';
import {TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { MatButtonModule } from '@angular/material/button';
import { MyDateTimePickerComponent } from '../../../common/modules/shared/components/my-date-time-picker/my-date-time-picker.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'ptc-tournament-game-admin-card',
    templateUrl: './tournament-game-admin-card.component.html',
    styleUrls: ['./tournament-game-admin-card.component.css'],
    standalone: true,
    imports: [MatCardModule, MyDateTimePickerComponent, MatButtonModule]
})
export class TournamentGameAdminCardComponent implements OnInit {

  @Input()
  isAdmin = false;

  @Input()
  game:TournamentEventModel = null;

  @Output()
  gameUpdated = new EventEmitter<boolean>();

  _dateIsoStr: string = "";

  constructor(private _restrictionService: TournamentGameRestrictionService,
    private _notifier: NotifierService) { }

  ngOnInit(): void {
  }

  dateChangedHandler(dateIsoStr: string){
    this._dateIsoStr = dateIsoStr;
  }

  formatDate(dateUtcStr: string | Date):string{
    return DateExtensions.formatDate(dateUtcStr);
  }

  removeDate(){
    this.updateDateInner({
      gameId: this.game.id,
      dateRestrictionUtc: null
    })
  }

  updateDate(){
    this.updateDateInner({
      gameId: this.game.id,
      dateRestrictionUtc: this._dateIsoStr
    })
  }

  updateDateInner(model: UpdateTournamentGameRestrictionRequest){
    this._restrictionService.updateDate(model).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifier, data);

      if(data.isSucceeded){
        this.gameUpdated.emit(true);
      }
    })
  }

}
