import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CurrentLoginData, LoginService } from 'src/app/clt/services/LoginService';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { BaseApiResponse } from 'src/models/BaseApiResponse';
import { AddRubsToPlayer, BalanceAdminService} from '../../services/BalanceAdminService';
import { ChangePlayerPasswordRequest, ChangePlayerRatingRequest, PlayerAdminService } from '../../services/PlayerAdminService';
import { PlayerModel, PlayerQueryService } from '../../services/PlayerQueryService';
import {PlayerAccountService, PlayerBalanceData} from '../../../common/services';
import { PtcRoles } from 'src/app/common/roles';
import { PlayerGamesComponent } from '../player-games/player-games.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { ClientAvatarComponent } from '../../../common/modules/shared/components/client-avatar/client-avatar.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'ptc-player-info',
    templateUrl: './player-info.component.html',
    styleUrls: ['./player-info.component.css'],
    standalone: true,
    imports: [MatTabsModule, NgIf, MatCardModule, ClientAvatarComponent, MatButtonModule, MatExpansionModule, MatFormFieldModule, MatInputModule, FormsModule, TextFieldModule, PlayerGamesComponent]
})
export class PlayerInfoComponent implements OnInit {

  isAdmin = false;
  transaction: AddRubsToPlayer = {
    playerId: null,
    sum: 100,
    description: ""
  };

  changePassModel: ChangePlayerPasswordRequest = {
    playerId: null,
    password: ""
  }

  rating: ChangePlayerRatingRequest = {
    playerId: null,
    rating: ""
  }

  balance: PlayerBalanceData;
  playerId: string;

  player: PlayerModel = null;
  loginData: CurrentLoginData = null

  constructor(private _route: ActivatedRoute,
    private _notifierService: NotifierService,
    private _playerAdminService: PlayerAdminService,
    private _playerAccountService: PlayerAccountService,
    private _loginService: LoginService,
    private _playerQueryService: PlayerQueryService,
    private _balanceService: BalanceAdminService,
    private _title: Title,
    ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.playerId = params['id'];
      this.loadPlayer();
    });

    this._loginService.getLoginDataCached().subscribe(data => {
      this.isAdmin = data.roles.includes(PtcRoles.admin);
      this.loginData = data;
    });
  }

  loginAsPlayer(){
    this._playerAdminService.login(this.player.id).subscribe(data => {
      NotificaterExtensions.ShowBaseApiResponse(this._notifierService, data);

      if(data.isSucceeded){
        this._loginService.clearLoginDataCache();
      }
    })
  }

  addTransaction(){
    this.transaction.playerId = this.playerId;
    this.transaction.description = "Пополнение баланса на сайте";
    this._balanceService.addTransaction(this.transaction).subscribe(data => {
      this.handleResponse(data);
    });
  }

  changePassword(){
    this.changePassModel.playerId = this.playerId;

    this._playerAdminService.changePassword(this.changePassModel).subscribe(data => {
      this.handleResponse(data);
    })
  }

  changeRating(){
    this.rating.playerId = this.playerId;
    this._playerAdminService.changeRating(this.rating).subscribe(data => {
      this.handleResponse(data);
    })
  }

  confirmPlayer():void{
    this._playerAdminService.confirm(this.playerId).subscribe(data => {
      this.handleResponse(data);
    })
  }

  handleResponse(data: BaseApiResponse){
    NotificaterExtensions.ShowBaseApiResponse(this._notifierService, data);

      if(data.isSucceeded){
        this.loadPlayer();
      }
  }

  loadPlayer(): void{
    this._playerQueryService.getById(this.playerId).subscribe(data => {
      this.player = data;

      this._title.setTitle(this.player.name);
    });

    this._playerAccountService.getBalanceDataById(this.playerId).subscribe(data => {
      this.balance = data;
    });
  }
}
