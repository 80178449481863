import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TournamentSimpleModel} from '../../../common/modules/tournament-admin/services';
import { MatCardModule } from '@angular/material/card';
import { TournamentCardComponent } from '../tournament-card/tournament-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ptc-tournaments-list-group',
    templateUrl: './tournaments-list-group.component.html',
    styleUrls: ['./tournaments-list-group.component.css'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule, NgFor, TournamentCardComponent, MatCardModule]
})
export class TournamentsListGroupComponent implements OnInit {


  @Output()
  onChange = new EventEmitter<boolean>();

  @Input()
  header = ""

  @Input()
  noTournamentsHeader = ""

  @Input()
  isAuthenticated = false;

  @Input()
  tournaments:TournamentSimpleModel[] = null;

  constructor() { }

  ngOnInit(): void {
  }

  getTournaments(){
    this.onChange.emit();
  }

}
