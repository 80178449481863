import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlayerSimpleModel } from '../../services/GameService';
import { GameCourtDataModel, GameCourtDataComponent } from '../game-court-data/game-court-data.component';
import { SetData } from '../scores-main/scores-main.component';
import { SetDataExtensions } from '../scores-main/SetDataExtensions';
import { SetScoreDataController, SetScoreDataResult, SetScoreDataCommonComponent } from '../set-score-data-common/set-score-data-common.component';
import { TennisMatchData } from '../../../common/modules/tournament-admin/services';
import { ShowImageComponent } from '../show-image/show-image.component';
import { GameImageComponent } from '../game-image/game-image.component';
import { ShowCourtDataComponent } from '../show-court-data/show-court-data.component';
import { MatButtonModule } from '@angular/material/button';
import { ScoresSingleAsTextComponent } from '../scores-single-as-text/scores-single-as-text.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, JsonPipe } from '@angular/common';
import { FilePathProvider } from 'src/app/common/services';

export interface SetGameModelData {
  imageFileId: number | null;
  isWinning: boolean;
  scoreText: string;
  courtData: GameCourtDataModel;
  scoreData: SetData[];
}


export class SetFullGameDataController {
  step = 0;
  model: SetGameModelData = {
    imageFileId: null,
    isWinning: false,
    scoreText: "",
    courtData: null,
    scoreData: null,
  };

  setScore(data: TennisMatchData) {
    this.model.scoreData = SetDataExtensions.toSetsData(data);
    this.model.isWinning = SetDataExtensions.isWining(this.model.scoreData);
    this.model.scoreText = SetDataExtensions.getStringValue(this.model.scoreData);
  }
}

@Component({
  selector: 'ptc-set-full-game-data',
  templateUrl: './set-full-game-data.component.html',
  styleUrls: ['./set-full-game-data.component.css'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    SetScoreDataCommonComponent,
    ScoresSingleAsTextComponent,
    MatButtonModule,
    GameCourtDataComponent,
    ShowCourtDataComponent,
    GameImageComponent,
    ShowImageComponent,
    JsonPipe
  ]
})
export class SetFullGameDataComponent implements OnInit {

  isDebug = false;

  @Input()
  player1: PlayerSimpleModel;

  @Input()
  player2: PlayerSimpleModel;

  @Output()
  gameUpdated = new EventEmitter<SetGameModelData>();

  @Input()
  controller = new SetFullGameDataController();

  scoreController: SetScoreDataController;

  step = 0;

  model: SetGameModelData = null;

  constructor(private readonly _filePathProvier: FilePathProvider) {
  }

  scoreSetHandler(data: SetScoreDataResult) {

    this.model.scoreData = data.scoreRawData;
    this.model.isWinning = data.isWining;
    this.model.scoreText = data.scoreText;
    this.step = 1;
  }

  courtDataHandler(data: GameCourtDataModel) {
    this.model.courtData = data;
    this.step = 2;
  }

  createGame() {
    this.gameUpdated.emit(this.model);
  }

  getSrc(fileId: number) {
    return this._filePathProvier.getMediumImageFilePath(fileId);
  }

  showToStep3Btn = true;

  ngOnInit(): void {
    if (!this.controller) {
      this.controller = new SetFullGameDataController();
    }

    this.scoreController = new SetScoreDataController();
    this.scoreController.value = this.controller.model.scoreData;

    this.step = this.controller.step;
    this.model = this.controller.model;
  }
}
