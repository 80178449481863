import { Routes } from "@angular/router";
import { LoginLinkComponent } from "./components/login-link/login-link.component";
import { PlayerInfoComponent } from "./components/player-info/player-info.component";
import { PlayersListComponent } from "./components/players-list/players-list.component";
import { RatingInfoComponent } from "./components/rating-info/rating-info.component";

export const PtcPlayerRoutes: Routes = [
    {
        path: 'ptc/players',
        component: PlayersListComponent
    },
    {
        path: 'ptc/player/:id',
        component: PlayerInfoComponent
    },
    {
        path: 'info/ntrp',
        component:RatingInfoComponent
    },
    {
        path: 'ptc/player-login/:loginId/:password/:command',
        component: LoginLinkComponent
    },
];