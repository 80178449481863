<div>
    <a mat-stroked-button [routerLink]="['/ptc/tournaments']" href="...">
        Все турниры
    </a>

    <div style="height: 10px;">
    </div>
</div>

<div *ngIf="tournament && isAdmin">
    <a mat-stroked-button [routerLink]="['/ptc/tournament/edit', tournament.id]" href="...">
        Редактировать турнир
    </a>

    <div style="height: 10px;">
    </div>
</div>

<div *ngIf="tournament">
    <mat-card>
        <mat-card-content>
            <span class="h6">{{tournament.name}}</span>

            <ptc-tournament-type-link [type]="tournament.type"></ptc-tournament-type-link>

            <div *ngIf="tournament.participationCostRub > 0">
                Стоимость участия: {{tournament.participationCostRub}}₽
            </div>
            <div style="height: 20px;"></div>

            <div *ngIf="tournament.description && tournament.description.length > 0">
                <div [innerHTML]="tournament.description">
                </div>
                <div style="height: 20px;"></div>
            </div>

            <div *ngIf="tournament.activityStatus === 'Planned'">
                Запланирован
            </div>

            <div *ngIf="tournament.activityStatus === 'Active'">
                Идут игры
            </div>

            <div *ngIf="tournament.activityStatus === 'Finished'">
                Игры завершены
            </div>

            <div *ngIf="tournament.activityStatus !== 'Active'">
                <div *ngIf="!tournament.isInTournament">
                    <div style="height: 10px;"></div>
                    <button (click)="join()" *ngIf="!showAddMoneyButton" mat-raised-button color="primary">
                        {{joinTitle}}
                    </button>

                    <a [routerLink]="['/ptc/info']" *ngIf="showAddMoneyButton">
                        Пополнить баланс
                    </a>
                </div>

                <div *ngIf="tournament.isInTournament">
                    <div *ngIf="tournament.activityStatus !== 'Finished'">
                        Вы учавствуете в этом турнире
                    </div>
                    <div *ngIf="tournament.activityStatus === 'Finished'">
                        Вы учавствовали в этом турнире
                    </div>
                    <div style="height: 10px;"></div>
                    <button *ngIf="tournament.activityStatus === 'Active'" (click)="leave()" mat-raised-button
                        color="primary">
                        Выйти из турнира
                    </button>
                </div>
            </div>
        </mat-card-content>

    </mat-card>
</div>

<div style="height: 20px;">
</div>

<div *ngIf="tournament">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Добавить пост
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <blog-post-editor (onPostAdded)="postAddedHandler()" [tournamentId]="tournament.id"></blog-post-editor>
        </div>
    </mat-expansion-panel>

    <div style="height: 20px;"></div>

    <ptc-posts-list #postList [tournamentId]="tournament.id"></ptc-posts-list>

    <div style="height: 20px;"></div>
</div>


<mat-tab-group *ngIf="tournament">
    <mat-tab label="Сетка">
        <div *ngIf="tournament.type !== 'For8WithTwoGroups' && tournament.type !== 'UniversalGroups'">
            <t-grid *ngIf="tournament.activityStatus === 'Active' || tournament.activityStatus === 'Finished'"
                class="t-grid" [tournamentDetailedModel]="tournament"></t-grid>

            <div *ngIf="tournament.activityStatus !== 'Active' && tournament.activityStatus !== 'Finished'">
                Сетка еще не готова
            </div>
        </div>

        <ptc-tournament-groups-view [tournament]="tournament" [tournamentId]="tournament.id"
            *ngIf="tournament.type === 'For8WithTwoGroups' || tournament.type === 'UniversalGroups'">
        </ptc-tournament-groups-view>
    </mat-tab>
    <mat-tab label="Участники">
        <table mat-table [dataSource]="tournament.players" class="mat-elevation-z8 w-100">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Имя </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/ptc/player', element.id]" href="...">
                        {{element.surname}} {{element.name}}
                    </a>
                </td>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Номер </th>
                <td mat-cell *matCellDef="let element; let i = index"> №{{i + 1}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-tab>
    <mat-tab *ngIf="tournament.activityStatus === 'Active'" label="Список игр">
        <ptc-tournament-bracket [tournament]="tournament"></ptc-tournament-bracket>
    </mat-tab>
</mat-tab-group>

<div style="height: 20px;"></div>