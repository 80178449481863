import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotificaterExtensions } from 'src/app/extensions/NotificaterExtensions';
import { PlayerModel } from 'src/app/player/services/PlayerQueryService';
import { ScoresMainComponent, SetData } from '../scores-main/scores-main.component';
import { SetDataExtensions } from '../scores-main/SetDataExtensions';
import { TennisMatchData } from '../../../common/modules/tournament-admin/services';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, JsonPipe } from '@angular/common';

export interface SetScoreDataResult {
  scoreData: TennisMatchData;
  scoreRawData: SetData[];
  isWining: boolean;
  scoreText: string;
}

export class SetScoreDataController {
  value: SetData[] = [];
}

@Component({
  selector: 'ptc-set-score-data-common',
  templateUrl: './set-score-data-common.component.html',
  styleUrls: ['./set-score-data-common.component.css'],
  standalone: true,
  imports: [NgIf, ScoresMainComponent, MatButtonModule, JsonPipe]
})
export class SetScoreDataCommonComponent implements OnInit {

  showDebugInfo = false;

  @Input()
  player1: PlayerModel;

  @Input()
  player2: PlayerModel;

  @ViewChild("scores")
  scores: ScoresMainComponent;

  @Output()
  scoreSet = new EventEmitter<SetScoreDataResult>();

  @Input()
  controller = new SetScoreDataController();

  constructor(private readonly _notifier: NotifierService) {
  }

  setScore() {
    let score = this.scores.getScore();

    if (score === null) {
      return;
    }

    var isValid = SetDataExtensions.isValidMatchData(score, e => this.errorHandler(e));

    if (!isValid) {
      return;
    }

    const scoreData = SetDataExtensions.toTennisMatchData(score, this.player1.id, this.player2.id);

    this.scoreSet.emit({
      scoreRawData: score,
      scoreData: scoreData,
      isWining: SetDataExtensions.isWining(score),
      scoreText: SetDataExtensions.getStringValue(score)
    })
  }

  errorHandler(error: string): void {
    NotificaterExtensions.ShowError(this._notifier, error);
  }

  ngOnInit(): void {
  }
}
