import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GetListResult } from "src/app/ecc/services/NotificationService";

export interface SearchPlayersRequest {
    q: string; 
    sex: boolean | null; 
    emailConfirmed: boolean | null; 
    accountConfirmed: boolean | null; 
    dataFilled: boolean | null;
    cityId: string | null; 
    registrationSource: string | null;
    count: number | null; 
    offSet: number; 
}

export interface PlayerModel {
    id: string; 
    aboutMe: string; 
    sex: boolean; 
    dataFilled: boolean; 
    name: string; 
    surname: string; 
    patronymic: string; 
    phoneNumber: string; 
    email: string; 
    noEmail: boolean;
    ntrpRating: string; 
    rating: number; 
    telegramUserId: number | null; 
    telegramUserName: string; 
    emailConfirmed: boolean; 
    accountConfirmed: boolean; 
    avatarFileId: number | null; 
    registrationSource: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlayerQueryService {

    baseControlerUrl:string;

    constructor(private readonly _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.baseControlerUrl = baseUrl + "Api/ptc/Player/";
    }

    public search(model: SearchPlayersRequest): Observable<GetListResult<PlayerModel>> {
        return this._httpClient.post<GetListResult<PlayerModel>>(this.baseControlerUrl + 'search', model);
    }

    public getRegistratonSources(): Observable<string[]> {
        return this._httpClient.get<string[]>(this.baseControlerUrl + 'search/RegistrationSources');
    }

    public getById(id: string): Observable<PlayerModel> {
        return this._httpClient.get<PlayerModel>(this.baseControlerUrl + `getById/${id}`);
    }
}
