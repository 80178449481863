import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-respond-game-request-instruction',
    templateUrl: './respond-game-request-instruction.component.html',
    styleUrls: ['./respond-game-request-instruction.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class RespondGameRequestInstructionComponent implements OnInit {

  widgetUrl1: SafeResourceUrl;
  widgetUrl2: SafeResourceUrl;
  
  constructor(private _domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.widgetUrl1 = this._domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/ttuYYtPhMy4");
    this.widgetUrl2 = this._domSanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/8dAUZAW61Hw");
  }
}
