import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GetListResult } from '../../common/modules/tournament-admin/services';

export interface SearchCountries {
    q: string;
    count: number | null;
    offSet: number;
}

export interface CountrySimpleModel {
    name: string;
    id: string;
    citiesCount: number;
}

@Injectable({
    providedIn: 'root',
})
export class CountryQueryService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this._baseControllerUrl = baseUrl + "api/ptc/dlv/countries/";
    }

    search(model: SearchCountries) {
        return this._httpClient.post<GetListResult<CountrySimpleModel>>(this._baseControllerUrl + 'search', model);
    }

    byId(id: string) {
        return this._httpClient.get<CountrySimpleModel>(this._baseControllerUrl + id);
    }
}
