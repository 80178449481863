<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">
      <button _ngcontent-frd-c160="" mat-icon-button="" aria-label="Sidnav"
        class="mat-focus-indicator mat-icon-button mat-button-base" onclick="this.blur()"
        (click)="sideBarControlClick()">
        <span class="mat-button-wrapper">
          <mat-icon _ngcontent-frd-c160="" role="img" class="mat-icon notranslate material-icons mat-icon-no-color"
            aria-hidden="true" data-mat-icon-type="font">menu</mat-icon>
        </span>
        <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
        <span class="mat-button-focus-overlay"></span>
      </button>
      <a class="navbar-brand" [routerLink]="['/']">{{appName}}</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item">
            <app-login-data></app-login-data>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>