<div>
    <a mat-stroked-button [routerLink]="['/ptc/tournament', tournamentId]" href="...">
        Показать турнир
    </a>

    <a style="margin-left: 10px;" mat-stroked-button [routerLink]="['/ptc/tournaments']" href="...">
        Все турниры
    </a>

    <a style="margin-left: 10px;" mat-stroked-button
        [routerLink]="['/modules/ptc/tournament-group-editor', tournamentId]" href="...">
        Редактор групп
    </a>

    <div style="height: 10px;">
    </div>
</div>

<mat-tab-group>
    <mat-tab label="Основные">
        <mat-card *ngIf="tournament" style="margin: 2px;">

            <mat-card-content>
                <div *ngIf="tournament.activityStatus === 'Finished'">
                    <mat-card style="margin: 2px;">
                        <mat-card-content>
                            Турнир завершён
                        </mat-card-content>
                    </mat-card>
                </div>

                <div *ngIf="tournament.activityStatus === 'Planned'">
                    <mat-card style="margin: 2px;" *ngIf="!tournament.openForParticipantsJoining">
                        <mat-card-content>
                            <div>
                                В данный турнир участники не могут записываться
                            </div>
                            <button (click)="openForParticipation()" mat-raised-button color="primary">
                                Открыть для записи
                            </button>
                        </mat-card-content>
                    </mat-card>

                    <mat-card style="margin: 2px;" *ngIf="tournament.openForParticipantsJoining">
                        <mat-card-content>
                            <div>
                                В данный турнир участники могут записываться
                            </div>
                        </mat-card-content>

                    </mat-card>
                </div>

                <div style="height: 10px;"></div>

                <mat-card style="margin: 2px;">
                    <mat-card-content>
                        <div>
                            Участников: {{tournament.players.length}} из {{tournament.maxNumberOfParticipants}}
                        </div>
                    </mat-card-content>
                </mat-card>

                <div style="height: 10px;"></div>


                <div *ngIf="tournament.activityStatus === 'Active'">
                    <mat-card style="margin: 2px;">
                        <mat-card-content>
                            <button (click)="finish()" mat-raised-button color="primary">
                                Завершить турнир
                            </button>
                        </mat-card-content>

                    </mat-card>
                </div>


                <mat-card style="margin: 2px;" *ngIf="tournament.activityStatus === 'Planned'">
                    <mat-card-content>
                        <button (click)="start()" mat-raised-button color="primary">
                            Начать турнир
                        </button>
                    </mat-card-content>

                </mat-card>

                <div style="height: 10px;"></div>

                <mat-card *ngIf="tournament.activityStatus === 'Active'">
                    <mat-card-content>
                        <div>
                            Турнир запущен
                        </div>
                    </mat-card-content>
                </mat-card>

                <div style="height: 10px;"></div>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Редактор доп данных
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ptc-tournament-external-data-editor *ngIf="tournament" [tournament]="tournament">
                    </ptc-tournament-external-data-editor>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Дебаггинг
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <button (click)="computeGames()" mat-raised-button color="primary">
                        Расчитать будущие игры
                    </button>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Опасная секция
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="tournament.activityStatus === 'Active'">
                        <button (click)="clearGames()" mat-raised-button color="primary">
                            Стереть текущую сетку
                        </button>
                    </div>

                    <div style="height: 10px;"></div>
                    <button (click)="deleteTournament()" mat-raised-button color="primary">
                        Удалить турнир
                    </button>
                </mat-expansion-panel>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab label="Стоимость участия">

        <mat-card style="margin: 2px;" *ngIf="tournament">
            <mat-card-content>
                <div style="height: 10px;"></div>
                <div>
                    <mat-form-field class="w-100">
                        <mat-label>Стоимость участия</mat-label>
                        <input type="number" matInput [(ngModel)]="tournament.participationCostRub">
                    </mat-form-field>
                </div>
                <button (click)="changeParticipationCost()" mat-raised-button color="primary">
                    Изменить стоимость участия
                </button>
            </mat-card-content>
        </mat-card>
    </mat-tab>
    <mat-tab label="Переименовать">
        <mat-card style="margin: 2px;">
            <mat-card-content>
                <div>
                    <t-restrictions-select #restrictionsSelect (onRestrictionChanged)="onRestrictionChanged($event)">
                    </t-restrictions-select>

                    <mat-form-field class="w-100">
                        <mat-label>Название турнира</mat-label>
                        <input matInput placeholder="Название турнира" [(ngModel)]="tournamentRenameModel.name">
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <textarea matInput [(ngModel)]="tournamentRenameModel.description" cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                            placeholder="Описание турнира"></textarea>
                    </mat-form-field>
                </div>
                <button (click)="rename()" mat-raised-button color="primary">
                    Переименовать
                </button>
            </mat-card-content>

        </mat-card>

    </mat-tab>
    <mat-tab *ngIf="tournament" label="Новости турнира">
        <mat-card style="margin: 2px;">
            <mat-card-content>
                <ptc-tournament-news-editor [tournament]="tournament" (updated)="loadTournament()">
                </ptc-tournament-news-editor>
            </mat-card-content>

        </mat-card>

    </mat-tab>
    <mat-tab label="Игроки">
        <mat-card style="margin: 2px;">
            <mat-card-content>
                <ptc-edit-tournament-players *ngIf="tournament" (updated)="loadTournament()" [tournament]="tournament">
                </ptc-edit-tournament-players>

                <div style="height: 20px;"></div>
            </mat-card-content>

        </mat-card>
    </mat-tab>

    <mat-tab label="Сообщения">
        <mat-card style="margin: 2px;">
            <mat-card-content>
                <ptc-messaging-panel *ngIf="tournament" [tournament]="tournament"></ptc-messaging-panel>
            </mat-card-content>

        </mat-card>
    </mat-tab>
</mat-tab-group>