import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {FilePathProvider, PlayerData} from '../../../common/services';
import { ClientAvatarComponent } from '../../../common/modules/shared/components/client-avatar/client-avatar.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-player-client-data',
    templateUrl: './player-client-data.component.html',
    styleUrls: ['./player-client-data.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, ClientAvatarComponent]
})
export class PlayerClientDataComponent implements OnInit {

  @Input()
  client: PlayerData;
  sex = "Не указано";
  birthDateStr = "";
  createdOnStr = "";
  avatarFilePath: string =null;

  constructor(private _filePathProvider: FilePathProvider) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateClientData(changes.client?.currentValue);
  }

  updateClientData(client: PlayerData){

    if(!client){
      return;
    }

    let sex = client.sex;

    if(sex !== null){
      this.sex = sex? "Мужской" : "Женский";
    }
    this.birthDateStr = new Date(client.birthDate).toLocaleDateString();
    this.createdOnStr = new Date(client.createdOn).toLocaleString();
  }

  ngOnInit(): void {
  }
}
