import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlayerModel } from '../../services/PlayerQueryService';
import { ClientAvatarComponent } from '../../../common/modules/shared/components/client-avatar/client-avatar.component';

@Component({
    selector: 'ptc-player-card',
    templateUrl: './player-card.component.html',
    styleUrls: ['./player-card.component.css'],
    standalone: true,
    imports: [ClientAvatarComponent]
})
export class PlayerCardComponent implements OnInit {

  @Input()
  player: PlayerModel;

  @Output()
  playerClicked = new EventEmitter<PlayerModel>();

  playerClickHandler(player: PlayerModel){
    this.playerClicked.emit(player);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
