import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-banner-info',
    templateUrl: './banner-info.component.html',
    styleUrls: ['./banner-info.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatButtonModule]
})
export class BannerInfoComponent implements OnInit {

  @Input() 
  message: string;

  @Input() 
  hasActions = true;
  
  type: string = "info";

  constructor() { }

  ngOnInit(): void {
  }

}
