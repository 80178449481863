<div *ngIf="client">
    <mat-card class="w-100">
        <mat-card-header>
        
          <app-client-avatar *ngIf="loginData" [size]="100" [class]="mat-card-avatar" [clientAvatarData]="loginData"></app-client-avatar>
          
          <mat-card-title>{{client.surname}} {{client.name}} {{client.patronymic}}</mat-card-title>
          <mat-card-subtitle>{{client.email}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div>Номер телефона: {{client.phoneNumber || 'Не указано'}}</div>
            <div>Дата рождения: {{client.birthDate || 'Не указано'}}</div>
            <div>Дата регистрации: {{client.registrationDate}}</div>
            <div>Пол: {{sex}}</div>
        </mat-card-content>
    </mat-card>
</div>