<div style="height: 10px;"></div>
<div>
    <mat-form-field class="w-100">
        <textarea matInput [(ngModel)]="tournament.newsHtml" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="Новости турнира"></textarea>
    </mat-form-field>
</div>

<button (click)="updateNews()" mat-raised-button color="primary">
    Обновить новости
</button>

<div style="height: 10px;"></div>

<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            Добавить разметку для изображения
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
        <div>
            <mat-form-field class="w-100">
                <mat-label>Идентификатор файла изображения</mat-label>
                <input type="number" matInput
                    [(ngModel)]="fileIdMarkUp.fileId">
            </mat-form-field>

            <mat-form-field class="w-100">
                <textarea matInput [(ngModel)]="fileIdMarkUp.markUp" cdkTextareaAutosize 
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1" 
                    cdkAutosizeMaxRows="5" 
                    placeholder="Готовая разметка">
                </textarea>
            </mat-form-field>
        </div>
        
        <button (click)="generateMarkUp()" mat-raised-button color="primary">
            Сгенерировать разметку
        </button>
    </div>
</mat-expansion-panel>


<div style="height: 20px;"></div>
<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            Добавить пост
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
        <blog-post-editor></blog-post-editor>
    </div>
</mat-expansion-panel>
