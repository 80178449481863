import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {IMenuItem} from '../../../../models';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-side-nav-menu-item',
    templateUrl: './side-nav-menu-item.component.html',
    standalone: true,
    imports: [MatExpansionModule, NgIf, RouterLink, NgFor, forwardRef(() => SideNavMenuItemComponent)]
})
export class SideNavMenuItemComponent implements OnInit {
  @Input() public menuItem: IMenuItem;
  @Input() public nesting = 0;

  @Input() public visibleChecks: {};
  constructor() { }

  ngOnInit() {
  }
}
