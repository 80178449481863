import { map, takeUntil, filter, mapTo, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { NotifierNotificationOptions } from 'angular-notifier/lib/models/notifier-notification.model';
import { ReplaySubject, Subject, timer, Observable } from 'rxjs';
import { NotifierAction } from 'angular-notifier/lib/models/notifier-action.model';
interface NotifierCustom {
  notificationOptions: NotifierNotificationOptions;
  withoutAutoClose?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class NotificationCustomStackService {
  private stack: NotifierCustom[] = [];
  private queue: NotifierCustom[] = [];
  private show$ = new ReplaySubject<NotifierCustom>(1);
  private defaultPreffixId = 'customStack';
  private defaulId = 0;
  private autoClose$ = new Subject<string>();
  constructor(private _notify: NotifierService) {
    const actionStream: Observable<NotifierAction> = _notify.actionStream;
    const defaultShow = this._notify.show;
    this._notify.show = (notificationOptions: NotifierNotificationOptions) => {
      this.show$.next({ notificationOptions });
    };
    const autoHide = this._notify.getConfig().behaviour.autoHide;
    const stacking = this._notify.getConfig().behaviour.stacking;
    this._notify.getConfig().behaviour.autoHide = false;
    this.show$
      .asObservable()
      .pipe(
        map(({ notificationOptions, withoutAutoClose }) => {
          if (!notificationOptions.id) {
            notificationOptions.id = `${this.defaultPreffixId}${this
              .defaulId++}`;
          }
          const withAutoCloseStack = this.stack.filter(
            (notify) => !notify.withoutAutoClose
          );
          if (
            withAutoCloseStack.length &&
            (!stacking || this.stack.length === stacking)
          ) {
            const [
              {
                notificationOptions: { id },
              },
            ] = withAutoCloseStack;
            this._notify.hide(id);
          }
          if (
            (this.stack.length && !stacking) ||
            this.stack.length < stacking
          ) {
            this.stack.push({ notificationOptions, withoutAutoClose });
            defaultShow.call(this._notify, notificationOptions);
            if (!withoutAutoClose) {
              this.autoClose$.next(notificationOptions.id);
            }
          } else {
            this.queue.push({ notificationOptions, withoutAutoClose });
          }
        })
      )
      .subscribe();
    actionStream?.subscribe(({ payload, type }) => {
      if (type === 'HIDE') {
        const hidedId = this.stack.findIndex(
          ({ notificationOptions }) => notificationOptions.id === payload
        );
        if (hidedId !== -1) {
          this.stack.splice(hidedId, 1);
        }

        if (this.queue.length) {
          this.show$.next(this.queue.shift());
        }
      }
    });
    if (autoHide) {
      this.autoClose$
        .pipe(
          mergeMap((id) =>
            timer(autoHide).pipe(
              takeUntil(
                actionStream.pipe(
                  filter(({ payload, type }) => {
                    if (
                      (type === 'HIDE' && payload === id) ||
                      type === 'HIDE_ALL'
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                )
              ),
              mapTo(id)
            )
          )
        )
        .subscribe((id) => {
          this._notify.hide(id);
        });
    }
  }

  public show(
    notificationOptions: NotifierNotificationOptions,
    withoutAutoClose: boolean
  ): void {
    this.show$.next({ notificationOptions, withoutAutoClose });
  }
}
