import { Routes } from "@angular/router";
import { CreateSinglesGameComponent } from "./components/create-singles-game/create-singles-game.component";
import { GamesMainComponent } from "./components/games-main/games-main.component";

export const PtcGameRoutes: Routes = [
    {
        path: 'ptc/my-games',
        component: GamesMainComponent
    },
    {
        path: 'ptc/game/:id',
        component: GamesMainComponent
    },
    {
        path: 'ptc/create-game',
        component: CreateSinglesGameComponent
    }
];