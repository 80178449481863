import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DiableAutofillDirective } from '../../directives/DiableAutofillDirective';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

export interface MyDateTimePickerComponentData{
  utcDateIso:string;
  time:string;
}

export class DateTimePickerExtensions{
  static extractDateAndTimeFromIsoString(dateIsoStr: string): MyDateTimePickerComponentData {
    let bits = dateIsoStr.split('T');

    return {
      utcDateIso: bits[0] + `T00:00:00.000Z`,
      time: bits[1].substring(0,5)
    }
  }

  static default():MyDateTimePickerComponentData{
     var extracted = this.extractDateAndTimeFromIsoString(new Date().toISOString());

     extracted.utcDateIso = this.plusLocal(new Date(extracted.utcDateIso)).toISOString();
     extracted.time = "10:00";

     return extracted;
  }

  static minusLocal(dateObj: Date): Date{
    var userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
    return new Date(dateObj.getTime() - userTimezoneOffset);
  }

  static plusLocal(dateObj: Date): Date{
    var userTimezoneOffset = dateObj.getTimezoneOffset() * 60000;
    return new Date(dateObj.getTime() + userTimezoneOffset);
  }
}

@Component({
    selector: 'my-date-time-picker',
    templateUrl: './my-date-time-picker.component.html',
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatInputModule, FormsModule, MatDatepickerModule, DiableAutofillDirective]
})
export class MyDateTimePickerComponent implements AfterViewChecked {

  data: MyDateTimePickerComponentData = DateTimePickerExtensions.default();

  @Input()
  dateLabel: string = "Дата";

  @Input()
  timeLabel:string = "Время по гринвичу";

  @Input()
  showTime = true;

  @Input()
  showLocalTime = true;

  @Input()
  showDateTimePicker = true;

  dateStr: string = new Date().toISOString();

  setDateFromIsoString(dateIsoStr: string){
    this.dateStr = dateIsoStr;
    this.data = DateTimePickerExtensions.extractDateAndTimeFromIsoString(dateIsoStr);
    this.onDateChangedHandler();
  }

  @Output()
  onDateChanged = new EventEmitter<string>();

  constructor(private _cdr: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this._cdr.detectChanges();
  }

  onDateChangedHandler(){
    this.onDateChanged.emit(this.getDate());
  }

  getDate():string{
    let newDateStr = this.dateStr.split('T')[0];

    newDateStr += "T" + this.data.time + ":00.000Z"

    return newDateStr;
  }

  updateDOB(dateObj: Date){

    let newDate = DateTimePickerExtensions.minusLocal(dateObj);

    this.dateStr = newDate.toISOString();
    this.onDateChanged.emit(this.getDate());
  }

  showDate(){
    let dateStr = this.getDate();
    return new Date(dateStr).toLocaleString();
  }

  setTime(value:string){
    this.data.time = value;
    this.onDateChanged.emit(this.getDate());
  }

  ngOnInit(): void {
  }

}
