<div>
    <div>
        <label for="album">{{labelText}}</label>
        <ng-select [(ngModel)]="playerId"
                (ngModelChange)="onPlayerSelectedHandler($event)"
                   [items]="players"
                   #select
                   bindLabel="fullName"
                   (search)="onSearchChanged($event)"
                   [loading]="loading"
                   [loadingText]="'Загружаю игроков'"
                   dropdownPosition="auto"
                   bindValue="player.id"
                   labelForId="player"
                   placeholder="Выбрать игрока"
                   [virtualScroll]="true">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                    <span class="h5">
                        <b>{{item.player.surname}} {{item.player.name}}</b>
                    </span>
                </div>
                <div *ngIf="showContactData">
                    <div>
                        <small>
                            <span>
                                Email: <b>{{item.player.email}}</b> 
                            </span>
                        </small>
                    </div>
                    <div>
                        <small>
                            <span>
                                Номер телефона: <b>{{item.player.phoneNumber}}</b> 
                            </span>
                        </small>
                    </div>
                </div>
                
            </ng-template>
        </ng-select>
    </div>
</div>