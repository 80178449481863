import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { TournamentTypeDescription, TournamentTypesService } from '../../services/TournamentTypesService';
import { NgSelectModule } from '@ng-select/ng-select';

export interface SearchTypesFormData{
  q:string;
}

@Component({
    selector: 'common-tournament-types-select',
    templateUrl: './tournament-types-select.component.html',
    styleUrls: ['./tournament-types-select.component.css'],
    standalone: true,
    imports: [NgSelectModule, FormsModule]
})
export class TournamentTypesSelectComponent implements OnChanges {

  searchModel: SearchTypesFormData = {
    q: ""
  };

  @Input()
  searchTypes: (data: SearchTypesFormData) => Observable<TournamentTypeDescription[]>;

  loading = false;
  type: string;
  types: TournamentTypeDescription[] = [];

  @Output()
  typeChanged = new EventEmitter<string>();

  constructor(
    private _typeService: TournamentTypesService) {
    
    this.searchTypes = (data) => {
      return this._typeService.list();
    }
  }

  onTypeChanged(type: string){
    this.typeChanged.emit(type);
  }

  ngOnInit(): void {
    this.loadTypes();
  }

  public loadTypes() {
    this.loading = true;
    this.searchTypes(this.searchModel).subscribe(data => {
      this.types = [...data];
      this.loading = false;
    });
  }

  onSearchChanged(q: { term: string, items: object[] }) {
    this.searchModel.q = q.term;
    this.loadTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadTypes();
  }
}
