import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BaseApiResponse } from "src/models/BaseApiResponse";

export interface UpdateTournamentGameRestrictionRequest {
    gameId: string; 
    dateRestrictionUtc: string | null; 
}

@Injectable({
    providedIn: 'root',
})
export class TournamentGameRestrictionService {
    private _baseControllerUrl = "";

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this._baseControllerUrl = baseUrl + "api/ptc/tournament-game/restriction/";
    }

    updateDate(model: UpdateTournamentGameRestrictionRequest) {
        return this._httpClient.post<BaseApiResponse>(this._baseControllerUrl + `date/update`, model);
    }
}
