import { Component, Input, OnInit } from '@angular/core';
import { PlayerSimpleModel } from 'src/app/game/services/GameService';
import { FilePathProvider } from '../../../common/services';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ptc-game-player-card',
    templateUrl: './game-player-card.component.html',
    styleUrls: ['./game-player-card.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class GamePlayerCardComponent implements OnInit {

  @Input()
  player: PlayerSimpleModel;

  avatarFilePath: string = "/images/default_player.jpg";

  constructor(private _filePathProvider: FilePathProvider) {
  }

  ngOnInit(): void {
    if (!this.player?.avatarFileId) {
      return;
    }

    this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.player.avatarFileId);
  }
}
