<div *ngIf="posts.length === 0">
    <mat-card>
        <mat-card-content>
            Пока здесь нет новостей
        </mat-card-content>
    </mat-card>
</div>

<div *ngFor="let post of posts">
    <mat-card>
        <mat-card-content>
            <ptc-post-block [isAdmin]="isAdmin" [userId]="userId" [post]="post" (postUpdated)="postEditedHandler()"
                (postDeleted)="postDeletedHandler()">
            </ptc-post-block>
        </mat-card-content>
    </mat-card>
    <div style="height: 5px;"></div>
</div>