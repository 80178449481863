<div>
    <div *ngIf="widgetUrl1">
        <iframe width="100%" height="auto" [src]="widgetUrl1"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>

    <div style="height:10px"></div>

    <div>
        Если у автора заявки привязан телеграм к учетной записи на сайте, 
        тогда вы можете сразу написать в личные сообщения.
    </div>

    <div style="height:10px"></div>

    <div *ngIf="widgetUrl2">
        <iframe width="100%" height="auto" [src]="widgetUrl2"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</div>