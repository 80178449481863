import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { ClientModel, ClientService, EditClient } from '../../services/ClientService';
import {BaseApiResponseWithFilesIds, FilePathProvider} from '../../../common/services';
import { UploadFilesBtnComponent } from '../../../common/modules/upload-files-btn/components';
import { ClientAvatarComponent } from '../../../common/modules/shared/components/client-avatar/client-avatar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf } from '@angular/common';
import { ClientDataComponent } from '../client-data/client-data.component';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css'],
    standalone: true,
    imports: [MatTabsModule, ClientDataComponent, NgIf, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, ClientAvatarComponent, UploadFilesBtnComponent]
})
export class ClientComponent implements OnInit {

  client: ClientModel;
  name = "";
  sex = "Не указано";
  hasAvatar = false;
  avatarFilePath: string = null;

  uploadAvatarBtnText:string = "Загрузить аватар";
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  constructor(private _clientService: ClientService,
    private _notifierService: NotifierService,
    private _filePathProvider: FilePathProvider,
    private _titleService: Title) {
    this._titleService.setTitle("Редактирование клиентских данных");
    this.getClientData();

  }

  getClientData(){
    this._clientService.GetClientData().subscribe(data => {
      this.client = data.responseObject;

      this.hasAvatar = !!this.client.avatarFileId;

      if(this.hasAvatar){
        this.avatarFilePath = this._filePathProvider.getSmallImageFilePath(this.client.avatarFileId);
        this.uploadAvatarBtnText = "Загрузить новый аватар";
      }
      else{
        this.uploadAvatarBtnText = "Загрузить аватар";
      }
    })
  }

  editClient(){
    var data:EditClient = {
      name: this.client.name,
      birthDate: this.client.birthDate,
      surname: this.client.surname,
      patronymic: this.client.patronymic,
      sex: this.client.sex,
      phoneNumber: this.client.phoneNumber
    };

    this._clientService.EditClientData(data).subscribe(resp => {
      this._notifierService.notify(resp.isSucceeded? "success" : "error", resp.message);
      this.getClientData();
    });
  }

  uploadAvatar(){
    this._notifierService.notify("error", "Не реализовано");
    //this.uploadAvatarBtn.clickFileInput();
  }

  removeAvatar(){
    this._clientService.DeletePhoto().subscribe(data => {
      this._notifierService.notify(data.isSucceeded? "success" : "error", data.message);
      this.getClientData();
    });
  }

  onAvatarFileUploaded(data: BaseApiResponseWithFilesIds){
    let fileId = data.responseObject[0];

    this._clientService.UpdatePhoto(fileId).subscribe(data => {
      this._notifierService.notify(data.isSucceeded? "success" : "error", data.message);
      this.getClientData();
    });
  }

  ngOnInit(): void {
  }
}
