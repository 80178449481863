import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {CityModel, GetListResult, SearchCities} from '../models';

@Injectable({
    providedIn: 'root',
})
export class CityQueryService {
    private readonly _baseControllerUrl;

    constructor(private readonly _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this._baseControllerUrl = baseUrl + 'api/ptc/dlv/cities/';
    }

    search(model: SearchCities) {
        return this._httpClient.post<GetListResult<CityModel>>(this._baseControllerUrl + 'search', model);
    }

    getById(id: string) {
        return this._httpClient.get<CityModel>(this._baseControllerUrl + `Get/ById/${id}`);
    }
}
