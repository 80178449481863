import { Component } from '@angular/core';
import { LoginService } from 'src/app/clt/services/LoginService';
import {IMenuItem} from '../../../../models';
import { MenuService } from 'src/app/common/services/MenuService';
import { SideNavMenuItemComponent } from '../side-nav-menu-item/side-nav-menu-item.component';
import { NgFor } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-side-nav-menu',
    templateUrl: './side-nav-menu.component.html',
    styleUrls: ['./side-nav-menu.component.css'],
    standalone: true,
    imports: [MatExpansionModule, NgFor, SideNavMenuItemComponent]
})
export class SideNavMenuComponent {

  visibleChecks = {
    undefined: true,
    "": true,
    'IsNotAuthorized': false,
    'IsAuthorized': false,
    'IsAdmin': false,
    'IsDeveloper': false,
    'IsTutorAdmin': false,
    'IsTutorStudent': false,
    'IsTutorAdminOrStudent': false
  };

  public menuItems: IMenuItem[];

  constructor(loginService: LoginService,
    menuServie: MenuService,
    ) {
    menuServie.getMenu("MainApp").subscribe(data => this.menuItems = data.items);

    loginService.getLoginDataCached().subscribe(data => {
      this.visibleChecks['IsNotAuthorized'] = !data.isAuthenticated;
      this.visibleChecks['IsAuthorized'] = data.isAuthenticated;
      this.visibleChecks['IsDeveloper'] = data.roles.includes('Developer');
      this.visibleChecks['IsAdmin'] = data.roles.includes('Admin');
      this.visibleChecks['IsTutorAdmin'] = data.roles.includes('Tutor.Admin');
      this.visibleChecks['IsTutorStudent'] = data.roles.includes('Tutor.Student');
      this.visibleChecks['IsTutorAdminOrStudent'] = data.roles.includes("Tutor.Admin") || data.roles.includes("Tutor.Student");
    });
  }
}
