<div class="player-block">
    <div *ngIf="player">
      <a [routerLink]="['/ptc/player', player.id]" href="...">
        <img [attr.src]="avatarFilePath" class="img-fluid" alt="...">
      </a>
    </div>
    
    <div *ngIf="player" class="text-center">
      <a [routerLink]="['/ptc/player', player.id]" href="...">
        {{player.surname}} {{player.name}}
      </a>
    </div>

    <div *ngIf="!player">
        <img [attr.src]="avatarFilePath" class="img-fluid" alt="...">
    </div>
    <div *ngIf="!player" class="text-center">Игрок не определён</div>
</div>