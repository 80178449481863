import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {TournamentDetailedModel, TournamentEventModel} from '../../../common/modules/tournament-admin/services';
import { TournamentSimpleMatchCardComponent } from '../tournament-simple-match-card/tournament-simple-match-card.component';
import { MatCardModule } from '@angular/material/card';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ptc-tournament-bracket',
    templateUrl: './tournament-bracket.component.html',
    styleUrls: ['./tournament-bracket.component.css'],
    standalone: true,
    imports: [NgFor, MatCardModule, TournamentSimpleMatchCardComponent]
})
export class TournamentBracketComponent implements OnInit {

  displayedColumns = ["id", "description", "actions"];

  @Input()
  public tournament: TournamentDetailedModel;

  @Output()
  gamesUpdated = new EventEmitter<boolean>();

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  extractPlayer(game: TournamentEventModel, index: number){
    return game.players.length > index
      ? this.tournament.players.find(x => x.id === game.players[index].userId)
      : null;
  }

  extractPlayers(game: TournamentEventModel){

    let player1 = this.extractPlayer(game, 0);

    let player2 = this.extractPlayer(game, 1);

    let result = {
      player1,
      player2
    };

    return result;
  }

  openDialog(game: TournamentEventModel) {
    this._router.navigate(['/ptc/tournament/game', game.id]);
  }
}
