import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface Option {
  value: string;
  text: string
}

@Component({
    selector: 't-restrictions-select',
    templateUrl: './t-restrictions-select.component.html',
    styleUrls: ['./t-restrictions-select.component.css'],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, NgFor, MatOptionModule]
})
export class TRestrictionsSelectComponent implements OnInit {

  restrictionOptions: Option[] = [
    {
      value: "null",
      text: "Не выбрано"
    },
    {
      value: "true",
      text: "Мужской"
    },
    {
      value: "false",
      text: "Женский"
    },
  ];

  genderRestrictionStr: string = "null";
  genderRestriction: boolean | null = null;

  @Output()
  onRestrictionChanged = new EventEmitter<boolean | null>();

  restrictionChanged(data:MatSelectChange){
    this.genderRestrictionStr = data.value.toString();
    
    this.genderRestriction = this.toBool(this.genderRestrictionStr);
    this.onRestrictionChanged.emit(this.genderRestriction);
  }

  toBool(str: string){
    if(str === 'null'){
      return null;
    }

    return str === 'true';
  }

  constructor() { }

  ngOnInit(): void {
    this.computeGenderRestriction();
  }

  setValue(value: boolean | null){
    this.genderRestrictionStr = value?.toString() ?? "null";
    this.computeGenderRestriction();
  }

  computeGenderRestriction(){
    this.genderRestriction = this.toBool(this.genderRestrictionStr);
  }
}
