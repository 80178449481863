<div *ngIf="!inProccess && !gameCreated">
    <div *ngIf="!opponent">
        <mat-card>
            <mat-card-content>
                <div>
                    <span class="h5">Выберите соперника</span>
                </div>
            </mat-card-content>
        </mat-card>
    
        <ptc-player-search-list (playerSelected)="playerSelectedHandler($event)">
        </ptc-player-search-list>
    </div>
    
    <div *ngIf="step !== 0 && opponent">
    
        <mat-card>
            <mat-card-content>
                <span class="h6">Ваш соперник</span>
            </mat-card-content>
        </mat-card>

        <div style="height:10px"></div>

        <mat-card>
            <mat-card-content>
                <div>
                    <ptc-player-card [player]="opponent"></ptc-player-card>
                </div>
                
                <div style="height:20px"></div>
    
                <button class="w-100" (click)="changeOponent()" mat-raised-button color="warn">
                    Выбрать другого соперника
                </button>
            </mat-card-content>
        </mat-card>

        <div style="height: 20px;"></div>
    </div>

    <div>
        <ptc-set-full-game-data 
            [player1]="me" 
            [player2]="opponent"
            (gameUpdated)="gameCreatedHandler($event)">
        </ptc-set-full-game-data>
    </div>
</div>

<div *ngIf="gameCreated">
    <mat-card>
        <mat-card-content>
            <span class="h6">
                Игра создана успешно
            </span>
    
            <div style="height:20px"></div>
    
            <button (click)="startAgain()" class="w-100" mat-raised-button color="primary">
                Внести еще одну игру
            </button>
        </mat-card-content>
    </mat-card>

    <div style="height:20px"></div>
</div>