import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import {PlayerModel} from '../../../../models';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

export interface SearchPlayersFormData{
  q: string;
}

export interface PlayerModelWithFullName{
    player: PlayerModel;
    fullName: string;
}

@Component({
    selector: 'ptc-search-players',
    templateUrl: './search-players.component.html',
    styleUrls: ['./search-players.component.css'],
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgIf]
})
export class SearchPlayersComponent implements OnChanges {

    @Input()
    searchModel: SearchPlayersFormData = {
      q: ""
    };

    @Input()
    labelText = "Поиск игроков";

    @Output()
    playerChanged = new EventEmitter<string>();

    @Input()
    showContactData = true;

    @Input()
    searchPlayers: (data: SearchPlayersFormData) => Observable<PlayerModel[]>;

    loading = false;
    playerId: string;
    players:PlayerModelWithFullName[] = [];

    constructor() {
    }

    onPlayerSelectedHandler(playerId: string){
        this.playerChanged.emit(playerId);
    }

    public loadPlayers() {
        this.loading = true;
        this.searchPlayers(this.searchModel).subscribe(data => {

            this.players = [...data.map(x => ({fullName: x.surname + ' ' + x.name, player: x}))];
            this.loading = false;
        });
    }

    onSearchChanged(q: {term: string, items:object[]}){
        this.searchModel.q = q.term;
        this.loadPlayers();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loadPlayers();
    }
}
