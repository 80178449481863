import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface GetListResult<T>{
    
    /*
    Сколько всего записей
     */
    totalCount:number;

    /*
    Текущий список
     */
    list:Array<T>;
}

enum UserNotificationType {
   Success = <any> 'Success',
   Info = <any> 'Info',
   Warning = <any> 'Warning',
   Danger = <any> 'Danger',
   Custom = <any> 'Custom'
}

export interface BaseApiResponse {
    isSucceeded: boolean; 
    message: string; 
}

export interface NotificationModelWithUserModel {
    user: EccUserModel; 
    notification: NotificationModel; 
}


interface EccUserModel {
    id: string; 
    email: string; 
    phoneNumber: string; 
}


export interface NotificationModel {
    id: string; 
    title: string; 
    text: string; 
    objectJson: string; 
    type: UserNotificationType; 
    createdOn: Date; 
    readOn: Date | null; 
    userId: string; 
}

export interface NotificationSearchQueryModel {
	/* Идентификатор пользователя, к которому прикреплено уведомление */
	userId?: string; 
	/* Диапазон для даты создания уведомления */
	createdOn?: DateTimeRange; 
	read?: boolean | null; 
	count?: number | null; 
    offSet: number; 
}


interface DateTimeRange {
	min: Date | null; 
	max: Date | null; 
}

export interface CreateNotification {
    title: string; 
    text: string; 
    objectJSON: string; 
    type: UserNotificationType; 
    userId: string; 
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService{

    baseControllerUrl: string;

    constructor(private _httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string){
        this.baseControllerUrl = baseUrl + 'Api/Notification/';
    }

    public GetLastNotification(): Observable<NotificationModel> {
        return this._httpClient.post<NotificationModel>(this.baseControllerUrl + 'Client/GetLast', {});
    } 

    public MarkNotificationAsRead(id:string) : Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControllerUrl + `Client/Read?id=${id}`, {});
    }

    public Search(model:NotificationSearchQueryModel) : Observable<GetListResult<NotificationModelWithUserModel>> {
        return this._httpClient.post<GetListResult<NotificationModelWithUserModel>>(this.baseControllerUrl + `Search`, model);
    }

    public CreateNotification(model:CreateNotification) : Observable<BaseApiResponse> {
        return this._httpClient.post<BaseApiResponse>(this.baseControllerUrl + `Create`, model);
    }
}